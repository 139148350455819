// VRApp.styled.js
import styled from 'styled-components'

export const StyledVRApp = styled.section`
	@media (min-width: ${({ theme }) => theme.mobile}) {
		width: 75vw;
	}
	margin: auto;
	padding: 20px;
	background: ${({ theme }) => theme.primaryBackground};
	border-radius: 5px;
	text-align: left;

	* {
		text-align: left;
	}

	p,
	ul {
		margin-top: 20px;
		margin-bottom: 20px;
		color: ${({ theme }) => theme.primaryLight};
		text-align: left;
	}

	h1,
	h2 {
		color: ${({ theme }) => theme.primaryNormal};
		margin-top: 30px;
		margin-bottom: 10px;
	}

	a {
		color: ${({ theme }) => theme.accent};
		margin-left: 3px;
		margin-right: 3px;
	}

	img {
		width: 300px;
	}
`
