/**
 * Embed a Title.
 */
import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { ReactiveList } from '@appbaseio/reactivesearch'
import { Helmet } from 'react-helmet'
import { StyledEmbed } from './Embed.styled'
import Transcript from '../View/Transcript'
import { trackItemRequest } from '../../utils/TrackingUtils'
import customReactiveList from '../customReactiveList'
import ViewLayoutVideoObject from '../View/ViewLayoutVideoObject'
import { urlPrefix } from '../../constants'

/**
 * A function to generate a default query based on the view id.
 *
 * @param itemid
 * @returns elastic query
 */
function ViewQuery(itemid) {
	this.defaultQuery = () => {
		const queryTemplate = {
			query: {
				match: {
					'slug.keyword': itemid,
				},
			},
		}
		return queryTemplate
	}
}

const CustomReactiveList = customReactiveList(ReactiveList)

function useQuery() {
	return new URLSearchParams(useLocation().search)
}

const Embed = () => {
	const { id } = useParams()
	const viewQuery = new ViewQuery(id)
	let showImage = true
	let showPreview = true
	let accessType = 'controlled'

	const params = useQuery()
	const hideTranscript = params.get('transcript') === 'false'

	return (
		<StyledEmbed>
			<CustomReactiveList
				componentId="ViewImageResult"
				dataField="title"
				sortOptions={null}
				showResultStats={false}
				defaultQuery={viewQuery.defaultQuery}
			>
				{({ data }) => (
					<ReactiveList.ResultListWrapper>
						{/* eslint-disable-next-line */}
						{data.map((item) => (
							<div className="row view" key={item._id}>
								<Helmet>
									{
										(accessType =
											item.url.indexOf(urlPrefix) > 0
												? 'controlled'
												: 'other_free_to_read')
									}
									{trackItemRequest(accessType, item)}
									<title>{item.title} - Mindscape Commons</title>
									{item.purpose && (
										<meta name="description" content={item.purpose} />
									)}
									<link
										rel="canonical"
										href={`https://mindscapecommons.net/view/${item.slug}`}
									/>
									<meta name="twitter:card" content="summary_large_image" />
									<meta name="twitter:site" content="@MindscapeVR" />
									<meta name="twitter:creator" content="@MindscapeVR" />
									<meta
										property="og:url"
										content={`https://mindscapecommons.net/view/${item.slug}`}
									/>
									<meta property="og:title" content={item.title} />
									{item.purpose && (
										<meta property="og:description" content={item.purpose} />
									)}
									{item.image && item.image[0].thumbnails && (
										<meta
											property="og:image"
											content={item.image[0].thumbnails.large.url}
										/>
									)}
									<meta property="og:site_name" content="Mindscape Commons" />
									<meta property="og:type" content="virtual-reality" />
								</Helmet>
								<div key={item._id} className="column left">
									<div key={`${item._id}-image`}>
										{/* If there is a MICO title URL, frame that experience */}
										{item.url &&
											item.url.indexOf(urlPrefix) > 0 &&
											!item['scenery-type'].includes('2D Video') && (
												<div className="vr-embed">
													<iframe
														src={`${item.url}`}
														className="vr-iframe"
														title="VR embed"
														allowFullScreen
														webkitallowfullscreen="true"
														mozallowfullscreen="true"
														msallowfullscreen="true"
														type="text/html"
													/>
													{(showPreview = false)}
													{(showImage = false)}
												</div>
											)}

										{item.url &&
											item.url.indexOf(urlPrefix) > 0 &&
											item['scenery-type'].includes('2D Video') && (
												<div className="video-object">
													<div className="flex-grow">
														<ViewLayoutVideoObject item={item} />
													</div>
													{(showPreview = false)}
													{(showImage = false)}
												</div>
											)}

										{/* If there is a Vimeo preview, frame that experience */}
										{item['supplementary-materials'] &&
											item['supplementary-materials'].map(
												// eslint-disable-next-line
												(st) =>
													st['material-type'] === 'Preview' &&
													st.url.indexOf('vimeo') > 0 &&
													showPreview && (
														<div>
															<div className="vimeo-embed" key={st.url}>
																<iframe
																	className="vimeo-iframe"
																	title="Vimeo embed"
																	src={`https://player.vimeo.com/video/${st.url.substring(
																		st.url.lastIndexOf('/') + 1
																	)}?title=0&byline=0&portrait=0`}
																	allow="autoplay; fullscreen"
																	allowFullScreen
																	webkitallowfullscreen="true"
																	mozallowfullscreen="true"
																	msallowfullscreen="true"
																/>
																<script src="https://player.vimeo.com/api/player.js" />
																{(showImage = false)}
															</div>
															<h1>[Preview]</h1>
														</div>
													)
											)}
										{/* If there is a YouTube Preview URL, frame that experience */}
										{item['supplementary-materials'] &&
											item['supplementary-materials'].map(
												// eslint-disable-next-line
												(st) =>
													st['material-type'] === 'Preview' &&
													st.url.indexOf('youtu') > 0 &&
													showPreview && (
														<div>
															<div className="yt-embed" key={st.url}>
																<iframe
																	className="yt-iframe"
																	title="Youtube embed"
																	src={`https://www.youtube.com/embed/${st.url.substring(
																		st.url.lastIndexOf('/')
																	)}?autoplay=0&fs=1&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0`}
																	allowFullScreen
																	webkitallowfullscreen="true"
																	mozallowfullscreen="true"
																	msallowfullscreen="true"
																	height="350"
																	type="text/html"
																/>
																{(showImage = false)}
															</div>
															<h1>[Preview]</h1>
														</div>
													)
											)}
										{item.image && item.image[0].thumbnails && showImage && (
											<div id="experience-image">
												<img
													src={item.image[0].thumbnails.large.url}
													alt={item.title}
												/>
											</div>
										)}
									</div>
									{item.cid && (
										<div className="cid">
											<a
												href={`https://cid.coherentdigital.net/${item.cid}`}
												target="_cid"
												rel="noopener noreferrer"
												title="Coherent Identifier page opens in another window"
											>
												<img
													src="/coherent-logo.png"
													alt="Coherent identifier"
												/>
												{item.cid}
											</a>
										</div>
									)}
									<h1 className="page-title" key={item._id}>
										{item.title}
									</h1>
									<div className="row">
										<div className="column left">
											{item['scenery-type'] &&
												item['scenery-type'].map((stype) => (
													<div key={stype}>{stype}</div>
												))}
										</div>
									</div>

									<div className="citation">
										{item['project-leader'] &&
											item['project-leader'].map((pl) => (
												<span key={pl.id}>
													{'by '}
													{pl['first-name']} {pl['last-name']}
													{'. '}
												</span>
											))}
										{item.channel &&
											item.channel.map((chan) => (
												<span key={chan.slug}>
													{'in '}
													{chan.title}
													{'. '}
												</span>
											))}
										{item.publisher && item.publisher[0] && (
											<span>
												<span>
													{item.publisher[0].name}{' '}
													{window.dataLayer &&
														window.dataLayer.push({
															publisher: item.publisher[0].name,
														})}
												</span>
												{item.publisher[0].country && (
													<span>
														{'('}
														{item.publisher[0].country}
														{'). '}
													</span>
												)}
											</span>
										)}
										{item['release-date'] && item['release-date'] !== 1999 && (
											<span className="rel-date">{item['release-date']}</span>
										)}
										{item.duration && (
											<span className="duration">
												{'. '}
												{Math.floor(item.duration / 60)}
												{'m'}
												{':'}
												{Math.round(
													item.duration - Math.floor(item.duration / 60) * 60
												)}
												{'s'}
											</span>
										)}
									</div>
								</div>
								{!hideTranscript && (
									<div className="column right">
										<div className="transcript">
											{/* If there is on screen transcript, render that in a div */}
											{item['supplementary-materials'] &&
												item['supplementary-materials'].map(
													(st) =>
														(st['material-type'] === 'On Screen Transcript') && (
															<div key={`transcript-${st.url}`}>
																<Transcript
																	url={st.url}
																	item={item}
																/>
															</div>
														)
												)}
										</div>
									</div>
								)}
							</div>
						))}
					</ReactiveList.ResultListWrapper>
				)}
			</CustomReactiveList>
		</StyledEmbed>
	)
}

export default Embed
