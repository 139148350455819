/**
 * Developer console.
 * Access to this page requires a registered coherentdigital user.
 */
import React, { useState } from 'react'
import jwt from 'jsonwebtoken'
import { Helmet } from 'react-helmet'
import { StyledDev } from './Dev.styled'
import { useAuth0 } from '../../react-auth0-spa'

const Dev = () => {
	const {
		loading,
		loadingInstitution,
		isAuthorizedIndividual,
		isAuthorizedInstitution,
		jwToken,
		user,
	} = useAuth0()
	const masqueradeIpStorageKey = 'MasqueradeIpAddress'

	const ipAddr = localStorage.getItem(masqueradeIpStorageKey)
	const [ipInput, setIpInput] = useState('')
	const onIpChange = (event) => setIpInput(event.target.value)

	/**
	 * Function to save a masquerade IP address
	 */
	function setIp() {
		if (ipInput) {
			localStorage.setItem(masqueradeIpStorageKey, ipInput)
			const ipNote = document.getElementById('ipnote')
			ipNote.textContent =
				'Masquerade IP set.  Click on the MICO logo to reload.'
			/// Cause a token refresh
			localStorage.removeItem('mico-auth-token')
		} else {
			localStorage.removeItem(masqueradeIpStorageKey)
		}
	}

	/**
	 * Function to clear a masquerade IP address
	 */
	function clearIp() {
		setIpInput('')
		localStorage.removeItem(masqueradeIpStorageKey)
		const ipNote = document.getElementById('ipnote')
		ipNote.textContent =
			'Masquerade IP cleared.  Click on the MICO logo to reload.'
	}

	if (loading || loadingInstitution || !user) {
		return <div />
	}

	if (user.email.indexOf('coherentdigital.') < 0) {
		// eslint-disable-next-line
		console.log('Invalid user')
		return <div>Not authorized.</div>
	}

	return (
		<StyledDev>
			<Helmet>
				<title>Developer Console</title>
			</Helmet>

			<h2 className="title">Developer Console</h2>

			<div className="widget">
				<h2>IP Testing</h2>
				<label htmlFor="ip">Masquerade as IP:</label>{' '}
				<input name="ip" value={ipInput} type="text" onChange={onIpChange} />
				<button type="button" onClick={setIp}>
					Set
				</button>
				<button type="button" onClick={clearIp}>
					Clear
				</button>
				<span id="ipnote">
					{ipAddr && <span>Currently masquerading as IP: {ipAddr}</span>}
				</span>
			</div>

			<div className="widget">
				<h2>Referrer Testing</h2>
				<p>
					Setup a LibLynx test customer with{' '}
					<i>
						{window.location.protocol}
						{'//'}
						{window.location.hostname}
						{window.location.port === 3000 ? `:${window.location.port}` : ''}
						{window.location.pathname}
						{window.location.search}
						{window.location.hash}
					</i>{' '}
					as the accepted referring URL, and then click a link below to test.
				</p>
				<p>
					Make sure your IP is not recognized and that the referrer is the only
					way to authenticate at the destination.
				</p>
				<p>
					This may require setting up a masquerade IP of an unknown value at the
					destination.
				</p>
				<div>
					<a href="https://mindscapecommons.net">
						<img
							src="/apple-icon-57x57.png"
							alt="Mindscape Commons Logo"
							align="left"
							srcSet="/apple-icon-57x57.png 57w, /COH.MC.logo.preferable.RGB-01.svg 145w"
							sizes="(min-width: 576px) 160px, 60px"
							className="ml-0 mr-4"
							width="160"
						/>
					</a>
				</div>
				<div>
					<a href="https://policycommons.net">
						<img
							src="/policy-commons-logo.svg"
							alt="Policy Commons"
							className="ml-0 mr-4"
							width="160"
						/>
					</a>
				</div>
			</div>

			<div className="widget">
				<h2>Authentication and Authorization</h2>
				{isAuthorizedIndividual && <div>User is logged into Auth0</div>}
				{isAuthorizedInstitution && (
					<div>User is authorized at LibLynx for Mindscape Commons</div>
				)}
				{jwToken && (
					<div className="token">
						<div>
							<strong>Token (raw)</strong>:
						</div>
						<div>{jwToken}</div>
						<div>
							<strong>Token (decoded)</strong>:
						</div>
						<div>
							<code>{JSON.stringify(jwt.decode(jwToken), null, ' ')}</code>
						</div>
						<div>
							<strong>Token (info)</strong>:
						</div>
						<div>
							<span>
								<strong>Account name</strong>: {jwt.decode(jwToken).acn}
							</span>
						</div>
						<div>
							<span>
								<strong>Account reference</strong>: {jwt.decode(jwToken).aud}
							</span>
						</div>
						<div>
							<span>
								<strong>Token for</strong>: {jwt.decode(jwToken).sub}
							</span>
						</div>
						<div>
							<span>
								<strong>Issued at</strong>:{' '}
								{new Date(jwt.decode(jwToken).iat * 1000).toGMTString()}
							</span>
						</div>
						<div>
							<span>
								<strong>Expires</strong>:{' '}
								{new Date(jwt.decode(jwToken).exp * 1000).toGMTString()}
							</span>
						</div>
						<div>
							<span>
								<strong>Expires In</strong>:{' '}
								{jwt.decode(jwToken).exp - Math.floor(Date.now() / 1000)}
								{' seconds.'}
							</span>
						</div>
						<div>
							<span>
								<strong>Authorization ID</strong>: {jwt.decode(jwToken).jti}
							</span>
						</div>
					</div>
				)}
			</div>

			<div className="widget">
				<h2>Local Storage</h2>
				<table>
					<thead>
						<tr>
							<th>Key</th>
							<td>
								<strong>Value</strong>
							</td>
						</tr>
					</thead>
					<tbody>
						{Object.keys(localStorage).map((key) => (
							<tr key={key}>
								<th>{key}</th>
								<td>{localStorage.getItem(key)}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</StyledDev>
	)
}

export default Dev
