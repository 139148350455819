/**
 * This is a High Order Component to add additional functionality to ReactiveList
 */
import React, { useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { revealAriaMessage } from '../../helpers/accessibilityHelpers'
import { sortOptions as defaultSortOptions } from '../../constants'

const customReactiveList = (ReactiveList) => {
	return ({ ...props }) => {
		// to search `.sort-options` into root elem instead of entire document
		const rootElem = useRef(null)
		// to show screen reader's alerts
		const ariaMessage = useRef(null)

		// get default sort option
		const history = useHistory()
		const location = useLocation()
		const query = new URLSearchParams(location.search)
		const sort = query.get('sort')
		const sortOptionFromUrl = Object.values(defaultSortOptions).find(
			(option) => option.label === sort
		)

		// subscribe select changes
		// eslint-disable-next-line
		useEffect(() => {
			const select = rootElem.current.querySelector('.sort-options')

			// Change listener
			function subscribeSelect() {
				setTimeout(() => {
					const sortText = select.options[select.selectedIndex].text
					const subscribeQuery = new URLSearchParams(location.search)
					subscribeQuery.set('sort', sortText)
					history.push({
						search: subscribeQuery.toString(),
					})
				}, 300)
			}
			if (select) {
				// Add change listener
				select.addEventListener('change', subscribeSelect)

				// Add aria-label for a11y
				select.setAttribute('aria-label', 'Sort search result')

				// Remove change listener for performance
				return () => {
					select.removeEventListener('change', subscribeSelect)
				}
			}
			// eslint-disable-next-line
        }, [])

		const combinedOnData = (data) => {
			const results = data?.resultStats?.numberOfResults ?? 0
			revealAriaMessage(ariaMessage.current, `${results} results found`)
			// call passed onData callback if exists
			if (props.onData) {
				props.onData(data)
			}
		}

		const optionsFromProps = Object.prototype.hasOwnProperty.call(
			props,
			'sortOptions'
		)
		const sortOptions = optionsFromProps
			? props.sortOptions
			: Object.values(defaultSortOptions)

		return (
			<div ref={rootElem}>
				<h2 className="sr-only">Results</h2>
				<div
					ref={ariaMessage}
					className="sr-only"
					role="alert"
					aria-live="assertive"
				/>
				<ReactiveList
					sortOptions={sortOptions}
					defaultSortOption={sortOptionFromUrl?.label}
					{...props}
					onData={combinedOnData}
				/>
			</div>
		)
	}
}

export default customReactiveList
