/**
 * ViewLayoutVideoObject
 */
import React, { useEffect, useState, useRef, useCallback } from 'react'
import axios from 'axios'
import VideoObjectPlayer from '@coherentdigital/video-object/dist/main'
import { trackItemRequest } from '../../utils/TrackingUtils'

const ViewLayoutVideoObject = ({ item, videoObjectRef }) => {
	const [json, setJson] = useState(null)
	const videoContainer = useRef()

	const getJson = async (url) => {
		const response = await axios.get(url)
		setJson(response.data)
	}

	const generateVideoObject = useCallback(() => {
		const transcriptContainer = document.getElementById('transcript-container')
		// eslint-disable-next-line
		const voPlayer = new VideoObjectPlayer(json, videoContainer.current, transcriptContainer)

		if (videoObjectRef) {
			// eslint-disable-next-line
			videoObjectRef(voPlayer)
		}
	}, [json, videoObjectRef])

	useEffect(() => {
		if (json) {
			generateVideoObject()
		}
	}, [generateVideoObject, json])

	useEffect(() => {
		getJson(item.url)
		// eslint-disable-next-line
	}, [item.url])

	// emit trackItemRequest
	useEffect(() => {
		const isMembership = item['access-type'] === 'Membership Video'

		if (isMembership) {
			trackItemRequest('controlled', item)
		} else {
			trackItemRequest('other_free_to_read', item)
		}
	})

	return (
		<div className="container">
			<div ref={videoContainer} />
		</div>
	)
}

export default ViewLayoutVideoObject
