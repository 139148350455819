/**
 * Browse by Immersion Factor.
 */
import React from 'react'

import Browse from '../Browse'

const Immersion = () => {
	return (
		<Browse
			filterHeading="Browse by Immersion Factor"
			componentId="immersion"
			dataField="immersion-factor.keyword"
			filterLabel="Immersion Factors"
			selectAllLabel="All Immersion Factors"
		/>
	)
}

export default Immersion
