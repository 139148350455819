// Dev.styled.js
import styled from 'styled-components'

export const StyledDev = styled.section`
	margin-left: auto;
	margin-right: auto;
	padding: 20px;
	background: ${({ theme }) => theme.primaryBackground};
	border-radius: 5px;
	text-align: left;
	color: ${({ theme }) => theme.primaryNormal};

	.title {
		text-align: center;
	}

	h2 {
		color: ${({ theme }) => theme.primaryNormal};
	}

	div {
		text-align: left;
	}

	table {
		border: 1px solid #aaa;
	}

	tr {
		width: 80%;
		border-bottom: 1px solid #aaa;
	}

	tr:nth-child(even) {
		background-color: #f2f2f2;
	}

	th {
		width: 25%;
		word-break: break-word;
		vertical-align: top;
	}

	td {
		word-break: break-word;
		vertical-align: top;
	}

	input {
		border: 1px solid #ccc;
		border-radius: 5px;
	}

	button {
		border: 1px solid #ccc;
		border-radius: 5px;
		margin-left: 5px;
		margin-right: 5px;
		padding: 5px;
		width: 50px;
		background-color: ${({ theme }) => theme.accent};
		color: white;
	}

	.container {
		width: 80%;
		border: 1px solid blue;
	}

	.widget {
		background-color: #ddd;
		border-radius: 25px;
		margin: 20px;
		padding: 20px;
	}

	.token {
		word-break: break-word;
	}

	code {
		white-space: pre;
	}
`
