export const params = {
	channel: {
		cardHref: 'collection',
		subtitle: 'All Collections',
		singleEntityLabel: 'Collection',
		title: 'Collections - Mindscape Commons',
	},
	resourcechannel: {
		cardHref: 'resource',
		subtitle: 'All Resources',
		singleEntityLabel: 'Resource',
		title: 'Resources - Mindscape Commons',
	},
}
