import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { compose, createStore } from 'redux'
import HttpsRedirect from 'react-https-redirect'
import TagManager from 'react-gtm-module'

import rootReducer from './reducers/index'
import App from './App'
import * as serviceWorker from './serviceWorker'
import '@fortawesome/fontawesome-free/css/all.min.css'

import './styles/index.scss'
// import tailwind css file
import './tailwind.output.css'

const store = createStore(
	rootReducer,
	compose(
		window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
	)
)

// Setup Google Tag Manager as staging unless the environment contains other settings.
const gtmId = process.env.REACT_APP_GTM_ID
	? process.env.REACT_APP_GTM_ID
	: 'GTM-PT2R53M'
const gtmAuth = process.env.REACT_APP_GTM_AUTH
	? process.env.REACT_APP_GTM_AUTH
	: 'fer0LakPu_KSFko0vqFhpA'
const gtmPreview = process.env.REACT_APP_GTM_PREVIEW
	? process.env.REACT_APP_GTM_PREVIEW
	: 'env-6'
const tagManagerArgs = {
	gtmId,
	auth: gtmAuth,
	preview: gtmPreview,
}
TagManager.initialize(tagManagerArgs)

ReactDOM.render(
	<Provider store={store}>
		<HttpsRedirect>
			<App />
		</HttpsRedirect>
		,
	</Provider>,
	document.getElementById('root')
)

serviceWorker.unregister()
