import React, { useState, useRef, useEffect } from 'react'
import { DataSearch } from '@appbaseio/reactivesearch'
import { Link, useLocation } from 'react-router-dom'
import { FocusOn } from 'react-focus-on'
import Burger from '../Burger'
import Menu from '../Menu'
import { StyledNavBar } from './NavBar.styled'
import { useAuth0 } from '../../react-auth0-spa'

const NavBar = () => {
	const rootElem = useRef()
	const location = useLocation()
	const [open, setOpen] = useState(false)
	const node = useRef()
	const {
		loading,
		loadingInstitution,
		user,
		isAuthorizedIndividual,
		isAuthorizedInstitution,
		logout,
		loginWithPopup,
	} = useAuth0()

	useEffect(() => {
		if (rootElem.current) {
			const searchBarInput = rootElem.current.querySelector('.search-bar input')
			if (searchBarInput) {
				searchBarInput.setAttribute('aria-label', 'Search for experiences')
			}
		}
	}, [loadingInstitution, loading, location])

	const menuId = 'main-menu'

	const port =
		window.location.port === '' ||
		window.location.port === '80' ||
		window.location.port === '443'
			? ''
			: `:${window.location.port}`
	const logoutDest = `${window.location.protocol}//${window.location.hostname}${port}`
	const loginDest = `${window.location.protocol}//${window.location.hostname}${port}/${window.location.pathname}`

	// Define plan (by default it's Visitor)
	let yourPlan = 'Visitor'
	if (isAuthorizedInstitution) {
		yourPlan = 'Institution'
	} else if (isAuthorizedIndividual) {
		yourPlan = 'Individual'
	}

	if (loading || loadingInstitution) {
		return <div />
	}

	if (document.location.href.indexOf('/embed/') > 0) {
		return <div />
	}

	const focusToMainContent = () => {
		// eslint-disable-next-line
		document.querySelector('main')?.focus()
	}

	return (
		<StyledNavBar
			ref={rootElem}
			role="banner"
			className="navbar w-full flex flex-wrap p-0 h-16"
		>
			<div className="container relative hidden md:block">
				<button
					type="button"
					className="skip-main btn btn-dark text-xs"
					role="link"
					tabIndex="0"
					onClick={focusToMainContent}
				>
					Skip to main
					<br />
					content
				</button>
			</div>
			<div className="container">
				<div className="h-full flex flex-wrap p-0">
					<div className="w-auto flex sm:flex-grow flex-shrink items-center pr-3">
						<a id="logo-link" href="/">
							<img
								src="/apple-icon-57x57.png"
								alt="Mindscape Commons Logo"
								align="left"
								srcSet="/apple-icon-57x57.png 57w, /COH.MC.logo.preferable.RGB-01.svg 145w"
								sizes="(min-width: 576px) 160px, 60px"
								className="app-logo hidden md:block text-black"
							/>
							<img
								src="/apple-icon-57x57.png"
								alt="Mindscape Commons Logo"
								align="left"
								className="w-8 block md:hidden"
							/>
						</a>
					</div>

					{window.location.pathname !== '/' && (
						<div className="px-3 flex items-center w-9/12 sm:w-auto flex-grow">
							<DataSearch
								componentId="q"
								dataField={[
									'title',
									'publisher.name',
									'purpose',
									'description',
									'subjects.subject',
									'project-leader.first-name',
									'project-leader.last-name',
									'target-platform',
									'interactivity-factor',
									'scenery-type',
									'immersion-factor',
									'interactivity-factor',
									'demographics',
									'supplementary-materials.material-type',
								]}
								fieldWeights={[
									5, 3, 3, 3, 3, 1, 1, 1, 1, 1, 1, 1, 1, 1
								]}
								queryFormat="and"
								placeholder="Search for experiences.."
								filterLabel="Your query"
								searchOperators
								showClear
								innerClass={{
									input: 'search-bar-input',
								}}
								className="search-bar w-full text-black"
								URLParams
								onValueSelected={
									/** Function to redirect a query submission to /search if not on a search page */
									(value) => {
										if (
											window.location.href.indexOf('/search') > 0 ||
											window.location.href.indexOf('/browse') > 0
										) {
											window.dataLayer.push({
												userQuery: value,
												event: 'userQueryPushed',
											})
											// do not redirect; already at a search page
										} else if (value && value.trim().length > 0) {
											// Remove double quotes as we are about to add our own.
											const newValue = value.replace(/["]+/g, '')
											window.location.href = `/search?q="${encodeURI(
												newValue
											)}"`
										} else {
											window.location.href = '/search'
										}
									}
								}
							/>
						</div>
					)}
					<div className="flex-grow flex justify-center items-center">
						<div>
							{isAuthorizedIndividual && user && (
								<ul className="flex">
									<li>
										<Link
											className="justify-left avatar"
											to="/profile"
											aria-label="View Profile"
											title="View Profile"
										>
											{user.picture && (
												<img
													src={user.picture}
													alt="Profile"
													aria-hidden="true"
													className="max-w-full max-h-full mr-3 rounded-full h-8"
												/>
											)}
											{!user.picture && <span>{user.nickname}</span>}
										</Link>
									</li>
									<li>
										<Link
											to="#!"
											onClick={() => logout({ returnTo: logoutDest })}
											tabIndex={0}
											className="justify-right lolink"
										>
											Logout
										</Link>
									</li>
								</ul>
							)}
							{isAuthorizedIndividual && !user && (
								<div>
									<Link
										to="#!"
										onClick={() => logout({ returnTo: logoutDest })}
									>
										Logout
									</Link>
								</div>
							)}
							{!isAuthorizedIndividual && (
								<div className="signin-link-container">
									<Link
										to="#!"
										onClick={() => loginWithPopup({ returnTo: loginDest })}
									>
										<small>Login / Sign Up</small>
									</Link>
								</div>
							)}
							<div>
								<small>Your Plan: {yourPlan}</small>
							</div>
							{isAuthorizedIndividual && user && user.email_verified === false && (
								<div>
									<small>
										<Link to="/profile" className="underline">
											eMail unverified
										</Link>
									</small>
								</div>
							)}
						</div>
					</div>

					<div className="flex justify-end flex-grow-0 relative burger-block">
						<div ref={node}>
							<FocusOn scrollLock={false} noIsolation enabled={open}>
								<Burger open={open} setOpen={setOpen} aria-controls={menuId} />
								{open && <Menu open={open} setOpen={setOpen} id={menuId} />}
							</FocusOn>
						</div>
					</div>
				</div>
			</div>
		</StyledNavBar>
	)
}

export default NavBar
