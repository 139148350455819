/**
 * This is a High Order Component to add a11y features to SelectedFilters
 */
import React, { useRef } from 'react'

import { revealAriaMessage } from '../../helpers/accessibilityHelpers'
import { StyledCustomFilters } from './customSelectedFilters.styled'

const customSelectedFilters = (SelectedFilters) => {
	return ({ ...props }) => {
		// to search `.sort-options` into root elem instead of entire document
		const rootElem = useRef(null)
		// to show screen reader's alerts
		const ariaMessage = useRef(null)

		const combinedOnClear = (component, value) => {
			let text = ''

			if (component && value) {
				text = `filter ${value} from ${component} has been removed`
			} else {
				// multiple filters removed by clear filters button
				text = 'All filters have been removed'
			}

			revealAriaMessage(ariaMessage.current, text)

			// call passed onData callback if exists
			if (props.onClear) {
				props.onClear(component, value)
			}
		}

		const { className, ...propsWithoutClassName } = props

		return (
			<StyledCustomFilters ref={rootElem} className={props.className}>
				<h2 className="sr-only">Selected Filters</h2>
				<div
					ref={ariaMessage}
					className="sr-only"
					role="alert"
					aria-live="assertive"
				/>
				<SelectedFilters
					{...propsWithoutClassName}
					render={(selectedFilterProps) => {
						const { selectedValues } = selectedFilterProps

						const remove = (component, value = null) => {
							const { onClear } = selectedFilterProps
							selectedFilterProps.setValue(component, null)
							if (onClear) {
								onClear(component, value)
							}
						}

						const renderValue = (value, isArray) => {
							if (isArray && value.length) {
								const arrayToRender = value.map((item) => renderValue(item))
								return arrayToRender.join(', ')
							}
							if (value && typeof value === 'object') {
								// TODO: support for NestedList
								let label =
									(typeof value.label === 'string'
										? value.label
										: value.value) ||
									value.key ||
									value.distance ||
									null
								if (value.location) {
									label = `${value.location} - ${label}`
								}
								return label
							}
							return value
						}

						const filters = Object.keys(selectedValues)
							.filter(
								(id) =>
									selectedFilterProps.components.includes(id) &&
									selectedValues[id].showFilter
							)
							.map((component, index) => {
								const { label, value, category } = selectedValues[component]
								const isArray = Array.isArray(value)

								if (
									label &&
									((isArray && value.length) || (!isArray && value))
								) {
									const valueToRender = category
										? renderValue(`${value} in ${category} category`, isArray)
										: renderValue(value, isArray)
									const componentName =
										component === 'q' ? 'Your query' : component
									return (
										<button
											aria-label={`Remove filter ${componentName}: ${valueToRender}`}
											className={selectedFilterProps.innerClass.button}
											key={`${component}-${index + 1}`}
											type="button"
											onClick={() => remove(component, value)}
										>
											<span>
												{selectedValues[component].label}: {valueToRender}
											</span>
											<span>&#x2715;</span>
										</button>
									)
								}
								return null
							})
							.filter(Boolean)

						if (selectedFilterProps.showClearAll && !!filters.length) {
							filters.push(
								<button
									aria-label="Remove All filters"
									key="remove-all-filters"
									type="button"
									className={selectedFilterProps.innerClass.button}
									onClick={selectedFilterProps.clearValues}
								>
									{selectedFilterProps.clearAllLabel}
								</button>
							)
						}
						return filters
					}}
					onClear={combinedOnClear}
				/>
			</StyledCustomFilters>
		)
	}
}

export default customSelectedFilters
