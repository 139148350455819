/**
 * Breadcrumbs
 */
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { getTitleWithoutPostfix } from '../../reducers/title/selectors'
import { getLastBreadcrumbs } from '../../reducers/history/selectors'

/**
 * Cuts text and returns specified number of words
 * including ellipsis "A Walk Through Dementia at home" => "A Walk through ... Home"
 */
function splitText(text, maxWords) {
	const words = text.split(' ')
	if (words.length > maxWords) {
		// taking the first n-three words and the last 2
		const newWords = words
			.slice(0, maxWords - 3)
			.concat(['...'])
			.concat(words.slice(-2))
		return newWords.join(' ')
	}
	return text
}

const Breadcrumbs = () => {
	const lastBreadcrumbs = useSelector(getLastBreadcrumbs)
	const title = useSelector(getTitleWithoutPostfix)
	const indent = 2

	const breadcrumbs = lastBreadcrumbs.map((route, index) => {
		return (
			<div
				className={`ml-${indent * index} text-left`}
				key={`${route.pathname}-${route.title}-${route.key}`}
			>
				<i className={`fas fa-chevron-right mr-2 ${!index && 'md:hidden'}`} />
				<Link to={`${route.pathname}${route.search}`}>
					<span className="hidden md:inline">{splitText(route.title, 5)}</span>
					<span className="inline md:hidden">{route.title}</span>
				</Link>
			</div>
		)
	})

	return (
		<div className="flex flex-col md:flex-row py-10">
			{/* current page */}
			{breadcrumbs}
			<div
				key="current-page"
				className={`ml-${breadcrumbs.length * indent} text-left`}
			>
				<i
					className={`fas fa-chevron-right mr-2 ${
						(!breadcrumbs.length && 'md:hidden') || ''
					}`}
				/>
				{splitText(title, 10)}
			</div>
		</div>
	)
}

export default Breadcrumbs
