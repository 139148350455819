/**
 * ViewMenu
 */
import React from 'react'

import Transcript from './Transcript'
import { getMaterialWithTranscript } from '../../helpers/transcriptHelpers'

const ViewTranscript = ({ item }) => {
	// eslint-disable-next-line no-shadow
	function getComponent(item) {
		const data = getMaterialWithTranscript(item)

		return (
			!!data && (
				<div key={`transcript-${data.url}`} className="w-full">
					<Transcript url={data.url} item={item} />
				</div>
			)
		)
	}

	return (
		<div>
			{/* If there is on screen transcript, render that in a div */}
			{item['supplementary-materials'] && getComponent(item)}
		</div>
	)
}

export default ViewTranscript
