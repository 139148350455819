/**
 * Page showing an example MICO Directory Entry.
 */
import React from 'react'
import { StyledExampleEntry } from './ExampleEntry.styled'

const ExampleEntry = () => {
	return (
		<StyledExampleEntry>
			<h2>Example Mindscape Commons Directory Entry</h2>
			<p>This page is under construction</p>
		</StyledExampleEntry>
	)
}

export default ExampleEntry
