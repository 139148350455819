import React, { useState, useEffect, useContext } from 'react'
import createAuth0Client from '@auth0/auth0-spa-js'
import jwt from 'jsonwebtoken'

const DEFAULT_REDIRECT_CALLBACK = () =>
	window.history.replaceState({}, document.title, window.location.pathname)

export const Auth0Context = React.createContext()
export const useAuth0 = () => useContext(Auth0Context)
export const Auth0Provider = ({
	children,
	onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
	...initOptions
}) => {
	const [isAuthorizedIndividual, setIsAuthorizedIndividual] = useState(false)
	const [isAuthorizedInstitution, setIsAuthorizedInstitution] = useState(false)
	const [user, setUser] = useState()
	const [jwToken, setJwToken] = useState()
	const [auth0Client, setAuth0] = useState()
	const [loading, setLoading] = useState(true)
	const [loadingInstitution, setLoadingInstitution] = useState(true)
	const [popupOpen, setPopupOpen] = useState(false)

	const validateToken = (token) => {
		try {
			const tokenPayload = jwt.decode(token)
			if ('sub' in tokenPayload && tokenPayload.sub === 'MICO') {
				if ('exp' in tokenPayload) {
					const expiry = tokenPayload.exp
					const expiresIn = expiry - Math.floor(Date.now() / 1000)
					// If there are at least 30s left before token expiry, its OK for now.
					if (expiresIn > 30) {
						return true
					}
				}
			}
		} catch (error) {
			// eslint-disable-next-line
			console.log(error)
		}
		return false
	}

	useEffect(() => {
		const initAuth0 = async () => {
			try {
				const auth0FromHook = await createAuth0Client(initOptions)
				setAuth0(auth0FromHook)

				if (
					window.location.search.includes('code=') &&
					window.location.search.includes('state=')
				) {
					const { appState } = await auth0FromHook.handleRedirectCallback()
					onRedirectCallback(appState)
				}

				const isAuth0Authenticated = await auth0FromHook.isAuthenticated()
				// Having authenticated at Auth0, user is an authorized individual.
				setIsAuthorizedIndividual(isAuth0Authenticated)

				if (isAuth0Authenticated) {
					// eslint-disable-next-line
					const user = await auth0FromHook.getUser()
					setUser(user)
					// eslint-disable-next-line
					await initLibLynxByUser(user)
				}

				setLoading(false)
			} catch (error) {
				// If initialization fails, delete the refresh token and reload.
				// eslint-disable-next-line
				console.log(error)
				// eslint-disable-next-line
				for (let i = 0; i < localStorage.length; i++) {
					const key = localStorage.key(i)
					if (key.indexOf('auth0') > 0) {
						localStorage.removeItem(key)
						window.location.replace(
							window.location.pathname +
								window.location.search +
								window.location.hash
						)
					}
				}
			}
		}

		/**
		 * This method authorizes the user for MICO by IP or referrer in the LibLynx CRM.
		 */
		const initLibLynx = async () => {
			let referrer = null
			// eslint-disable-next-line
			if (window.hasOwnProperty('referrer')) {
				referrer = window.referrer
			}

			const headers = {}
			if (localStorage.getItem('MasqueradeIpAddress')) {
				headers['X-Debug-IP'] = localStorage.getItem('MasqueradeIpAddress')
			}

			// Validate an existing jwt.
			let localToken = jwToken
			if (!localToken) {
				localToken = localStorage.getItem('mico-auth-token')
			}
			if (localToken) {
				if (validateToken(localToken)) {
					setJwToken(localToken)
					setIsAuthorizedInstitution(true)
					setLoadingInstitution(false)
					return
				}
				localStorage.removeItem('mico-auth-token')
			}

			// There was no valid local token, obtain a new one.
			try {
				let params = null
				if (
					window.location.search.includes('_llca=') &&
					window.location.search.includes('_llch=')
				) {
					params = new URLSearchParams({ return: window.location.href })
				} else if (referrer) {
					params = new URLSearchParams({ referrer })
				}

				fetch(
					`https://auth.mindscapecommons.net/authorizeProduct/MICO${
						params ? `?${params}` : ''
					}`,
					{
						headers,
						referrer,
						referrer_policy: 'no-referrer-when-downgrade',
					}
				)
					.then((response) => {
						if (response.ok) {
							return response.text()
						}
						return Promise.reject(response)
					})
					.then((llToken) => {
						localStorage.setItem('mico-auth-token', llToken)
						setJwToken(llToken)
						setIsAuthorizedInstitution(true)
						setLoadingInstitution(false)
					})
					.catch((error) => {
						// eslint-disable-next-line
						console.log('Institutional authorization failed')
						setLoadingInstitution(false)
						return Promise.reject(error)
					})
			} catch (error) {
				// eslint-disable-next-line
				console.log('Institutional authorization failed during fetch')
				// eslint-disable-next-line
				console.log(error)
			}
		}

		/**
		 * This method attempts to validate institutional affiliation by organization_id in the user profile.
		 * The user's email must be verified.  And they must have an organization_id in their profile
		 * (this is added by coherent, not via user sign-up).
		 * And that organization must have an active MICO subscription.
		 * Note that initLibLynx will also be checking the user's IP address; so one or both of these could
		 * result in a valid institutional affiliation.
		 */
		// eslint-disable-next-line
		const initLibLynxByUser = async (user) => {
			if (!user.email_verified) {
				return
			}
			if (!('https://coherentdigital.net/user_metadata' in user)) {
				return
			}
			if (
				!(
					'organization_id' in user['https://coherentdigital.net/user_metadata']
				)
			) {
				return
			}
			const orgId =
				user['https://coherentdigital.net/user_metadata'].organization_id

			// Validate an existing jwt.
			let localToken = jwToken
			if (!localToken) {
				localToken = localStorage.getItem('mico-auth-token')
			}
			if (localToken) {
				if (validateToken(localToken)) {
					setJwToken(localToken)
					setIsAuthorizedInstitution(true)
					setLoadingInstitution(false)
					return
				}
				localStorage.removeItem('mico-auth-token')
			}

			// There was no valid local token, try to log the user in by orgId.
			try {
				fetch(
					`https://auth.mindscapecommons.net/authorizeProductById/MICO/${orgId}`
				)
					.then((response) => {
						if (response.ok) {
							return response.text()
						}
						return Promise.reject(response)
					})
					.then((llToken) => {
						localStorage.setItem('mico-auth-token', llToken)
						setJwToken(llToken)
						setIsAuthorizedInstitution(true)
					})
					.catch((error) => {
						// eslint-disable-next-line
						console.log('Institutional user authorization failed')
						return Promise.reject(error)
					})
			} catch (error) {
				// eslint-disable-next-line
				console.log('Institutional user authorization failed during fetch')
				// eslint-disable-next-line
				console.log(error)
			}
		}

		initAuth0()
		initLibLynx()
		// eslint-disable-next-line
  }, [isAuthorizedIndividual]);

	const loginWithPopup = async (params = {}) => {
		setPopupOpen(true)
		try {
			await auth0Client.loginWithPopup(params)
		} catch (error) {
			// eslint-disable-next-line
			console.error(error)
		} finally {
			setPopupOpen(false)
		}
		// eslint-disable-next-line
		const user = await auth0Client.getUser()
		setUser(user)
		setIsAuthorizedIndividual(true)
	}

	const handleRedirectCallback = async () => {
		setLoading(true)
		await auth0Client.handleRedirectCallback()
		// eslint-disable-next-line
		const user = await auth0Client.getUser()
		setLoading(false)
		setIsAuthorizedIndividual(true)
		setUser(user)
	}
	return (
		<Auth0Context.Provider
			value={{
				isAuthorizedIndividual,
				isAuthorizedInstitution,
				user,
				jwToken,
				loading,
				loadingInstitution,
				popupOpen,
				loginWithPopup,
				handleRedirectCallback,
				getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
				loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
				getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
				getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
				logout: (...p) => auth0Client.logout(...p),
			}}
		>
			{children}
		</Auth0Context.Provider>
	)
}
