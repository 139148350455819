/**
 * This is a High Order Component to add additional functionality
 *
 * To make ReactCard act like router link <Link>
 * To have corresponded icon based on access-type
 */
import React from 'react'
//import { useHistory } from 'react-router-dom'

export const AccessTypes = {
	Membership: 'Membership Video',
	Open: 'Open Access Video',
	Commercial: 'Commercial Resources',
}

const withLink = (ResultCard) => {
	return ({ ...props }) => {
		//const history = useHistory()

		const accessType = props?.item?.['access-type']
		const isMembership = accessType === AccessTypes.Membership
		const isOpen = accessType === AccessTypes.Open
		const isCommercial = accessType === AccessTypes.Commercial
		const other = !isOpen && !isMembership && !isCommercial
		const accessStyle = `with-link-icon absolute left-0 bottom-0 mb-4 ml-4 ${
			props.accessStyle || ''
		}`

		function handleClick(e) {
			//e.preventDefault()
			//history.push(props.href)
		}

		// @TODO Add support className to wrapper (might broke layouts)
		return (
			<div className="relative">
				<ResultCard onClick={handleClick} {...props} />
				{/* Access icon */}
				<div className={accessStyle}>
					<div className="text-xs with-link-icon__not-member p-2 rounded-full flex items-center justify-center bg-white">
						{isMembership && (
							<img
								title="Exclusive / Membership"
								src="/favicon-16x16.png"
								alt="Exclusive / Membership"
								className="w-3"
							/>
						)}
						{isOpen && (
							<i
								title="Open Access Video"
								className="fas fa-lock-open text-green-600"
							/>
						)}
						{isCommercial && (
							<i
								title="Commercial Resources"
								className="fas fa-external-link-alt text-red-500"
							/>
						)}
						{other && (
							<i
								title="Research Project"
								className="fas fa-external-link-alt text-indigo-500"
							/>
						)}
					</div>
				</div>
			</div>
		)
	}
}

export default withLink
