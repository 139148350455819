import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Tooltip } from 'react-tippy'
import 'react-tippy/dist/tippy.css'
import Search from 'search-children'

import { setIsTheater } from '../../reducers/viewPage/actions'
import { theaterModeSearchParam } from '../../reducers/viewPage/types'
import UseDataApi from '../../utils/UseDataApi'
import { trackSearch } from '../../utils/TrackingUtils'
import { StyledTranscript } from './Transcript.styled'
import { manifestFileName } from '../../constants'

function hashCode(string) {
	let hash = 0
	if (!string || string.length === 0) {
		return hash
	}
	// eslint-disable-next-line
	for (let i = 0; i < string.length; i++) {
		const char = string.charCodeAt(i)
		// eslint-disable-next-line
		hash = (hash << 5) - hash + char
		// eslint-disable-next-line
		hash &= hash
	}
	return hash
}

function Transcript({ url, item }) {
	const [tooltipOpened, setTooltipOpened] = useState(false)

	const dispatch = useDispatch()
	const history = useHistory()
	const location = useLocation()
	const isTheater = useSelector((state) => state.viewPage.isTheater)
	const isEmbed = window.location.href.indexOf('/embed/') > 0

	const [dataState] = UseDataApi(url)
	const [searchTerms, setSearchTerms] = useState('')
	const [searchResult, setSearchResult] = useState('')
	// eslint-disable-next-line
	const punct_regex = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\\]/g

	const toggleCollapse = () => {
		const newValue = !isTheater
		dispatch(setIsTheater(newValue))
		history.push(`${location.pathname}?${theaterModeSearchParam}=${newValue}`)
		// scroll to the top in we switch theater mode on
		// eslint-disable-next-line
		newValue && window.scrollTo(0, 0)
	}

	const handleSearchDone = ({ count }) => {
		if (searchTerms.length > 0) {
			setSearchResult(`${count} hit${count > 1 || !count ? 's' : ''}`)
		} else {
			setSearchResult('')
		}
	}

	const handleSearchTerms = (event) => {
		let terms = event.target.value
		terms = terms.replace(punct_regex, '')
		setSearchTerms(terms)
	}

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			trackSearch()
		}
	}

	return (
		<StyledTranscript isTheater={isTheater}>
			<Search value={searchTerms} onDone={handleSearchDone}>
				{!item.url.includes(manifestFileName) && (
					<div className="flex justify-between items-center mb-5">
						<h2 className="transcript-title text-2xl">Transcript</h2>
						<div className="flex items-center">
							{!!searchResult && (
								<div role="alert" aria-live="polite" className="sr-only">
									{searchResult} found
								</div>
							)}
							<div className="mr-5">{searchResult}</div>
							<div>
								<input
									aria-label="Search transcript"
									className="text-black pl-10 py-2 rounded-full border border-gray-400"
									placeholder="search transcript"
									value={searchTerms}
									onChange={handleSearchTerms}
									onKeyDown={handleKeyPress}
								/>
							</div>
						</div>
					</div>
				)}
				{/* transcript box */}
				<div
					className={`relative p-5 pb-0 md:p-10 md:pb-0 border border-gray-400 ${
						!isTheater ? 'rounded-md' : ''
					}`}
				>
					{/* scroll block */}
					<div
						className={`${isTheater || isEmbed ? '' : 'transcript-collapsed'} pb-10`}
						style={{
							height: item.url.includes(manifestFileName)
								? '100%'
								: '65vh',
							overflow: 'auto',
						}}
						id="transcript-container"
					>
						{!item.url.includes(manifestFileName) &&
							Array.isArray(dataState?.data) &&
							dataState.data.map((paragraph, index) => (
								// eslint-disable-next-line
							<p key={`${hashCode(paragraph)}-${index}`}>{paragraph}</p>
							))}
						<p>{dataState.isFetching ? 'Fetching transcript...' : ''}</p>
					</div>
					{!isEmbed &&
					<div className="absolute close-button">
						<Tooltip
							title={isTheater ? 'Collapse transcript' : 'Expand transcript'}
							position="top"
							open={tooltipOpened}
						>
							<button
								type="button"
								onClick={toggleCollapse}
								onBlur={() => setTooltipOpened(false)}
								onFocus={() => setTooltipOpened(true)}
								onMouseEnter={() => setTooltipOpened(true)}
								onMouseLeave={() => setTooltipOpened(false)}
								aria-label={
									isTheater ? 'Collapse transcript' : 'Expand transcript'
								}
								className="tw-btn tw-btn-dark rounded-full w-10 h-10 flex items-center justify-center"
							>
								{!isTheater && (
									<i className="fas fa-angle-double-down text-xl" />
								)}
								{isTheater && <i className="fas fa-angle-double-up text-xl" />}
							</button>
						</Tooltip>
					</div>
					}
				</div>
			</Search>
		</StyledTranscript>
	)
}

export default Transcript
