// View.styled.js
import styled from 'styled-components'

export const StyledTranscript = styled.section`
	.transcript-title {
		padding-left: ${(props) => (props.isTheater ? '2.5rem' : '')};
	}

	.close-button {
		right: 20px;
		bottom: 20px;
	}

	.transcript-collapsed::after {
		content: '';
		width: 100%;
		height: 200px;
		position: absolute;
		bottom: 0;
		left: 0;
		background: linear-gradient(
			0deg,
			rgba(255, 255, 255, 1),
			rgba(255, 255, 255, 0)
		);
	}
`
