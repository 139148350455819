/**
 * Browse by Target Device.
 */
import React from 'react'

import Browse from '../Browse'

const Device = () => {
	return (
		<Browse
			filterHeading="Browse by Device"
			pageTitle="Browse by Device"
			componentId="selected-device"
			dataField="target-platform.keyword"
			filterLabel="Device"
			loaderMessage="Loading device browse"
			selectAllLabel="All Devices"
		/>
	)
}

export default Device
