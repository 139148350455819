/**
 * Footer.
 */
import React, { useRef, useEffect } from 'react'
import CookieConsent from 'react-cookie-consent'
import { StyledFooter } from './Footer.styled'

const Footer = () => {
	const cookieDialog = useRef()

	useEffect(() => {
		/**
		 * Need setTimeout as we cannot control child's rendering
		 */
		setTimeout(() => {
			if (cookieDialog.current) {
				const cookieBlocks = cookieDialog.current.querySelectorAll('div')
				const cookieButton = cookieDialog.current.querySelector('button')
				if (cookieBlocks.length && cookieButton) {
					cookieBlocks[1].setAttribute('id', 'cookie-description')
					cookieButton.setAttribute('aria-describedby', 'cookie-description')
					cookieButton.focus()
				}
			}
		}, 700)
	}, [])

	if (document.location.href.indexOf('/embed/') > 0) {
		return <div />
	}

	return (
		<StyledFooter className="relative">
			<div className="container pt-1" role="contentinfo">
				<div className="flex tagline w-11/12 mx-auto">
					<div className="text-left flex items-center justify-between tagtext w-full px-5">
						<div className="bg-white">We tame wild content</div>
						<div className="flex justify-end items-center">
							<a
								href="https://coherentdigital.net/"
								className="tw-btn tw-btn-tertiary dark-outline"
								target="_blank"
								rel="noopener noreferrer"
							>
								See other products
							</a>
						</div>
					</div>
				</div>
			</div>
			<div className="container" role="navigation">
				<div className="flex w-11/12 mx-auto footer-links py-6 flex-wrap">
					<div className="w-1/2 md:w-auto order-1 flex-grow flex md:justify-center">
						<a className="footer-logo-link" href="https://coherentdigital.net">
							<img
								className="footer-logo"
								src="/COH.logo.bw.main-neg.RGB.png"
								alt="Coherent Digital"
							/>
						</a>
					</div>
					<div className="mt-5 md:mt-0 w-1/2 md:w-auto order-3 flex-grow text-left">
						<h2 className="text-gray-400 text-lg">About</h2>
						<ul>
							<li>
								<a className="light-outline text-white text-s" href="/about">
									Mindscape Commons
								</a>
							</li>
							<li>
								<a
									className="light-outline text-white text-s"
									href="https://coherentdigital.net/vision"
									target="_blank"
									rel="noopener noreferrer"
								>
									Our Mission
								</a>
							</li>
							<li>
								<a
									className="light-outline text-white text-s"
									href="https://coherentdigital.net/terms-of-use"
									target="_blank"
									rel="noopener noreferrer"
								>
									Terms of Use
								</a>
							</li>
							<li>
								<a
									className="light-outline text-white text-s"
									href="https://coherentdigital.net/privacy-policy"
									target="_blank"
									rel="noopener noreferrer"
								>
									Privacy Policy
								</a>
							</li>
							<li>
								<a
									className="light-outline text-white text-s"
									href="https://coherentdigital.net/contact-us"
									target="_blank"
									rel="noopener noreferrer"
								>
									Contact Us
								</a>
							</li>
						</ul>
					</div>
					<div className="mt-5 md:mt-0 w-1/2 md:w-auto order-3 flex-grow text-left">
						<h2 className="text-gray-400 text-lg">Membership</h2>
						<ul>
							<li>
								<a
									className="light-outline text-white text-s"
									href="/about/membership"
								>
									Becoming a Member
								</a>
							</li>
							<li>
								<a
									className="light-outline text-white text-s"
									href="/about/community"
								>
									Join our Community
								</a>
							</li>
						</ul>
					</div>
					<div className="w-1/2 md:w-16 order-2 md:order-4 flex-grow">
						<h2 className="light-outline text-gray-400 text-lg mb-5">
							Newsletter
						</h2>
						<div>
							<a
								className="light-outline w-full block tw-btn tw-btn-primary"
								href="https://coherentdigital.us20.list-manage.com/subscribe/post?u=d55edcaa77ac7fe8cec6e9ad6&id=e183ecd55b&group%5B6178%5D%5B2%5D=1"
								target="_blank"
								rel="noopener noreferrer"
							>
								Subscribe
							</a>
						</div>
					</div>
				</div>
			</div>
			<a
				className="light-outline hidden sm:inline-block absolute right-0 bottom-0 text-white mr-4 mb-4"
				href="#logo-link"
			>
				Back to Top
			</a>
			<div ref={cookieDialog}>
				<CookieConsent>
					By using this website, you agree to our use of cookies. We use cookies
					to provide you with a great experience and to help our website run
					effectively.
				</CookieConsent>
			</div>
		</StyledFooter>
	)
}

export default Footer
