export const config = {
	sitePostfix: ' - Mindscape Commons',
}

export const sortOptions = {
	title: {
		label: 'Sort by Title',
		dataField: 'title.keyword',
		sortBy: 'asc',
	},
	publisher: {
		label: 'Sort by Publisher',
		dataField: 'publisher.name.keyword',
		sortBy: 'asc',
	},
	relevance: {
		label: 'Sort by Relevance',
		dataField: '_score',
		sortBy: 'desc',
	},
}

// LibLynx Analytics Provider ID used in COUNTER tracking
export const llPid = 'beea312d937c640a'

export const manifestFileName = 'video-object-manifest.json'

export const urlPrefix = 'd1qq4p6p3fiwyq'
