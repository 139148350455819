/**
 * List New Titles.
 */
import React from 'react'
import { Helmet } from 'react-helmet'
import { StyledNew } from './New.styled'

const New = () => {
	return (
		<StyledNew>
			<Helmet>
				<title>New Titles - Mindscape Commons</title>
			</Helmet>
			<h2>New Titles on Mindscape Commons</h2>
			<p>This page is under construction</p>
		</StyledNew>
	)
}

export default New
