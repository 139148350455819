/**
 * Browse Specific Collection Content.
 *
 */
import React from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import {
	ReactiveList,
	ResultCard,
	SelectedFilters,
} from '@appbaseio/reactivesearch'
import ReactMarkdown from 'react-markdown'
import { StyledCollection } from './Collection.styled'
import withLink from '../withLink'
import { trackSearch } from '../../utils/TrackingUtils'
import customReactiveList from '../customReactiveList'
import customSelectedFilters from '../customSelectedFilters'

/**
 * A function to generate a default query based on the view id.
 *
 * @param slug
 * @returns elastic query
 */
function ViewQuery(slug) {
	this.memberQuery = () => {
		return {
			query: {
				match: {
					'channel.slug.keyword': slug,
				},
			},
		}
	}

	this.channelQuery = () => {
		return {
			query: {
				match: {
					'slug.keyword': slug,
				},
			},
		}
	}
}

const CustomReactiveList = customReactiveList(ReactiveList)
const CustomSelectedFilters = customSelectedFilters(SelectedFilters)
const WithLinkResultCard = withLink(ResultCard)

const Collection = () => {
	const { slug } = useParams()
	const viewQuery = new ViewQuery(slug)

	return (
		<StyledCollection>
			<div className="container flex flex-col sm:flex-row" id="browse">
				<div className="left-side-bar flex flex-wrap flex-col">
					<ReactiveList
						componentId="channel-meta"
						dataField="title"
						showResultStats={false}
						size={1}
						defaultQuery={viewQuery.channelQuery}
						loader="Loading..."
						innerClass={{
							title: 'channel-title',
						}}
					>
						{({ data }) => (
							<>
								<ReactiveList.ResultListWrapper>
									{data.map((item, index) => (
										<div key={item._id}>
											<h1 className="sr-only">{item.title}</h1>
											{!index && (
												<Helmet>
													<title>{item.title}</title>
												</Helmet>
											)}
											{item['collection-type'] && (
												<h2 className="collection-type">
													{item['collection-type']}
												</h2>
											)}
											<h2 className="channel-title">{item.title}</h2>
											{item.image && item.image[0].thumbnails && (
												<img
													src={item.image[0].thumbnails.large.url}
													className="channel-image"
													alt={item.title}
												/>
											)}
											<div className="detail">
												{item.purpose && (
													<ReactMarkdown
														source={item.purpose}
														renderers={{
															paragraph: (props) => <div {...props} />,
														}}
													/>
												)}
											</div>
											{item.url && (
												<div>
													<a
														className="button"
														href={`${item.url}`}
														target="_project"
														rel="noopener noreferrer"
														title="Opens in another window"
													>
														Go To Project Site
													</a>
												</div>
											)}
										</div>
									))}
								</ReactiveList.ResultListWrapper>
							</>
						)}
					</ReactiveList>
				</div>
				<div className="">
					<CustomSelectedFilters
						showClearAll
						clearAllLabel="Clear filters"
						className="filter-button-row"
						innerClass={{
							button: 'filter-button',
						}}
					/>
					<CustomReactiveList
						componentId="results-collection"
						dataField="title"
						size={40}
						defaultQuery={viewQuery.memberQuery}
						sortOptions={[
							{
								label: 'Sort by Relevance',
								dataField: '_score',
								sortBy: 'desc',
							},
							{
								label: 'Sort by Title',
								dataField: 'title.keyword',
								sortBy: 'asc',
							},
						]}
						defaultSortOption="Sort by Title"
						loader="Loading..."
						noResults="No results were found..."
						renderResultStats={(stats) => {
							trackSearch()
							return (
								<div className="result-count mr-5">
									{stats.numberOfResults} results
								</div>
							)
						}}
						react={{
							and: ['q', 'channel-meta'],
						}}
						innerClass={{
							title: 'result-title',
							sortOptions: 'sort-options',
							resultStats: 'result-stats',
							resultsInfo: 'result-list-info',
						}}
					>
						{({ data }) => (
							<>
								<ReactiveList.ResultCardsWrapper
									className="mt-3"
									id="result-list"
								>
									{data.map((item) => (
										<WithLinkResultCard
											key={item._id}
											href={`/view/${item.slug}`}
											target="_self"
											className="result-item"
											item={item}
										>
											{item.image && item.image[0].thumbnails && (
												<ResultCard.Image
													src={item.image[0].thumbnails.large.url}
													className="result-image"
												/>
											)}
											<ResultCard.Title className="result-title">
												{item.title.split(' ').slice(0, 15).join(' ')}
												{item.title.split(' ').length > 15 && '...'}
											</ResultCard.Title>
											<ResultCard.Description>
												<div className="flex column justify-space-between">
													<div className="row">
														<div className="column">
															<div className="detail">
																{item.publisher && item.publisher[0].name}
																{item.publisher && ', '}
																{item.publisher &&
																	item.publisher[0].country &&
																	item.publisher[0].country}
																{item.publisher &&
																	item.publisher[0].country &&
																	item['release-date'] !== 1999 &&
																	', '}
																{item['release-date'] &&
																	item['release-date'] !== 1999 &&
																	'('}
																{item['release-date'] &&
																	item['release-date'] !== 1999 &&
																	item['release-date']}
																{item['release-date'] &&
																	item['release-date'] !== 1999 &&
																	')'}
															</div>
														</div>
													</div>
												</div>
											</ResultCard.Description>
										</WithLinkResultCard>
									))}
								</ReactiveList.ResultCardsWrapper>
							</>
						)}
					</CustomReactiveList>
				</div>
			</div>
		</StyledCollection>
	)
}

export default Collection
