// Menu.styled.js
import styled from 'styled-components'

export const StyledMenu = styled.nav`
	width: 100%;
	position: fixed;
	right: ${({ open }) => (open ? '0' : '500%')};
	transition: transform 0.3s ease-in-out;
	z-index: 9;
	background: ${({ theme }) => theme.captionBackground};
	/* for high-contrast mode */
	border: 1px solid rgba(255, 255, 255, 0);

	@media (max-width: ${({ theme }) => theme.mobile}) {
		font-size: 1rem;
	}

	.menu-title {
		color: ${({ theme }) => theme.captionNormal};
		font-size: x-small;
		font-weight: 300;
		padding-bottom: 5px;
	}

	.menu-header a {
		color: ${({ theme }) => theme.captionBold};
		text-decoration: none;
		font-size: small;
		&:hover {
			color: ${({ theme }) => theme.captionBold};
		}
	}

	.menu-header a:focus {
		outline: 2px solid #fff;
	}

	.column-four {
		width: 270px;
	}

	.sponsor-link {
		background: url('/sponsor-mask-background.svg');
		background-color: #fff;
		height: 100px;
	}

	.sponsor-link div {
		font-weight: 500;
		text-shadow: 1px 1px 1px #000;
	}
`
