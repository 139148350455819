// theme.js
export const theme = {
	/* Primary Page Elements: Page content such as static pages, body content */

	/* Text colors contrast with the primary background */
	/* 'Light' color means text is muted compared to 'Normal' */
	/* 'Normal' color means text is readable, not muted */
	/* 'Bold' color means text is emphasized compared to 'Normal' */
	primaryLight: '#33322D',
	primaryNormal: '#111111',
	primaryBold: '#000000',
	primaryHighlight: '#2020B2',
	/* Primary background; the color behind primary divs */
	primaryBackground: 'rgba(255, 255, 255, 1)',
	primaryBackgroundTranslucent: 'rgba(255, 255, 255, 0.7)',
	primaryBackgroundTransparent: 'rgba(255, 255, 255, 0.3)',

	/* Secondary Page Elements: Search Results, Popups, Facet Panel */
	/* Text colors contrast with the secondary background */
	secondaryLight: '#666666',
	secondaryNormal: '#222222',
	secondaryBold: '#000000',
	/* Secondary background; color behind secondary divs */
	secondaryBackground: 'rgba(255, 255, 255, 1)',
	secondaryBackgroundTranslucent: 'rgba(255, 255, 255, 0.7)',
	secondaryBackgroundTransparent: 'rgba(255, 255, 255, 0.3)',

	/* Tertiary - Navbar /*
  /* Text colors contrast with the tertiary background */
	tertiaryLight: '#D8D8D8',
	tertiaryNormal: '#E8E8E8',
	tertiaryBold: '#FFFFFF',
	/* Tertiary background */
	tertiaryBackground: 'rgba(32, 32, 178, 1)',
	tertiaryBackgroundSemiTranslucent: 'rgba(32, 32, 178, 0.9)',
	tertiaryBackgroundTranslucent: 'rgba(32, 32, 178, 0.8)',
	tertiaryBackgroundTransparent: 'rgba(32, 32, 178, 0.3)',

	/* Footer, Captions, Menu /*
  /* Text colors contrast with the footer background */
	captionLight: '#817F71',
	captionNormal: '#EFEFEF',
	captionBold: '#FFFFFF',
	/* Tertiary background */
	captionBackground: 'rgba(60, 59, 50, 1)',
	captionBackgroundTranslucent: 'rgba(60, 59, 50, 0.7)',
	captionBackgroundTransparent: 'rgba(60, 59, 50, 0.3)',

	/* Accent color used in borders, fontawesome icons */
	/* accent: '#2020B2', */
	accent: '#7B2031',

	/* Deprecate this class ! */
	imageBackground: '#666666',

	mobile: '576px',
	fontFamily:
		'Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
}
