/**
 * InfoGraphic.
 */
import React from 'react'
import { ReactiveComponent } from '@appbaseio/reactivesearch'
import { Helmet } from 'react-helmet'
import { StyledInfoGraphic } from './InfoGraphic.styled'
import AggTotals from './AggTotals'

const InfoGraphic = () => {
	return (
		<StyledInfoGraphic>
			<Helmet>
				<title>Infographic - Mindscape Commons</title>
			</Helmet>
			<h1 id="igheader" className="text-black text-center">
				Mindscape Commons by the Numbers
			</h1>
			{/* This div displays an aggregation on various doc filters */}
			<div className="sig">
				<ReactiveComponent
					componentId="statsSummary"
					defaultQuery={() => ({
						size: 0,
						aggs: {
							projects: {
								filter: {
									term: {
										rectype: 'experience',
									},
								},
							},
							have_sup_matter: {
								filter: {
									exists: {
										field: 'supplementary-materials.url',
									},
								},
							},
							have_preview: {
								filter: {
									wildcard: {
										'supplementary-materials.material-type.keyword': {
											value: 'Preview',
										},
									},
								},
							},
							have_teacher_materials: {
								filter: {
									wildcard: {
										'supplementary-materials.material-type.keyword': {
											value: 'Teacher Materials',
										},
									},
								},
							},
							publishers: {
								cardinality: {
									field: 'publisher.name.keyword',
								},
							},
							countries: {
								cardinality: {
									field: 'publisher.country.keyword',
								},
							},
							subjects: {
								cardinality: {
									field: 'subjects.subject.keyword',
								},
							},
							country: {
								terms: {
									field: 'publisher.country.keyword',
									size: 10,
								},
							},
							publisher: {
								terms: {
									field: 'publisher.name.keyword',
									size: 10,
								},
							},
							subject: {
								terms: {
									field: 'subjects.subject.keyword',
									size: 100,
								},
							},
						},
					})}
					render={({ aggregations }) => (
						<div>
							<AggTotals aggregations={aggregations} />
						</div>
					)}
				/>
			</div>
		</StyledInfoGraphic>
	)
}

export default InfoGraphic
