/**
 * Browse of Exclusive Content.
 */
import React from 'react'

import Browse from '../Browse'

const Exclusive = () => {
	return (
		<Browse
			filterHeading="Browse by Subject"
			pageTitle="Exclusively on Mindscape Commons"
			componentId="exclusive-subject"
			dataField="subjects.subject.keyword"
			filterLabel="Subject"
			defaultQuery={{ exclusive: true }}
			loaderMessage="Loading subjects browse"
			selectAllLabel="All Subjects"
			placeholder="Search for a Subject"
			showSearch
		/>
	)
}

export default Exclusive
