/**
 * Submit a New Entry to Mindscape Commons.
 */
import React from 'react'
import { Helmet } from 'react-helmet'
import { StyledSubmit } from './Submit.styled'

const Submit = () => {
	return (
		<StyledSubmit>
			<Helmet>
				<title>Submit an Entry - Mindscape Commons</title>
			</Helmet>
			<h1>Submit a New Entry to Mindscape Commons</h1>
			<p>
				Mindscape Commons is a collaborative membership. We encourage faculty,
				students, librarians, researchers, and practitioners to upload any
				projects they have completed, or are currently working on. We hope that
				collectively we can accelerate the development of this increasingly
				important field by sharing and collaborating.
			</p>
			<p>
				Before uploading a submission, please review our{' '}
				<a
					href="https://coherentdigital.net/file-upload-policy"
					target="_blank"
					rel="noopener noreferrer"
				>
					File Upload Policy
				</a>{' '}
				and our Mindscape Commons{' '}
				<a href="/about/guidelines">editorial guidelines for submissions</a>.
			</p>
			<script src="https://static.airtable.com/js/embed/embed_snippet_v1.js" />
			<iframe
				title="Submission Form"
				className="airtable-embed airtable-dynamic-height"
				src="https://airtable.com/embed/shrHPucb5URQXuTGa?backgroundColor=red"
				frameBorder="0"
				onmousewheel=""
				width="100%"
				height="1544"
				style={{
					background: 'transparent',
					border: '1px solid #ccc',
				}}
			/>
		</StyledSubmit>
	)
}

export default Submit
