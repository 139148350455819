/**
 * Help pages.
 */
import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { StyledHelp } from './Help.styled'

const Help = () => {
	return (
		<StyledHelp>
			<Helmet>
				<title>Help - Mindscape Commons</title>
			</Helmet>
			<h1 className="mb-10">Help - Table of Contents</h1>

			<div className="ml-5">
				<strong>
					<a href="#about">About Mindscape Commons</a>
				</strong>
			</div>
			<div className="ml-5">
				<strong>
					<a href="#nav">Navigation</a>
				</strong>
			</div>
			<div className="ml-5">
				<strong>
					<a href="#search">Searching & Browsing</a>
				</strong>
			</div>
			<div className="ml-5">
				<strong>
					<a href="#watch">How to Watch</a>
				</strong>
			</div>
			<div className="ml-5">
				<strong>
					<a href="/help/vrapp">Installing and Using the VR Application</a>
				</strong>
			</div>
			<div className="ml-5">
				<strong>
					<a href="#content">Content</a>
				</strong>
			</div>
			<div className="ml-5">
				<strong>
					<a href="#membership">Membership Options</a>
				</strong>
			</div>
			<div className="ml-5">
				<strong>
					<a href="#glossary">Glossary of Interactive and Immersive Media</a>
				</strong>
			</div>
			<div className="ml-5">
				<strong>
					<a href="#support">Support</a>
				</strong>
			</div>

			<h2 id="about">About Mindscape Commons</h2>
			<p>
				Mindscape Commons is the first and most comprehensive source of
				immersive and interactive content in mental health. It includes and
				documents nearly 200 experiences relevant for teaching and research in
				counseling, social work, psychology, nursing, and other related
				programs. Content is focused around three core areas:
			</p>

			<ul className="ml-10 list-disc">
				<li>Premium video content. 360°/180° immersive and /or interactive.</li>
				<li>Open access 360° immersive video content.</li>
				<li>
					Research project and commercial pages that feature the latest research
					and use cases within VR and mental health.
				</li>
			</ul>

			<p>
				Mindscape Commons features a collaborative platform that encourages
				community involvement around emerging technologies and mental health.
				Members can upload projects, link out to content, and create user
				profiles. Mindscape will be ever-evolving and growing as more members
				join to use and contribute content.
			</p>

			<h1 className="mt-5" id="nav">
				Navigation
			</h1>
			<p>
				Open the menu of destinations using the{' '}
				<i className="fa fa-bars" aria-hidden="true" /> icon in the upper right.
				Click on the Mindscape Commons logo on the upper left to return to the
				Home page.
			</p>

			<h1 className="mt-5" id="search">
				Search and Browse
			</h1>
			<p>There are multiple ways to discover content</p>

			<p>
				<strong>Keyword search</strong>: If you know what you’re looking for,
				start with the Quick Search bar at the top of your screen.
			</p>

			<p>
				<strong>All Titles</strong>: Showcase all 190+ Mindscape Commons
				entries. This number will grow as more content is added to the platform.
			</p>

			<p>
				<strong>Sorting</strong>: You can sort titles by Relevance, Title and
				Date.
			</p>

			<p>
				<strong>Collections</strong>: Collections consist of smaller, curated
				content according to topics, publisher, or series.
			</p>

			<p>
				<strong>Facets</strong>: Facets are featured to the left of screen to
				better help refine and narrow a search. Search by Subject, Publisher,
				Release Date, Immersion Factor, Target Platform and more.
			</p>

			<p>
				<strong>Icons</strong>: Icons, located in the lower right side of
				project tiles, will help you determine exclusive Mindscape Commons
				content, Project or Commercial Pages, and Open Access content.
			</p>

			<p>
				<strong>Links</strong>: When viewing the details of any experience,
				links on the page lead to other items with the same features.
			</p>

			<p>
				<strong>See Also</strong>: Every experience has a featured set of
				curated See Also links to similar experiences.
			</p>

			<h1 className="mt-5" id="watch">
				How to Watch
			</h1>
			<h2>Watching the experiences - what equipment do I need?</h2>

			<p>
				Mindscape Commons experiences run on a wide variety of devices,
				browsers, and applications. Experiences hosted on other platforms will
				have different hardware requirements.
			</p>

			<h2>Desktop, Laptop or Tablet</h2>
			<p>
				You can experience all of Mindscape Commons content on a modern browser
				on any of these devices. You can interact with VR content using your
				mouse, trackpad or touch-screen gestures to rotate the scene or to click
				on hotspots.
			</p>

			<h2>Mobile Web</h2>
			<p>
				In this mode, you can interact with VR content on your mobile device
				using a browser. You may use your touch-screen gestures to rotate the
				scene or to click on hotspots.
			</p>

			<h2>Mobile Web with Cardboard</h2>
			<p>
				In this mode, you can interact with VR content placing your mobile
				device in a Cardboard HMD. You can use your head movement to rotate the
				scene. To activate on hotspots you can focus the eye-tracker dot on a
				hotspot, and when your gaze comes to rest on it for a couple seconds,
				the hotspot will be activated.
			</p>

			<h2>Oculus HMD</h2>
			<p>
				In this mode, you can interact with the VR content on your Oculus
				device. <Link to="/help/vrapp/install">See instructions</Link> on how to
				set up.
			</p>

			<h1 className="mt-5" id="content">
				Content included in Mindscape Commons
			</h1>
			<h2>Bibliography</h2>
			<p>
				See all the content currently in Mindscape Commons. As Mindscape grows,
				the bibliography will be updated frequently.
			</p>

			<h2>Supplementary materials</h2>
			<p>
				Take advantage of all the supplementary materials associated with
				content, such as:
			</p>

			<div className="ml-5">
				<p>
					<strong>
						<i>Teaching guides</i>
					</strong>{' '}
					include information on how to use content, what courses content fits
					into, assessment and group discussion questions, book chapters, and
					more.
				</p>
				<p>
					<strong>
						<i>Transcripts</i>
					</strong>{' '}
					are available with all premier Mindscape content.
				</p>
				<p>
					<strong>
						<i>Articles and brochures</i>
					</strong>{' '}
					that relate to specific content.
				</p>
				<p>
					<strong>
						<i>Introductory videos</i>
					</strong>{' '}
					that provide an overview of the content.
				</p>
			</div>

			<h1 className="mt-5" id="membership">
				Membership Options
			</h1>
			<p>
				There are several membership levels with Mindscape Commons.
				Institutional membership gives you the most content, plus all{' '}
				<a href="/about/community">community features</a>. Learn more{' '}
				<a href="/about/membership">here</a>.
			</p>

			<h1 className="mt-5" id="glossary">
				Glossary
			</h1>
			<p>
				We hope to collectively accelerate the development of this increasingly
				important field by sharing and collaborating. The{' '}
				<a href="/help/glossary">glossary</a> provides explanations of concepts
				relevant to interactive and immersive media.
			</p>

			<h1 className="mt-5" id="support">
				Support
			</h1>
			<p>
				Contact Support with any technical questions:{' '}
				<a href="mailto:support@coherentdigital.net">
					support@coherentdigital.net
				</a>
			</p>
		</StyledHelp>
	)
}

export default Help
