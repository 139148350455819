// Embed.styled.js
import styled from 'styled-components'

export const StyledEmbed = styled.section`
	background: ${({ theme }) => theme.primaryBackground};
	@media (min-width: ${({ theme }) => theme.mobile}) {
		width: 90%;
	}
	margin-top: 0px;
	margin-left:  auto;
	margin-right: auto;

	.view .column {
		margin: 0px;
	}

	.vo-transcript {
		max-height: 50vh;
	}

	.ost {
		width: 100%;
		max-height: 90%;
		border: 1px solid red;
		width: fit-content;
		overflow-y: scroll;
		border: 1px solid ${({ theme }) => theme.primaryNormal};
	}

	p {
		margin-bottom: 10px;
		margin-left: 10px;
		text-align: left;
		width: 95%;
		color: ${({ theme }) => theme.primaryNormal};
		font-size: smaller;
	}

	h2 {
		font-size: large;
		font-weight: 400;
		margin-top: 10px;
		margin-bottom: 10px;
		text-align: left;
		color: ${({ theme }) => theme.primaryLight};
	}

	.vr-embed,
	.vimeo-embed,
	.yt-embed {
		overflow: hidden;
		padding-top: 56.25%;
		position: relative;
	}

	.vr-iframe,
	.vimeo-iframe,
	.yt-iframe {
		border: 0;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}

	#experience-image img {
		object-fit: cover;
		width: 100%;
	}

	.cid {
		color: ${({ theme }) => theme.primaryLight};
		font-size: x-small;
		margin-bottom: 10px;
	}

	.cid img {
		width: 16px;
		vertical-align: middle;
		margin-right: 3px;
	}

	.view,
	.title,
	.summary,
	.meta {
		@media (min-width: ${({ theme }) => theme.mobile}) {
			width: 90vw;
		}
		@media (max-width: ${({ theme }) => theme.mobile}) {
			width: 90vw;
		}
	}

	.video-object {
		text-align: left;
		color: #fff !important;
	}
`
