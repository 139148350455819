/**
 * Modal to display a share button for Twitter and Facebook.
 * Usage:
 *
 * import Share from '../utils/Share';
 * <Share text="Page title" url="Location of page"/>
 *
 */
import React, { useState, useRef } from 'react'
import Modal from 'react-modal'
import { Tooltip } from 'react-tippy'

const customStyles = {
	overlay: {
		backgroundColor: 'rgba(8, 8, 8, 0.50)',
	},
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		padding: '0',
		transform: 'translate(-50%, -50%)',
		width: '350px',
	},
}

// Bind modal to the appElement.  This allows the model to disable the background.
// See: http://reactcommunity.org/react-modal/accessibility/
Modal.setAppElement('#root')

function Share(props) {
	const [tooltipOpened, setTooltipOpened] = useState(false)

	const subtitle = useRef()
	const twlink = useRef()
	const fblink = useRef()

	const [modalIsOpen, setIsOpen] = React.useState(false)
	function openModal() {
		setIsOpen(true)
	}

	function afterOpenModal() {
		// references are now sync'd and can be accessed.
		subtitle.current.style.color = '#111111'
		twlink.current.href = `https://twitter.com/intent/tweet?text=${encodeURI(
			props.text
		)}&url=${props.url}`
		fblink.current.href = `https://www.facebook.com/sharer/sharer.php?u=${props.url}`
	}

	function closeModal() {
		setIsOpen(false)
	}

	return (
		<span className="share_modal">
			<Tooltip title="Share This Page" position="top" open={tooltipOpened}>
				<button
					type="button"
					onClick={openModal}
					onBlur={() => setTooltipOpened(false)}
					onFocus={() => setTooltipOpened(true)}
					onMouseEnter={() => setTooltipOpened(true)}
					onMouseLeave={() => setTooltipOpened(false)}
				>
					<img src="/share-icon.svg" alt="Share This Page" />
				</button>
			</Tooltip>
			<Modal
				isOpen={modalIsOpen}
				onAfterOpen={afterOpenModal}
				onRequestClose={closeModal}
				style={customStyles}
				contentLabel="Share Modal"
			>
				<div className="modal-header">
					<h2 ref={subtitle} className="modal-title">
						Share
					</h2>
					<button
						type="button"
						aria-label="Close Modal"
						onClick={closeModal}
						className="close"
					>
						<span aria-hidden="true">x</span>
					</button>
				</div>
				<div className="modal-body">
					<div className="row">
						<div className="column">
							<a
								className="btn twitter-button"
								role="button"
								href="https://twitter.com/intent/tweet?text=&url="
								target="_blank"
								rel="noopener noreferrer"
								ref={twlink}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="16"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
									className="feather feather-twitter"
								>
									<path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z" />
								</svg>{' '}
								Twitter
							</a>
						</div>
						<div className="column">
							<a
								className="btn facebook-button"
								role="button"
								href="https://www.facebook.com/sharer/sharer.php"
								target="_blank"
								rel="noopener noreferrer"
								ref={fblink}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="16"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
									className="feather feather-facebook"
								>
									<path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z" />
								</svg>{' '}
								Facebook
							</a>
						</div>
					</div>
				</div>
			</Modal>
		</span>
	)
}

export default Share
