/**
 * ViewVideoBlock
 */
import React from 'react'

import ViewVR from './VideoVR'
import VideoVimeo from './VideoVimeo'
import VideoYoutube from './VideoYoutube'
import { urlPrefix } from '../../../constants'

const ViewVideoBlock = ({ item, authorizations }) => {
	const isVr = item?.['url']?.indexOf(urlPrefix) !== -1
	const materials =
		item?.['supplementary-materials']?.filter(
			(sm) => sm['material-type'] === 'Preview'
		) ?? []
	const isSphereApp =
		isVr &&
		authorizations.institution &&
		item?.['scenery-type'][0]?.indexOf('Video') !== -1
	const isWebGl =
		isVr &&
		authorizations.institution &&
		item?.['scenery-type'][0]?.indexOf('Computer Generated') !== -1

	let vimeo = materials.find((st) => st.url.indexOf('vimeo') !== -1)
	let youtube = materials.find((st) => st.url.indexOf('youtu') !== -1)

	// Don't allow previews for visitors.
	if (authorizations.visitor) {
		vimeo = null
		youtube = null
	}

	// Preview
	const preview =
		!isVr && (vimeo || youtube) ? (
			<section className="h-full">
				{/* If there is a Vimeo preview, frame that experience */}
				{!isVr && vimeo && (
					<VideoVimeo
						vimeo={vimeo}
						item={item}
						analytics="trackItemInvestigation"
					/>
				)}
				{/* If there is a YouTube Preview URL, frame that experience */}
				{!isVr && youtube && (
					<VideoYoutube
						youtube={youtube}
						item={item}
						analytics="trackItemInvestigation"
					/>
				)}
				<h2>[Preview]</h2>
			</section>
		) : (
			''
		)

	// AppHelper
	let appHelper = ''
	let appSrHelper = ''
	if (isSphereApp) {
		appHelper = (
			<p className="text-center italic">
				Click on &quot;View in 360°&quot; above to start the experience, then
				click on{' '}
				<i aria-label="arrows icon" className="fas fa-expand-arrows-alt" /> to
				expand to full screen
			</p>
		)
		appSrHelper = (
			// eslint-disable-next-line
			<span className="sr-only" tabIndex={0}>
				being on the following iframe click on View in 360 button to start the
				experience, then click on arrows icon to expand to full screen
			</span>
		)
	} else if (isWebGl) {
		appHelper = (
			<p className="text-center italic">
				Drag your cursor inside the box to explore the experience,
				<br />
				click on <i aria-label="arrows icon" className="fas fa-expand-alt" /> to
				expand to full screen
			</p>
		)
		appSrHelper = (
			// eslint-disable-next-line
			<span className="sr-only" tabIndex={0}>
				being on the following iframe click on click on arrows icon to expand to
				full screen
			</span>
		)
	}

	return (
		<div className="h-full">
			{/* If there is a MICO title URL, frame that experience */}
			{appSrHelper}
			{isVr && <ViewVR item={item} authorizations={authorizations} />}

			{/* Vimeo or Youtube Preview */}
			{preview}
			{/* SphereApp or WebGL helper */}
			{appHelper}

			{/* Image */}
			{item?.image?.[0]?.thumbnails && !youtube && !vimeo && !isVr && (
				<div className="h-full" id="experience-image">
					<img
						className="h-full"
						src={item.image[0].thumbnails.large.url}
						alt={item.title}
					/>
				</div>
			)}
		</div>
	)
}

export default ViewVideoBlock
