/**
 * VideoVimeo
 */
import React from 'react'
import {
	trackItemInvestigation,
	trackItemRequest,
} from '../../../utils/TrackingUtils'

const VideoVimeo = ({ vimeo, item, analytics }) => {
	// Detects a click in the iframe allowing is to emit a tracking event.
	const iframewatcher = setInterval(() => {
		const activeE = document.activeElement
		if (activeE && activeE.tagName === 'IFRAME') {
			if (analytics === 'trackItemRequest') {
				trackItemRequest('other_free_to_read', item)
			} else if (analytics === 'trackItemInvestigation') {
				trackItemInvestigation('other_free_to_read', item)
			}
			clearInterval(iframewatcher)
		}
	}, 1000)

	return (
		<div className="h-full flex flex-col">
			<div className="h-full vimeo-embed">
				<iframe
					className="h-full vimeo-iframe"
					title="Vimeo embed"
					src={`https://player.vimeo.com/video/${vimeo.url.substring(
						vimeo.url.lastIndexOf('/') + 1
					)}?title=0&byline=0&portrait=0`}
					allow="autoplay; fullscreen"
					allowFullScreen
					webkitallowfullscreen="true"
					mozallowfullscreen="true"
					msallowfullscreen="true"
				/>
				<script src="https://player.vimeo.com/api/player.js" />
			</div>
		</div>
	)
}

export default VideoVimeo
