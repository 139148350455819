/**
 * VideoYoutube
 */
import React from 'react'
import {
	trackItemInvestigation,
	trackItemRequest,
} from '../../../utils/TrackingUtils'

const VideoYoutube = ({ youtube, item, analytics }) => {
	function getYoutubeId(youtubeUrl) {
		const versionId = youtubeUrl.match(/vi?=([-_a-zA-Z\d]+)/)?.[1]
		const embedId = youtubeUrl.match(/\/([-_a-zA-Z\d]+)(\?|$)/)?.[1]
		return versionId || embedId
	}

	// Detects a click in the iframe allowing us to emit a tracking event.
	const iframewatcher = setInterval(() => {
		const activeE = document.activeElement
		if (activeE && activeE.tagName === 'IFRAME') {
			if (analytics === 'trackItemRequest') {
				trackItemRequest('other_free_to_read', item)
			} else if (analytics === 'trackItemInvestigation') {
				trackItemInvestigation('other_free_to_read', item)
			}
			clearInterval(iframewatcher)
		}
	}, 1000)

	return (
		<div className="h-full flex flex-col">
			<div className="j-full yt-embed">
				<iframe
					className="yt-iframe"
					title="Youtube embed"
					src={`https://www.youtube.com/embed/${getYoutubeId(
						youtube.url
					)}?autoplay=0&fs=1&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0`}
					allowFullScreen
					webkitallowfullscreen="true"
					mozallowfullscreen="true"
					msallowfullscreen="true"
					height="350"
					type="text/html"
				/>
			</div>
		</div>
	)
}

export default VideoYoutube
