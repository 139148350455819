// Guidelines.styled.js
import styled from 'styled-components'

export const StyledGuidelines = styled.section`
	padding: 20px;
	background-color: ${({ theme }) => theme.secondaryBackground};
	border-radius: 5px;
	width: 75vw;
	margin: auto;

	p {
		width: 50%;
		margin-top: 20px;
		margin-bottom: 20px;
		margin-left: auto;
		margin-right: auto;
		color: ${({ theme }) => theme.primaryLight};
	}

	h2 {
		color: ${({ theme }) => theme.primaryNormal};
	}

	a {
		color: ${({ theme }) => theme.accent};
	}

	a:hover {
		text-decoration: underline;
	}
`
