/**
 * ViewButtonsBlock
 */
import React from 'react'
import Cite from './Cite'
import Embed from './Embed'
import Share from '../../utils/Share'
import HeadMountedDevice from './HeadMountedDevice'
import ModalLink from './ModalLink'
import { trackOutboundLink } from '../../utils/TrackingUtils'
import { manifestFileName, urlPrefix } from '../../constants'
import { getMaterialWithTranscript } from '../../helpers/transcriptHelpers'

const ModalButton = ({ openModal, btnClass, icon, title }) => (
	<button
		type="button"
		onClick={openModal}
		href={window.location.href}
		className={btnClass}
	>
		<img
			aria-hidden="true"
			className="h-4 inline mr-2"
			src={icon}
			alt={title}
		/>
		{title}
	</button>
)

const ViewButtonsBlock = ({
	item,
	authorizations,
	handlePlay,
	handlePlayWithTranscription,
	isTheater,
	isPlayed,
}) => {
	// eslint-disable-next-line
	const isExternal = !~item?.['url']?.indexOf(urlPrefix)
	const accessType =
		item.url.indexOf(urlPrefix) > 0 ? 'controlled' : 'other_free_to_read'
	const embedExpand = false
	const embedTranscript = true
	const embedTheater = true

	function getHMDButtons() {
		return item['supplementary-materials'].map((st) => {
			let ButtonsComponent
			if (st['material-type'] === 'HMD') {
				const isAuth = authorizations.institution
				const isMembership = item['access-type'] === 'Membership Video'

				if ((isAuth && isMembership) || !isMembership) {
					ButtonsComponent = <HeadMountedDevice url={st.url} item={item} />
				} else {
					ButtonsComponent = (
						<ModalLink
							button={ModalButton}
							title="Watch in HMD"
							description={authorizations.membershipMessage}
							icon="/hmd.png"
							btnClass="tw-btn tw-btn-light w-full"
						/>
					)
				}
			}

			return (
				ButtonsComponent && (
					<div key={st.url} className="mr-4 w-full sm:w-auto mb-2">
						{ButtonsComponent}
					</div>
				)
			)
		})
	}

	function get2DButtons() {
		const isAuth = authorizations.institution
		const isMembership = item['access-type'] === 'Membership Video'
		const isManifestExist = item.url.includes(manifestFileName)

		function getButton(withTranscript) {
			return ({ openModal, btnClass, title }) => {
				let onClick
				const Icon = (
					<i className={`fas mr-2 fa-${isPlayed ? 'pause' : 'play'}`} />
				)

				if ((isAuth && isMembership) || !isMembership) {
					onClick = !withTranscript ? handlePlay : handlePlayWithTranscription
				} else {
					onClick = openModal
				}

				return (
					<button
						type="button"
						className={`${btnClass} mr-5 block`}
						onClick={onClick}
					>
						{Icon}
						{title}
					</button>
				)
			}
		}

		const PlayButton = (
			<ModalLink
				button={getButton(false)}
				title={isPlayed ? 'Pause' : 'Play'}
				description={authorizations.membershipMessage}
				btnClass="tw-btn tw-btn-light"
			/>
		)

		const PlayWithTranscript = (
			<ModalLink
				button={getButton(true)}
				title={`${isPlayed && isTheater ? 'Without' : 'With'} Transcript`}
				description={authorizations.membershipMessage}
				btnClass="tw-btn tw-btn-light"
			/>
		)

		return (
			<>
				{isManifestExist && PlayButton}
				{PlayWithTranscript}
			</>
		)
	}

	return (
		<div className="flex flex-wrap mb-5">
			{/* External */}
			{isExternal && (
				<a
					className="tw-btn tw-btn-dark sm:mr-2 w-full sm:w-auto mb-2"
					href={item.url}
					target="_project"
					title="Opens in another window"
					rel="noopener noreferrer"
					onClick={() =>
						trackOutboundLink(item.url, 'trackItemRequest', accessType, item)
					}
				>
					<i className="fas fa-external-link-alt mr-2" />
					Visit
				</a>
			)}
			{/* HMD */}
			{item['supplementary-materials'] && getHMDButtons()}
			{/* Video object */}
			{!!getMaterialWithTranscript(item) && (
				<div className="flex">{get2DButtons()}</div>
			)}
			{/* Preview link */}
			{item['supplementary-materials'] &&
				item['supplementary-materials'].map(
					(st) =>
						st['material-type'] === 'Preview' && (
							<div
								key={st['material-type']}
								className="mr-5 flex-grow sm:flex-grow-0"
							>
								{!authorizations.visitor && (
									<a
										className="tw-btn tw-btn-light mr-5 w-full block"
										href={st.url}
										target="_project"
										title="Opens in another window"
										rel="noopener noreferrer"
										onClick={() =>
											trackOutboundLink(
												st.url,
												'trackItemInvestigation',
												'other_free_to_read',
												item
											)
										}
									>
										<i className="fas fa-play mr-2" />
										{st['material-type']}
									</a>
								)}
								{authorizations.visitor && (
									<ModalLink
										button={ModalButton}
										title="Preview"
										description={authorizations.registrationMessage}
										icon="/hmd.png"
										btnClass="tw-btn tw-btn-light mr-5 flex-grow sm:flex-grow-0"
									/>
								)}
							</div>
						)
				)}
			{/* Cite */}
			<div className="flex items-center mr-5">
				<Cite item={item} />
			</div>
			{/* Share */}
			<div className="flex items-center mr-5">
				{item.cid && (
					<Share
						url={window.location.href}
						text={`${item.title} cid:${item.cid}`}
					/>
				)}
				{!item.cid && <Share url={window.location.href} text={item.title} />}
			</div>
			<div className="flex items-center">
				<Embed
					url={`${window.location.href.replace(
						'/view/',
						'/embed/'
					)}?theater=${embedTheater}&expand=${embedExpand}&transcript=${embedTranscript}`}
				/>
			</div>
		</div>
	)
}

export default ViewButtonsBlock
