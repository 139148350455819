/**
 * Collapse block
 */
import React, { useState, useRef, useEffect } from 'react'

export const headerClassName = 'collapse__header'
export const bodyClassName = 'collapse__body'

const Collapse = (props) => {
	const [isCollapsed, setIsCollapsed] = useState(true)
	const headerRef = useRef()
	const [headerAriaLabel, setHeaderAriaLabel] = useState('')

	const header = props.children.find((component) => {
		const { className } = component.props
		return className && className.indexOf(headerClassName) !== -1
	})

	const body = props.children.find((component) => {
		const { className } = component.props
		return className && className.indexOf(bodyClassName) !== -1
	})

	useEffect(() => {
		const buttonText = headerRef.current.innerText
		setHeaderAriaLabel(buttonText)
	}, [])

	if (header && body) {
		return (
			<div className={props.className}>
				<button
					onClick={() => setIsCollapsed(!isCollapsed)}
					type="button"
					ref={headerRef}
					aria-label={`${
						isCollapsed ? 'Expand' : 'Collapse'
					} ${headerAriaLabel}`}
					className={`pt-2 w-full flex items-center justify-between ${
						isCollapsed ? 'border-b' : ''
					} sm:border-0`}
				>
					{header}
					<i
						className={`sm:hidden text-black fas fa-chevron-${
							isCollapsed ? 'down' : 'up'
						}`}
					/>
				</button>
				<div className={`${isCollapsed ? 'hidden' : 'block'} sm:block`}>
					{body}
				</div>
			</div>
		)
	}
	return (
		<div className="text-red-800 border border-red-800">
			Collapse Block Error: Header or Body not found
		</div>
	)
}

export default Collapse
