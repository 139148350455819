import jwt from 'jsonwebtoken'
import debounce from 'lodash/debounce'
import { llPid } from '../constants'

export const decodeToken = (token) => {
	const jtoken = jwt.decode(token)
	return jtoken
}

export const trackSearch = debounce(
	() => {
		const jwToken = localStorage.getItem('mico-auth-token')

		if (jwToken) {
			if (!window._ll) {
				// eslint-disable-next-line
				console.log("Can't find LibLynx tracking queue")
				return
			}
			const { acn, aud, sub, jti } = decodeToken(jwToken)

			window._ll.push([
				'trackSearch',
				{
					pid: llPid,
					aid: aud,
					aname: acn,
					sid: jti,
					dbs: [{ dbid: sub, dbname: 'Mindscape Commons' }],
				},
			])
		}
	},
	1000,
	{ leading: true, trailing: false }
)

export const trackItemInvestigation = debounce(
	// eslint-disable-next-line
	function (at, item) {
		const jwToken = localStorage.getItem('mico-auth-token')

		if (jwToken) {
			if (!window._ll) {
				// eslint-disable-next-line
				console.log("Can't find LibLynx tracking queue")
				return
			}

			// Year of publications that are 1999 are actually unknown, report as such.
			const yop =
				'release-date' in item && item['release-date'] === '1999'
					? '1'
					: `${item['release-date']}`
			const pub = 'publisher' in item ? item.publisher[0].name : ''
			const pubid =
				'publisher' in item && 'slug' in item.publisher[0]
					? item.publisher[0].slug
					: ''
			// If the scenery type is not specified or is None, its a resource article, otherwise its a multimedia experience
			const dtype =
				!('scenery-type' in item) || item['scenery-type'][0] === 'None'
					? 'article'
					: 'multimedia'

			const { acn, aud, sub, jti } = decodeToken(jwToken)
			const payload = {
				pid: llPid,
				aid: aud,
				aname: acn,
				sid: jti,
				dbid: sub,
				dbname: 'Mindscape Commons',
				at,
				am: 'regular',
				pub,
				pubid,
				im: {
					title: item.title,
					dtype,
					id: item.slug,
					yop,
				},
			}
			window._ll.push(['trackItemInvestigation', payload])
		}
	},
	1000,
	{ leading: true, trailing: false }
)

export const trackItemRequest = debounce(
	// eslint-disable-next-line
	function (at, item) {
		const jwToken = localStorage.getItem('mico-auth-token')

		if (jwToken) {
			if (!window._ll) {
				// eslint-disable-next-line
				console.log("Can't find LibLynx tracking queue")
				return
			}

			// Year of publications that are 1999 are actually unknown, report as such.
			const yop =
				'release-date' in item && item['release-date'] === '1999'
					? '1'
					: `${item['release-date']}`
			const pub = 'publisher' in item ? item.publisher[0].name : ''
			const pubid =
				'publisher' in item && 'slug' in item.publisher[0]
					? item.publisher[0].slug
					: ''
			// If the scenery type is not specified or is None, its a resource article, otherwise its a multimedia experience
			const dtype =
				!('scenery-type' in item) || item['scenery-type'][0] === 'None'
					? 'article'
					: 'multimedia'

			const { acn, aud, sub, jti } = decodeToken(jwToken)
			const payload = {
				pid: llPid,
				aid: aud,
				aname: acn,
				sid: jti,
				dbid: sub,
				dbname: 'Mindscape Commons',
				at,
				am: 'regular',
				pub,
				pubid,
				im: {
					title: item.title,
					dtype,
					id: item.slug,
					yop,
				},
			}
			window._ll.push(['trackItemRequest', payload])
		}
	},
	1000,
	{ leading: true, trailing: false }
)

// eslint-disable-next-line
export const trackOutboundLink = function (url, eventType, accessType, item) {
	if (eventType === 'trackItemRequest') {
		trackItemRequest(accessType, item)
	} else if (eventType === 'trackItemInvestigation') {
		trackItemInvestigation(accessType, item)
	}
	window.open(url, '_project')
	return false
}
