// View.styled.js
import styled from 'styled-components'

export const StyledView = styled.section`
	.vr-embed,
	.vimeo-embed,
	.yt-embed {
		height: 100%;
		max-height: 100%;
	}

	.vr-iframe {
		border: 0;
		height: 100%;
		width: 100%;
		max-height: 100%;
	}

	.vimeo-iframe,
	.yt-iframe {
		border: 0;
		height: 100%;
		width: 100%;
	}

	#experience-image img {
		object-fit: contain;
		width: 100%;
		max-width: 80vw;
		max-height: 500px;
	}

	.citation {
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.rel-date {
		color: ${({ theme }) => theme.primaryNormal};
	}

	.summary {
		color: ${({ theme }) => theme.primaryNormal};
	}

	.summary div {
		text-align: left;
		vertical-align: top;
		color: ${({ theme }) => theme.primaryLight};
	}

	.description *,
	p,
	em,
	b {
		font-size: medium;
	}

	.cid {
		color: ${({ theme }) => theme.primaryLight};
		margin-bottom: 10px;
	}

	.cid img {
		width: 16px;
		vertical-align: middle;
		margin-right: 3px;
	}

	.authors {
		margin-top: 30px;
	}

	.related {
		width: fit-content;
	}

	.related-list {
		margin: auto;
	}

	.related .column {
		margin: 8px;
		margin-top: 20px;
		margin-bottom: 20px;
		border-radius: 5px;
		max-width: 20vw;
	}

	.related-link {
		background: ${({ theme }) => theme.primaryBackground};
		border-radius: 6px;
	}

	.related-title {
		color: ${({ theme }) => theme.primaryNormal};
		background: ${({ theme }) => theme.primaryBackground};
		font-weight: normal;
	}

	.related-image {
		object-fit: cover;
		border-radius: 3px 3px 0px 0px;
	}

	.related-projects {
		color: ${({ theme }) => theme.secondaryLight};
		background-color: ${({ theme }) => theme.accent};
	}

	.related-projects-title {
		color: ${({ theme }) => theme.captionBold};
		font-weight: bold;
	}

	.label {
		font-weight: 500;
		font-size: larger;
		margin-top: 10px;
		margin-bottom: 5px;
	}

	.meta {
		color: ${({ theme }) => theme.secondaryNormal};
		background-color: ${({ theme }) => theme.secondaryBackground};
		font-size: small;
		width: 100%;
	}

	.meta-cell {
	}

	.links_out {
		margin-bottom: 40px;
	}

	.share_tool a {
		font-size: x-small;
		background-color: #eee;
		padding: 5px;
		padding-left: 12px;
		padding-right: 12px;
		border-radius: 5px;
		margin-right: 5px;
		white-space: no-wrap;
	}

	.share_open {
		color: #212529;
		background-color: #f8f9fa;
		border-color: #f8f9fa;
		margin-right: 8px;
	}

	.share_open:hover,
	.share_open:active,
	.share_open:focus {
		background-color: #e2e6ea;
		border-color: #dae0e5;
		outline: none;
	}

	.resource_link a {
		color: ${({ theme }) => theme.primaryHighlight};
	}

	.resource_icon {
		width: 65px;
		vertical-align: middle;
		margin-right: 15px;
		margin-bottom: 10px;
		cursor: pointer;
	}

	.hmd_link,
	.hmd_open {
		white-space: nowrap;
	}

	.collection-type {
		color: rgb(255, 255, 255);
		background-color: rgb(123, 32, 49);
	}
`
