/**
 * Mindscape Commons Facets for main search and browse.
 */
import React from 'react'
import { useDispatch } from 'react-redux'
import debounce from 'lodash/debounce'
import { SingleDropdownList, MultiList } from '@appbaseio/reactivesearch'

import { renderAccessItem } from './renderAccessItem'
import { addRoute } from '../../reducers/history/actions'
import Collapse, { bodyClassName, headerClassName } from '../Collapse'
import { StyledFacets } from './Facets.styled'
import customMultiList from '../customMultiList'

const CustomMultiList = customMultiList(MultiList)

// This is used as a default query to limit results to experiences
const experiencesOnly = () => ({ query: { match: { rectype: 'experience' } } })

const Facets = () => {
	const dispatch = useDispatch()

	/**
	 * We need debounce to prevent saving the same routes twice or more
	 * while clearing filters
	 */
	const multiListOnChangeHandler = debounce(() => {
		dispatch(
			addRoute({
				pathname: window.location.pathname,
				search: window.location.search,
				title: document.title,
			})
		)
	}, 500)

	return (
		<StyledFacets>
			<h2 className="sr-only">Filters</h2>

			<Collapse>
				<h3 className={`mt-4 filter-heading ${headerClassName}`}>
					Content Type
				</h3>
				<CustomMultiList
					componentId="access"
					dataField="access-type.keyword"
					defaultQuery={experiencesOnly}
					filterLabel="Access"
					className={`access-filter ${bodyClassName}`}
					size={4}
					sortBy="count"
					queryFormat="or"
					selectAllLabel="All Content Types"
					showCheckbox
					showCount
					onValueChange={multiListOnChangeHandler}
					showSearch={false}
					renderItem={renderAccessItem}
					react={{
						and: [
							'q',
							'results',
							'publisher',
							'date',
							'scene',
							'subject',
							'device',
							'immersion',
							'interactivity',
							'demographics',
						],
					}}
					showFilter
					URLParams
					innerClass={{
						checkbox: 'list-checkbox',
						label: 'list-item',
						input: 'list-input',
					}}
				/>
			</Collapse>

			<Collapse>
				<h3 className={`filter-heading ${headerClassName}`}>Subject</h3>
				<CustomMultiList
					componentId="subject"
					dataField="subjects.subject.keyword"
					defaultQuery={experiencesOnly}
					filterLabel="Subject"
					className={`subject-filter ${bodyClassName}`}
					size={100}
					sortBy="count"
					queryFormat="or"
					selectAllLabel="All Subjects"
					showCheckbox
					showCount
					showSearch
					onValueChange={multiListOnChangeHandler}
					placeholder="Search for a Subject"
					react={{
						and: [
							'q',
							'results',
							'publisher',
							'access',
							'date',
							'scene',
							'device',
							'immersion',
							'interactivity',
							'demographics',
						],
					}}
					showFilter
					URLParams
					innerClass={{
						checkbox: 'list-checkbox',
						label: 'list-item',
						input: 'list-input',
					}}
				/>
			</Collapse>

			<Collapse>
				<h3 className={`mt-4 filter-heading ${headerClassName}`}>
					Demographics
				</h3>
				<CustomMultiList
					componentId="demographics"
					dataField="demographics.keyword"
					defaultQuery={experiencesOnly}
					filterLabel="Demographics"
					className={`demo-filter ${bodyClassName}`}
					size={50}
					sortBy="asc"
					queryFormat="or"
					selectAllLabel="All Demographics"
					showCheckbox
					onValueChange={multiListOnChangeHandler}
					showCount
					react={{
						and: [
							'q',
							'results',
							'subject',
							'publisher',
							'access',
							'date',
							'scene',
							'device',
							'immersion',
							'interactivity',
						],
					}}
					showFilter
					URLParams
					innerClass={{
						checkbox: 'list-checkbox',
						label: 'list-item',
						input: 'list-input',
					}}
				/>
			</Collapse>

			<Collapse>
				<h3 className={`mt-4 filter-heading ${headerClassName}`}>Publisher</h3>
				<CustomMultiList
					componentId="publisher"
					dataField="publisher.name.keyword"
					defaultQuery={experiencesOnly}
					filterLabel="Publisher"
					showSearch
					className={`pub-filter ${bodyClassName}`}
					size={100}
					sortBy="count"
					queryFormat="or"
					selectAllLabel="All Publishers"
					showCheckbox
					showCount
					onValueChange={multiListOnChangeHandler}
					placeholder="Search for a Publisher"
					react={{
						and: [
							'q',
							'results',
							'subject',
							'access',
							'date',
							'scene',
							'device',
							'immersion',
							'interactivity',
							'demographics',
						],
					}}
					showFilter
					URLParams
					innerClass={{
						checkbox: 'list-checkbox',
						label: 'list-item',
						input: 'list-input',
					}}
				/>
			</Collapse>

			<Collapse>
				<h3 className={`mt-4 filter-heading ${headerClassName}`}>
					Release Year
				</h3>
				<div className={`${bodyClassName} flex release-date`}>
					<div className="w-1/2 sm:w-32">
						<SingleDropdownList
							componentId="release-year-from"
							dataField="release-date"
							size={100}
							defaultValue="1997"
							sortBy="asc"
							customQuery={(value) => ({
								query: {
									bool: {
										must: [
											{
												range: {
													'release-date': {
														gte: value || 1997,
														lte: new Date().getFullYear(),
														boost: 2.0,
													},
												},
											},
											{
												match: {
													rectype: 'experience',
												},
											},
										],
									},
								},
							})}
							showCount={false}
							placeholder="Year From"
							showFilter
							filterLabel="Release Year From"
							react={{
								and: [
									'q',
									'results',
									'subject',
									'publisher',
									'access',
									'release-year-to',
									'scene',
									'device',
									'immersion',
									'interactivity',
									'demographics',
								],
							}}
							URLParams
							loader="Loading ..."
						/>
					</div>
					<div className="w-1/2 sm:w-32">
						<SingleDropdownList
							componentId="release-year-to"
							dataField="release-date"
							size={100}
							defaultValue={new Date().getFullYear().toString()}
							className={`date-filter ${bodyClassName}`}
							sortBy="asc"
							customQuery={(value) => ({
								query: {
									bool: {
										must: [
											{
												range: {
													'release-date': {
														gte: 1997,
														lte: value || new Date().getFullYear(),
														boost: 2.0,
													},
												},
											},
											{
												match: {
													rectype: 'experience',
												},
											},
										],
									},
								},
							})}
							showCount={false}
							placeholder="Year To"
							showFilter
							filterLabel="Release Year To"
							react={{
								and: [
									'q',
									'results',
									'subject',
									'publisher',
									'release-year-from',
									'access',
									'scene',
									'device',
									'immersion',
									'interactivity',
									'demographics',
								],
							}}
							URLParams
							loader="Loading ..."
						/>
					</div>
				</div>
			</Collapse>

			<Collapse>
				<h3 className={`mt-4 filter-heading ${headerClassName}`}>
					Scenery Type
				</h3>
				<CustomMultiList
					componentId="scene"
					dataField="scenery-type.keyword"
					defaultQuery={experiencesOnly}
					filterLabel="Scenery"
					showSearch={false}
					className={`scene-filter ${bodyClassName}`}
					size={10}
					sortBy="asc"
					queryFormat="or"
					selectAllLabel="All Scenery Types"
					showCheckbox
					showCount
					onValueChange={multiListOnChangeHandler}
					react={{
						and: [
							'q',
							'results',
							'subject',
							'publisher',
							'access',
							'date',
							'device',
							'immersion',
							'interactivity',
							'demographics',
						],
					}}
					showFilter
					URLParams
					innerClass={{
						checkbox: 'list-checkbox',
						label: 'list-item',
						input: 'list-input',
					}}
				/>
			</Collapse>

			<Collapse>
				<h3 className={`mt-4 filter-heading ${headerClassName}`}>
					Target Platforms
				</h3>
				<CustomMultiList
					componentId="device"
					dataField="target-platform.keyword"
					defaultQuery={experiencesOnly}
					filterLabel="Platform"
					showSearch={false}
					className={`device-filter ${bodyClassName}`}
					size={10}
					sortBy="asc"
					queryFormat="or"
					selectAllLabel="All Platforms"
					showCheckbox
					showCount
					onValueChange={multiListOnChangeHandler}
					react={{
						and: [
							'q',
							'results',
							'subject',
							'publisher',
							'access',
							'date',
							'scene',
							'immersion',
							'interactivity',
							'demographics',
						],
					}}
					showFilter
					URLParams
					innerClass={{
						checkbox: 'list-checkbox',
						label: 'list-item',
						input: 'list-input',
					}}
				/>
			</Collapse>

			<Collapse>
				<h3 className={`mt-4 filter-heading ${headerClassName}`}>
					Immersion Factor
				</h3>
				<CustomMultiList
					componentId="immersion"
					dataField="immersion-factor.keyword"
					defaultQuery={experiencesOnly}
					filterLabel="Immersion"
					showSearch={false}
					className={`device-filter ${bodyClassName}`}
					size={10}
					sortBy="asc"
					queryFormat="or"
					selectAllLabel="All"
					showCheckbox
					showCount
					onValueChange={multiListOnChangeHandler}
					react={{
						and: [
							'q',
							'results',
							'subject',
							'publisher',
							'access',
							'date',
							'scene',
							'device',
							'interactivity',
							'demographics',
						],
					}}
					showFilter
					URLParams
					innerClass={{
						checkbox: 'list-checkbox',
						label: 'list-item',
						input: 'list-input',
					}}
				/>
			</Collapse>

			<Collapse>
				<h3 className={`mt-4 filter-heading ${headerClassName}`}>
					Interactivity Factor
				</h3>
				<CustomMultiList
					componentId="interactivity"
					dataField="interactivity-factor.keyword"
					defaultQuery={experiencesOnly}
					filterLabel="Interactivity"
					showSearch={false}
					className={`interactivity-filter ${bodyClassName}`}
					size={10}
					sortBy="asc"
					queryFormat="or"
					selectAllLabel="All"
					showCheckbox
					onValueChange={multiListOnChangeHandler}
					showCount
					react={{
						and: [
							'q',
							'results',
							'subject',
							'publisher',
							'access',
							'date',
							'scene',
							'device',
							'immersion',
							'demographics',
						],
					}}
					showFilter
					URLParams
					innerClass={{
						checkbox: 'list-checkbox',
						label: 'list-item',
						input: 'list-input',
					}}
				/>
			</Collapse>
		</StyledFacets>
	)
}

export default Facets
