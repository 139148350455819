/**
 * Modal to display an link in a popup.
 *
 * Usage:
 *
 * import ModalLink from './ModalLink';
 * <ModalLink type={button or link} title={title} icon={icon} btnClass={button class} description={description]}/>
 *
 */
import React from 'react'
import Modal from 'react-modal'
import ModalPopup from './ModalPopup'

// Bind modal to the appElement.  This allows the model to disable the background.
// See: http://reactcommunity.org/react-modal/accessibility/
Modal.setAppElement('#root')

function ModalLink(props) {
	const [modalIsOpen, setIsOpen] = React.useState(false)

	function openModal() {
		setIsOpen(true)
	}

	function closeModal() {
		setIsOpen(false)
	}

	return (
		<span className="link_modal">
			{props.button({
				...props,
				openModal,
			})}
			<ModalPopup
				isOpen={modalIsOpen}
				closeModal={closeModal}
				title={props.title}
				description={props.description}
			/>
		</span>
	)
}

export default ModalLink
