/**
 * ViewDuration
 */
import React from 'react'

const ViewDuration = ({ className, duration }) => {
	const minutes = Math.floor(duration / 60)
	const seconds = Math.ceil(duration - minutes * 60)
	return (
		<span className={className}>
			<span>{minutes}m</span>
			{seconds > 0 && <span> {seconds}s</span>}
		</span>
	)
}

export default ViewDuration
