// View.styled.js
import styled from 'styled-components'

const StyledViewLayout = styled.section`
	.view-container {
		width: ${({ isTheater }) => (isTheater ? '50%' : '')};
		flex-direction: column;
		justify-content: space-around;
		display: flex;
	}

	.info-container {
		width: ${({ isTheater }) => (isTheater ? '50%' : '')};
	}
`
export default StyledViewLayout
