/**
 * Modal to display an link in a popup.
 *
 * Usage:
 *
 * import ModalLink from './ModalLink';
 * <ModalLink closeModal={closeModal} isOpen={isOpen} title={title} description={description]}/>
 *
 */
import React, { useRef } from 'react'
import Modal from 'react-modal'
import { StyledModal } from './Modal.styled'

const customStyles = {
	overlay: {
		backgroundColor: 'rgba(8, 8, 8, 0.50)',
	},
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		padding: '0',
		transform: 'translate(-50%, -50%)',
		width: '600px',
	},
}

function ModalPopup(props) {
	const subtitle = useRef()

	function afterOpenModal() {
		// references are now sync'd and can be accessed.
		subtitle.current.style.color = '#111111'
	}

	return (
		<Modal
			isOpen={props.isOpen}
			onAfterOpen={afterOpenModal}
			onRequestClose={props.closeModal}
			style={customStyles}
			contentLabel={props.title}
		>
			<StyledModal>
				<div className="modal-header">
					<h2 ref={subtitle} className="modal-title">
						{props.title}
					</h2>
					<button
						type="button"
						aria-label="Close Modal"
						onClick={props.closeModal}
						className="close"
					>
						<span aria-hidden="true">x</span>
					</button>
				</div>
				<div className="modal-body">
					{/* eslint-disable-next-line */}
					<div dangerouslySetInnerHTML={{ __html: props.description }} />
				</div>
			</StyledModal>
		</Modal>
	)
}

export default ModalPopup
