import React from 'react'
import { Helmet } from 'react-helmet'
import { useAuth0 } from '../../react-auth0-spa'
import { StyledProfile } from './Profile.styled'

const Profile = () => {
	const { loading, user } = useAuth0()

	if (loading || !user) {
		return <div>Loading...</div>
	}

	return (
		<StyledProfile>
			<Helmet>
				<title>Profile - Mindscape Commons</title>
			</Helmet>
			<img src={user.picture} alt="Profile" />

			{'https://coherentdigital.net/user_metadata' in user && (
				<h2>
					{'first_name' in
						user['https://coherentdigital.net/user_metadata'] && (
						<span>
							{user['https://coherentdigital.net/user_metadata'].first_name}{' '}
						</span>
					)}
					{'last_name' in user['https://coherentdigital.net/user_metadata'] && (
						<span>
							{user['https://coherentdigital.net/user_metadata'].last_name}{' '}
						</span>
					)}
				</h2>
			)}
			{!('https://coherentdigital.net/user_metadata' in user) && (
				<h2>{user.nickname}</h2>
			)}
			<p>
				<strong>eMail:</strong> {user.email}{' '}
				{user.email_verified === true && <i className="verified">(Verified)</i>}
				{user.email_verified === false && (
					<i className="verified">(Unverified)</i>
				)}
			</p>
			{/* if the user has an org id but has not verified, show a message */}
			{user.email_verified === false &&
				'https://coherentdigital.net/user_metadata' in user &&
				'organization_id' in
					user['https://coherentdigital.net/user_metadata'] && (
					<p>
						<strong>Note:</strong> To access <i>Mindscape Commons</i> content
						via remote login using this profile, please verify your email
						address. A verification email was sent to the above address after
						signup. Click on the link inside this email to verify your email
						address. Please check your spam folder if you did not receive it. Or
						contact{' '}
						<a href="mailto:support@coherentdigital.net">
							support@coherentdigital.net
						</a>{' '}
						to receive another verification email.
					</p>
				)}
			{/* if the user does not have an org id but has not verified, show a different message */}
			{user.email_verified === false &&
				!(
					'https://coherentdigital.net/user_metadata' in user &&
					'organization_id' in user['https://coherentdigital.net/user_metadata']
				) && (
					<p>
						<strong>Note:</strong> To access <i>Mindscape Commons</i> user
						profile features, please verify your email address. A verification
						email was sent to the above address after signup. Click on the link
						inside this email to verify your email address. Please check your
						spam folder if you did not receive it. Or contact{' '}
						<a href="mailto:support@coherentdigital.net">
							support@coherentdigital.net
						</a>{' '}
						to receive another verification email.
					</p>
				)}
			{'https://coherentdigital.net/user_metadata' in user && (
				<div>
					{'institution' in
						user['https://coherentdigital.net/user_metadata'] && (
						<p>
							<strong>Institution:</strong>{' '}
							{user['https://coherentdigital.net/user_metadata'].institution}
						</p>
					)}
					{'role' in user['https://coherentdigital.net/user_metadata'] && (
						<p>
							<strong>Role:</strong>{' '}
							{user['https://coherentdigital.net/user_metadata'].role
								.charAt(0)
								.toUpperCase() +
								user['https://coherentdigital.net/user_metadata'].role.slice(1)}
						</p>
					)}
				</div>
			)}
			<p>
				<strong>Updated:</strong> {user.updated_at}
			</p>
		</StyledProfile>
	)
}

export default Profile
