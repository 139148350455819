import React, { useEffect } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { addRoute, updateLastTitle } from './reducers/history/actions'

import { changeTitle } from './reducers/title/actions'

import {
	About,
	Beta,
	Collection,
	Collections,
	Community,
	Contact,
	Dev,
	Device,
	Embed,
	ExampleEntry,
	Exclusive,
	Footer,
	Glossary,
	Guidelines,
	Help,
	Home,
	InfoGraphic,
	Immersion,
	Login,
	Membership,
	New,
	Profile,
	Publisher,
	Search,
	Shibboleth,
	Subjects,
	Submit,
	View,
	VRApp,
	NavBar,
} from './components'

import IndividualRoute from './components/IndividualRoute'

const Routes = () => {
	const dispatch = useDispatch()

	function usePageViews() {
		const location = useLocation()

		useEffect(() => {
			location.title = document.title
			dispatch(addRoute(location))
		}, [location])
	}

	// document title is changed  subscribe title changes
	useEffect(() => {
		const observer = new MutationObserver((mutations) => {
			const title = mutations?.[0]?.target?.text
			if (title) {
				dispatch(changeTitle(title))
				dispatch(updateLastTitle(title))
			}
		})
		observer.observe(document.querySelector('title'), {
			childList: true,
			subtree: true,
			characterData: true,
		})
		return () => {
			// remove title listener
			observer.disconnect()
		}
	})

	usePageViews()
	return (
		<section>
			<NavBar />
			<main tabIndex="-1">
				<Switch>
					{/* These paths are open, although they may need to inspect authorizations */}
					<Route path="/" exact component={Home} />
					<Route path="/about" exact component={About} />
					<Route path="/about/community" exact component={Community} />
					<Route path="/about/contact" exact component={Contact} />
					<Route path="/about/infographic" exact component={InfoGraphic} />
					<Route path="/about/membership" exact component={Membership} />
					<Route path="/about/guidelines" exact component={Guidelines} />
					<Route path="/help" exact component={Help} />
					<Route path="/help/beta" exact component={Beta} />
					<Route path="/help/example" exact component={ExampleEntry} />
					<Route path="/help/vrapp" exact component={VRApp} />
					<Route path="/help/vrapp/:section" exact component={VRApp} />
					<Route path="/help/glossary" exact component={Glossary} />
					<Route
						render={(props) => <Collections rectype="channel" {...props} />}
						path="/browse/collections"
					/>
					<Route
						render={(props) => (
							<Collections rectype="resourcechannel" {...props} />
						)}
						path="/browse/resources"
					/>
					<Route path="/browse/device" component={Device} />
					<Route path="/browse/exclusive" component={Exclusive} />
					<Route path="/browse/publisher" component={Publisher} />
					<Route path="/browse/subjects" component={Subjects} />
					<Route path="/browse/immersion" component={Immersion} />
					<Route path="/browse/new" component={New} />
					<Route path="/collection/:slug" exact component={Collection} />
					<Route path="/resource/:slug" exact component={Collection} />
					<Route path="/search" component={Search} />
					<Route path="/view/:id" component={View} />
					<Route path="/embed/:id" component={Embed} />
					<Route path="/start-session" exact component={Shibboleth} />
					<Route path="/login" exact component={Login} />
					{/* These paths require individual registration and login */}
					<IndividualRoute path="/dev" exact component={Dev} />
					<IndividualRoute path="/profile" component={Profile} />
					<IndividualRoute path="/submit" component={Submit} />
				</Switch>
			</main>
			<Footer />
		</section>
	)
}

export default Routes
