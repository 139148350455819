/**
 * ViewInfoBlock
 */
import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import ModalLink from './ModalLink'
import ViewMenu from './ViewMenu'
import ViewTranscript from './ViewTranscript'
import { trackOutboundLink } from '../../utils/TrackingUtils'

const ViewInfoBlock = ({ collections = [], item, authorizations }) => {
	const isTheater = useSelector((state) => state.viewPage.isTheater)

	const unneededTypes = ['Preview', 'On Screen Transcript', 'HMD']
	const supplementaryMaterials =
		item?.['supplementary-materials']?.filter(
			(sm) => !unneededTypes.includes(sm['material-type'])
		) ?? []

	// sort: teaching materials go first
	supplementaryMaterials.sort((a, b) => {
		const teach = 'teach'
		const isATeach = a['material-type']?.toLowerCase()?.includes(teach)
		const isBTeach = b['material-type']?.toLowerCase()?.includes(teach)
		if (isATeach) return -1
		if (isBTeach) return 1
		return 0
	})

	function isPdf(string) {
		return string.slice(-4).toLowerCase() === '.pdf'
	}

	function getCollectionImage(collection) {
		const large = collection?.image[0].thumbnails.large.url
		const small = collection?.image[0].thumbnails.small.url
		const full = collection?.image[0].thumbnails.full.url
		return large || full || small
	}

	return (
		<div className={`flex flex-col ${!isTheater ? 'md:flex-row mt-10' : ''}`}>
			{/* Summary column */}
			<div className={!isTheater ? 'md:pr-5 ' : ''}>
				<section>
					<h2 className={`text-2xl my-5 ${isTheater ? 'sr-only' : ''}`}>
						Summary
					</h2>
					{!isTheater && (
						<section>
							{item.purpose && (
								<ReactMarkdown
									source={item.purpose}
									renderers={{ paragraph: (props) => <div {...props} /> }}
								/>
							)}
							{item.description && (
								<ReactMarkdown
									source={item.description}
									renderers={{ paragraph: (props) => <div {...props} /> }}
								/>
							)}
							<ViewMenu item={item} />
						</section>
					)}
				</section>
				<ViewTranscript item={item} />
			</div>

			{/* Download column */}
			{!isTheater && (
				<div className="w-full md:w-1/4 flex-shrink-0">
					{supplementaryMaterials && supplementaryMaterials.length > 0 && (
						<h2 className="my-5 text-2xl">Supplemental Materials</h2>
					)}
					{supplementaryMaterials && (
						<div>
							{!authorizations.visitor &&
								supplementaryMaterials.map((sm) => (
									<a
										key={sm['material-type']}
										className="flex mb-5"
										href={sm.url}
										target="_project"
										title={`Opens ${sm['material-type']} in another window`}
										rel="noopener noreferrer"
										onClick={() =>
											trackOutboundLink(
												sm.url,
												'trackItemInvestigation',
												'other_free_to_read',
												item
											)
										}
									>
										<div className="mr-5">
											<img
												className="w-10"
												alt=""
												src={isPdf(sm.url) ? '/pdf.png' : '/web.png'}
											/>
										</div>
										<div>
											<div>{sm['material-type']}</div>
										</div>
									</a>
								))}
							{authorizations.visitor &&
								supplementaryMaterials.map((sm) => (
									<div key={sm['material-type']}>
										<ModalLink
											button={({ openModal, btnClass, icon, title }) => (
												<a href="#!" className={btnClass} onClick={openModal}>
													<div className="mr-5">
														<img
															className="w-10"
															alt={title}
															aria-hidden="true"
															src={icon}
														/>
													</div>
													<div>
														<div>{title}</div>
													</div>
												</a>
											)}
											title={sm['material-type']}
											description={authorizations.registrationMessage}
											icon={isPdf(sm.url) ? '/pdf.png' : '/web.png'}
											btnClass="flex mb-5"
										/>
									</div>
								))}
						</div>
					)}
					{collections && collections.length > 0 && (
						<h2 className="my-5">Part of</h2>
					)}
					{collections.map((collection) => (
						<div
							className="rounded-md border border-gray-400"
							key={`collection-${collection.slug}`}
						>
							<Link to={`/collection/${collection.slug}`}>
								<div>
									{getCollectionImage(collection) && (
										<img
											className="w-full"
											src={getCollectionImage(collection)}
											alt=""
										/>
									)}
									{collection['collection-type'] && (
										<h3 className="text-center p-1 collection-type">
											{collection['collection-type']}
										</h3>
									)}
								</div>
								{/* Collection info block */}
								<div className="p-5">
									<h3 className="my-5">{collection.title}</h3>
									<div>
										<ReactMarkdown
											source={collection.purpose}
											renderers={{ paragraph: (props) => <div {...props} /> }}
										/>
									</div>
									<div className="mt-5">{collection.projects.length} items</div>
								</div>
							</Link>
						</div>
					))}
				</div>
			)}
		</div>
	)
}

export default ViewInfoBlock
