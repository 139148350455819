import React from 'react'

export const renderAccessItem = (label, count) => (
	<span>
		<span>
			{label}
			{label === 'Membership Video' && (
				<img
					title="Exclusive / Membership"
					src="/favicon-16x16.png"
					alt="Exclusive / Membership"
					className="align-middle ml-1 inline w-3"
				/>
			)}
			{label === 'Open Access Video' && (
				<i
					title="Open Access"
					className="fas fa-lock-open text-green-600 ml-1"
				/>
			)}
			{label === 'Commercial Resources' && (
				<i
					title="Commercial Resource"
					className="fas fa-external-link-alt text-red-500 ml-1"
				/>
			)}
			{label === 'Research Project' && (
				<i
					title="Research Project"
					className="fas fa-external-link-alt text-indigo-500 ml-1"
				/>
			)}
		</span>
		<span>{count}</span>
	</span>
)
