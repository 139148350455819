/**
 * Browse All Collections.
 *
 * This retrieves records with the provided rectype and displays them with a subject facet.
 */
import React from 'react'
import {
	SingleList,
	ReactiveList,
	ResultCard,
	SelectedFilters,
} from '@appbaseio/reactivesearch'
import { Helmet } from 'react-helmet'
import ReactMarkdown from 'react-markdown'
import { StyledCollections } from './Collections.styled'
import { params } from './params'
import withLink from '../withLink'
import { trackSearch } from '../../utils/TrackingUtils'
import customReactiveList from '../customReactiveList'
import customSelectedFilters from '../customSelectedFilters'

const CustomReactiveList = customReactiveList(ReactiveList)
const CustomSelectedFilters = customSelectedFilters(SelectedFilters)
const WithLinkResultCard = withLink(ResultCard)

const Collections = (props) => {
	const { rectype } = props
	const listParams = params[rectype]

	return (
		<StyledCollections>
			<h1 className="sr-only">{listParams.title}</h1>
			<Helmet>
				<title>{listParams.title}</title>
			</Helmet>
			<div className="container flex flex-col sm:flex-row" id="browse">
				<div className="left-side-bar flex flex-wrap flex-col">
					<h3 className="filter-heading">{listParams.subtitle}</h3>
					<SingleList
						componentId="collections-subject"
						dataField="projects.subject.keyword"
						filterLabel="Subject"
						className="browse-list"
						size={200}
						sortBy="asc"
						selectAllLabel="All Subjects"
						showRadio={false}
						showCount
						showSearch
						placeholder={`Search ${listParams.singleEntityLabel} Subjects`}
						showFilter
						URLParams
						innerClass={{
							label: 'list-item',
							input: 'list-input',
							radio: 'list-radio',
						}}
						defaultQuery={() => ({
							query: {
								match: {
									rectype,
								},
							},
						})}
						react={{
							and: ['q', 'results-collections'],
						}}
						renderItem={(label, count, isSelected) => (
							<span>
								<span>{label}</span>
								<span
									style={{
										marginLeft: 5,
										color: isSelected ? 'red' : 'black',
									}}
								>
									{count}
								</span>
							</span>
						)}
						loader="loading..."
					/>
				</div>
				<div className="">
					<CustomSelectedFilters
						showClearAll
						clearAllLabel="Clear filters"
						className="filter-button-row"
						innerClass={{
							button: 'filter-button',
						}}
					/>
					<CustomReactiveList
						componentId="results-collections"
						dataField="title"
						size={40}
						defaultQuery={() => ({
							query: {
								match: {
									rectype,
								},
							},
						})}
						sortOptions={[
							{
								label: 'Sort by Relevance',
								dataField: '_score',
								sortBy: 'desc',
							},
							{
								label: 'Sort by Title',
								dataField: 'title.keyword',
								sortBy: 'asc',
							},
						]}
						defaultSortOption="Sort by Title"
						loader={
							<div className="result-loader">
								Loading {listParams.singleEntityLabel} browse...
							</div>
						}
						noResults="No results were found..."
						renderResultStats={(stats) => {
							trackSearch()
							return (
								<div className="result-count mr-5">
									{stats.numberOfResults} results
								</div>
							)
						}}
						renderError={(error) => (
							<div className="result-error">
								Something went wrong!
								<br />
								Error details
								<br />
								{error}
							</div>
						)}
						react={{
							and: ['q', 'collections-subject'],
						}}
						innerClass={{
							title: 'result-title',
							sortOptions: 'sort-options',
							resultStats: 'result-stats',
							resultsInfo: 'result-list-info',
						}}
					>
						{({ data }) => (
							<>
								<ReactiveList.ResultCardsWrapper
									className="mt-3"
									id="result-list"
								>
									{data.map((item) => (
										<WithLinkResultCard
											key={item.slug}
											aria-label={`Result Card ${item.title}`}
											href={`/${listParams.cardHref}/${item.slug}`}
											target="_self"
											className="result-item"
											item={item}
										>
											{item.image && item.image[0].thumbnails && (
												<ResultCard.Image
													src={item.image[0].thumbnails.large.url}
													className="result-image"
												/>
											)}
											{item['collection-type'] && (
												<h2 className="collection-type">
													{item['collection-type']}
												</h2>
											)}
											<ResultCard.Title className="result-title">
												{item.title}
											</ResultCard.Title>
											<ResultCard.Description>
												<div className="flex column justify-space-between">
													<div className="row">
														<div className="column">
															<div className="detail">
																<ReactMarkdown
																	source={`${item.purpose
																		.split(' ')
																		.slice(0, 35)
																		.join(' ')}...`}
																	renderers={{
																		paragraph: (paragraphProps) => (
																			<div {...paragraphProps} />
																		),
																	}}
																/>
															</div>
														</div>
													</div>
												</div>
											</ResultCard.Description>
										</WithLinkResultCard>
									))}
								</ReactiveList.ResultCardsWrapper>
							</>
						)}
					</CustomReactiveList>
				</div>
			</div>
		</StyledCollections>
	)
}

export default Collections
