import { combineReducers } from 'redux'
import history from './history/reducer'
import viewPage from './viewPage/reducer'
import title from './title/reducer'

export default combineReducers({
	history,
	viewPage,
	title,
})
