// Submit.styled.js
import styled from 'styled-components'

export const StyledSubmit = styled.section`
	padding: 20px;
	background-color: ${({ theme }) => theme.secondaryBackground};
	border-radius: 5px;
	@media (min-width: ${({ theme }) => theme.mobile}) {
		width: 75vw;
	}
	margin: auto;

	p {
		width: 90%;
		margin-top: 20px;
		margin-bottom: 20px;
		margin-left: auto;
		margin-right: auto;
		text-align: left;
		color: ${({ theme }) => theme.secondaryLight};
	}

	h2 {
		color: ${({ theme }) => theme.secondaryNormal};
	}

	button {
		color: ${({ theme }) => theme.tertiaryNormal};
		background-color: ${({ theme }) => theme.tertiaryBackground};
		border-radius: 5px;
		padding: 10px;
		width: 250px;
		height: 50px;
		font-weight: bold;
		font-size: 1em;
	}

	a {
		color: ${({ theme }) => theme.accent};
	}

	a:hover {
		text-decoration: underline;
	}
`
