/**
 * Redirect to the LibLynx Shibboleth WAYFless endpoint for MICO
 */
import React, { useEffect } from 'react'

function Shibboleth() {
	useEffect(() => {
		window.location.href = `https://connect.liblynx.com/wayfless/mico${window.location.search}`
	}, [])

	return (
		<div>
			<h2>Redirecting to your identity provider...</h2>
		</div>
	)
}

export default Shibboleth
