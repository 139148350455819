// Modal.styled.js
import styled from 'styled-components'

export const StyledModal = styled.div`
	.cite-row {
		border-bottom: 1px solid #aaa;
		margin-bottom: 20px;
		padding-bottom: 20px;
	}

	.cite-row:last-child {
		border: 0;
	}

	.right {
		flex: 4;
		text-align: left;
		margin-left: 10px;
	}

	div.citation,
	div.instructions {
		text-align: left;
	}

	#apa-cite .citation .title {
		font-style: italic;
	}

	#mla-cite .citation .title {
		font-style: italic;
	}

	#markup {
		margin: 0px;
		height: 100px;
		width: 500px;
		border: 0px;
	}

	#copy-buffer {
		height: 0px;
		width: 0px;
		color: #fff;
		background-color: #fff;
		border: 0px solid #fff;
	}

	.launch_code {
		overflow: hidden;
		border: 0px;
	}

	.hmd_instructions {
		background-color: #fff;
	}

	.hmd_detail_instructions {
		background-color: #fff;
	}

	h3 {
		text-align: left;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.hmd_instructions a {
		color: ${({ theme }) => theme.accent};
	}

	.hmd_instructions ul {
		text-align: left;
	}

	.hmd a {
		color: ${({ theme }) => theme.accent};
	}

	.hmd_detail_instructions ul {
		text-align: left;
	}

	p {
		text-align: left;
		margin-bottom: 10px;
	}

	.qr_open {
		padding: 2px;
		font-size: small;
	}

	.qr_open:active {
		outline: none;
	}

	.fake-big-window {
		width: 565px;
		height: 329px;
	}

	.loading {
		background: url('/hmd_loader_bg.png');
	}

	.modal-body a {
		color: ${({ theme }) => theme.accent};
		font-weight: bolder;
	}

	.modal-body p {
		text-align: center;
	}
`
