/**
 * Redirect to the Auth0 Login for MICO
 */
import React, { useEffect } from 'react'
import { useAuth0 } from '../../react-auth0-spa'

function Login() {
	const {
		isAuthorizedIndividual,
		loginWithRedirect,
		loading
	} = useAuth0()

	const port =
		window.location.port === '' ||
		window.location.port === '80' ||
		window.location.port === '443'
			? ''
			: `:${window.location.port}`
	const loginDest = `${window.location.protocol}//${window.location.hostname}${port}/`

	useEffect(() => {
		if (!loading) {
		  if (!isAuthorizedIndividual) {
			loginWithRedirect({ returnTo: '/' })
		  } else {
			window.location.href = loginDest 
		  }
		}
	  }, [loading, isAuthorizedIndividual, loginWithRedirect, loginDest])

	return (
		<div>
		</div>
	)
}

export default Login
