// Home.styled.js
import styled from 'styled-components'

export const StyledHome = styled.div`
	.home-container {
		min-height: calc(100vh - 355px);
	}

	.browses {
		background-color: ${({ theme }) => theme.accent};
		padding-top: 20px;
		padding-bottom: 50px;
	}

	.column {
		text-align: left;
		border: 1px solid #817f71;
		border-radius: 5px;
		margin: 8px;
		background-color: ${({ theme }) => theme.secondaryBackground};
		flex-basis: 200px;
	}

	.column a {
		color: ${({ theme }) => theme.primaryBold};
		text-decoration: none;
		transition: color 0.3s linear;
	}

	.column a:hover {
		color: ${({ theme }) => theme.accent};
	}

	.column small {
		color: ${({ theme }) => theme.primaryLight};
		font-size: xx-small;
		margin: 15px;
	}

	.column small:nth-child(1) {
		height: 50px;
	}

	.column img {
		width: 100%;
		object-fit: cover;
	}

	.column a .caption {
		text-align: left;
	}

	.column a .caption:hover {
		color: ${({ theme }) => theme.accent};
	}

	.column a .caption p {
		font-weight: 600;
		font-size: smaller;
		margin: 15px;
	}

	.home-row-title {
		color: ${({ theme }) => theme.primaryBold};
	}

	.browses .home-row-title {
		color: ${({ theme }) => theme.tertiaryBold};
	}

	.home-row-search {
		display: flex;
		width: 100%;
		height: 50vh;
		background-color: ${({ theme }) => theme.accent};
		background: url('./logo-mask-background-neg.svg');
	}

	.search-bar div input {
		border-radius: 25px;
		background-color: ${({ theme }) => theme.secondaryBackground};
	}

	.search-bar div div div {
		top: 15px;
	}

	.featured-experiences:focus,
	.featured-browses:focus {
		display: block;
		width: 100%;
		height: 100%;
		outline: 3px solid #0a3b8a;
	}

	.featured-browses:focus {
		outline: 3px solid #fff;
	}
`
