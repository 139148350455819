/**
 * Info about Joining our Community.
 */
import React from 'react'
import { Helmet } from 'react-helmet'
import { StyledMembership } from './Membership.styled'

const Community = () => {
	return (
		<StyledMembership>
			<Helmet>
				<title>Join our Community - Mindscape Commons</title>
			</Helmet>
			<h2>Join our Community!</h2>
			<p>
				Mindscape Commons is a collaborative membership. We encourage faculty,
				students, librarians, researchers, and practitioners to upload any VR
				projects, articles, teaching materials and related they have completed
				or are currently working on. We hope that collectively we can accelerate
				the development of this increasingly important field by sharing and
				collaborating.
			</p>

			<p>
				For those individuals who have registered to Mindscape Commons (which is
				free), you can submit up to five VR project entries. Before uploading a
				submission, please review our Mindscape Commons{' '}
				<a href="/about/guidelines">editorial guidelines for submissions</a>.
				And please review our{' '}
				<a
					href="https://coherentdigital.net/file-upload-policy"
					target="_blank"
					rel="noopener noreferrer"
				>
					File Upload Policy
				</a>
				.
			</p>

			<p>
				For those who have an institutional/organizational membership to
				Mindscape Commons, you can submit an unlimited number of VR project
				entries, along with up to 20 VR experiences, as well as supplemental
				material. Learn more <a href="/about/membership">here</a> for
				institutional membership.
			</p>
		</StyledMembership>
	)
}

export default Community
