// Membership.styled.js
import styled from 'styled-components'

export const StyledMembership = styled.section`
	padding: 20px;
	background-color: ${({ theme }) => theme.secondaryBackground};
	border-radius: 5px;
	width: 75vw;
	margin: auto;
	text-align: left;

	h2,
	h3 {
		color: ${({ theme }) => theme.secondaryNormal};
		margin-top: 20px;
		margin-bottom: 5px;
	}

	p {
		margin: 20px;
	}

	* {
		color: ${({ theme }) => theme.secondaryNormal};
	}

	a {
		color: ${({ theme }) => theme.accent};
	}

	#product_1 {
		height: 180px;
	}

	#product_2 {
		height: 250px;
	}

	.product_cart {
		border: blue solid 0px;
		margin-left: auto;
		margin-right: auto;
		font-family: arial;
	}

	.product {
		border: #2229af solid 2px;
		max-width: 300px;
		min-width: 300px;
		margin-left: auto;
		margin-right: auto;
		padding-bottom: 12px;
	}

	.product_status {
		font-size: x-large;
		font-weight: 900;
		text-align: center;
		min-height: 30px;
	}

	.product_reg_title {
		font-size: x-large;
		font-weight: 900;
		text-align: center;
		background-color: #2229af;
		color: White;
	}

	.product_reg_subtitle {
		background-color: #f96a3e;
		color: black;
		margin-left: 0px;
		margin-right: 0px;
		text-align: center;
		font-size: large;
		font-weight: bold;
		padding: 3px;
		line-height: 1.2;
	}

	.product_reg_body_text {
		border: red solid 0px;
		padding: 12px;
		font-size: large;
		text-align: center;
		line-height: 1.3;
	}

	.product_reg_button {
		background-color: #2229af;
		position: relative;
		bottom: 0;
		border-radius: 7px;
		max-width: 80%;
		font-size: large;
		font-weight: bold;
		text-align: center;
		color: white;
		margin-left: auto;
		margin-right: auto;
		line-height: 1;
		padding: 7px;
	}

	.product_reg_button:hover {
		background-color: #f96a3e;
		color: black;
	}

	table.product_cart {
		margin-top: 40px;
		width: 93%;
	}

	table tr th {
		background-color: #2229af;
		color: white;
	}

	table th,
	td {
		border: #2229af solid 1px;
		padding: 7px;
		text-align: center;
	}

	.feature-column {
		text-align: left;
		font-weight: 900;
	}

	.freemiumpct {
		max-width: 20px;
		display: inline;
	}

	.caption {
		border: none;
		font-size: smaller;
	}

	.yellow {
		background-color: #f4f4f4;
	}
	.blue {
		background-color: #eeeeee;
	}
	.pink {
		background-color: #dddddd;
	}
	.grey {
		background-color: #cccccc;
	}
	.green {
		background-color: #bbbbbb;
	}
	.blue2 {
		background-color: #aaaaaa;
	}
`
