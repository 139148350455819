// Browse.styled.js
import styled from 'styled-components'

export const StyledBrowse = styled.section`
	margin-top: 75px;

	.list-input {
		border-radius: 25px;
		background-color: ${({ theme }) => theme.primaryBackground};
		height: 25px;
		width: 85%;
		font-size: x-small;
	}

	.list-item {
		font-size: x-small;
		color: ${({ theme }) => theme.primaryLight};
		text-align: left;
		margin: 0;
	}

	.list-item:hover {
		color: ${({ theme }) => theme.primaryBold};
	}

	.list-item span span:nth-child(2) {
		color: ${({ theme }) => theme.primaryNormal};
		margin-right: 8px;
		background-color: #4a483f27;
		display: inline-block;
		height: fit-content;
		border-radius: 3px;
		padding: 2px;
		min-width: 20px;
		text-align: right;
	}

	.filter-heading {
		color: ${({ theme }) => theme.primaryBold};
		font-size: medium;
		font-weight: normal;
		margin-top: 35px;
		margin-bottom: 10px;
		text-align: left;
	}

	.browse-list {
		overflow-y: scroll;
		max-height: 400px;
	}

	.browse-list input {
		width: 80%;
	}

	.browse-list {
		margin-left: 20px;
	}

	.browse-list ul {
		overflow-y: visible;
		max-height: none;
	}

	#browse .column-one {
		flex-basis: 20%;
		background-color: ${({ theme }) => theme.secondaryBackground};
		border-radius: 5px;
		margin-left: 0;
		min-width: 200px;
	}

	.row {
		margin-top: 10px;
	}

	#browse {
		width: 80%;
		margin: auto;
	}

	#browse .column-one div {
		text-align: left;
	}

	#browse .column-two {
		flex-basis: 80%;
	}

	/* styling for Result Container */
	#result-list {
		margin: 0;
	}

	.result-item {
		min-height: 250px;
		height: 250px;
		background-color: ${({ theme }) => theme.secondaryBackground} !important;
		margin-right: 17px !important;
		margin-bottom: 17px !important;
		padding: 0px 0px 25px 0 !important;
	}

	.result-item:focus {
		outline: 2px solid transparent;
	}

	.result-title {
		display: block !important;
		color: ${({ theme }) => theme.primaryNormal} !important;
		padding: 5px;
		white-space: normal;
		text-align: left;
	}

	.result-image {
		-webkit-transition-duration: 1s;
		-o-transition-duration: 1s;
		transition-duration: 1s;
		overflow: hidden;
		background-color: ${({ theme }) => theme.imageBackground};
		background-size: cover;
		width: 260px;
		height: 145px;
	}

	.detail {
		font-size: 11px;
		color: ${({ theme }) => theme.primaryLight};
		text-align: left;
		padding: 5px;
	}

	.sub-title {
		color: ${({ theme }) => theme.primaryNormal};
		font-size: 13px;
		text-align: center;
	}

	.result-container {
		width: 80%;
	}

	.result-container-optional {
		width: 85%;
	}

	.result-stats {
		color: ${({ theme }) => theme.primaryNormal};
		font-size: 14px;
		margin-left: 3px;
		margin-top: 5px;
	}

	.result-count {
		color: ${({ theme }) => theme.primaryNormal};
		font-size: 0.85rem;
	}

	.result-loader {
		color: ${({ theme }) => theme.primaryLight};
	}

	.result-card-header {
		flex-direction: column;
	}

	.result-card-header > div {
		justify-content: center;
		margin-top: 5px;
	}

	h2 {
		color: ${({ theme }) => theme.primaryNormal};
	}

	.sort-options:focus {
		outline: 1px solid;
	}
`
