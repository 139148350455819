// InfoGraphic.styled.js
import styled from 'styled-components'

export const StyledInfoGraphic = styled.section`
	margin: 10px;
	padding: 10px;
	border-radius: 5px;

	#igheader {
		color: #ffffff;
		font-size: 28pt;
		font-family: 'Lucida Sans Unicode', 'Lucida Grande', sans-serif;
	}

	.cell {
		background-color: rgba(255, 255, 255, 0.7);
		color: #4863a0;
		margin: 10px;
		padding: 10px;
		border-radius: 5px;
		box-shadow: 5px 10px 18px #888888;
	}

	.large {
		font-size: 28pt;
		font-wight: bold;
		color: #2b3856;
		font-family: Impact, Charcoal, sans-serif;
	}

	.medium {
		font-size: 20pt;
	}

	.small {
		font-size: 18pt;
	}

	.r1c2 table,
	.r2c1 table {
		margin: auto;
		margin-top: 15px;
	}

	.r1c2 th,
	.r2c1 th {
		text-align: left;
	}

	.r1c2 td,
	.r2c1 td {
		text-align: right;
	}

	.r1c2 {
		background-image: url('./../world_map.png');
		background-size: cover;
		background-color: rgba(240, 255, 240, 0.1);
	}

	.r1c2 span,
	.r1c2 th,
	.r1c2 td,
	.r1c2 div.small span {
		background-color: #ffffff;
	}

	.r1c1c2 {
		background-color: rgba(255, 220, 220, 0.7);
	}

	.r2c1c1 {
		background-color: rgba(255, 255, 220, 0.7);
	}

	.r2c2c1 {
		background-color: rgba(255, 220, 255, 0.7);
	}

	.wordcloud {
		margin-top: 30px;
		margin-bottom: 30px;
		margin-left: 10%;
		margin-right: 10%;
	}

	.subjects {
		background-color: rgba(255, 255, 255, 1);
	}

	p {
		width: 50%;
		margin-top: 20px;
		margin-bottom: 20px;
		margin-left: auto;
		margin-right: auto;
		color: ${({ theme }) => theme.primaryLight};
	}

	th {
		color: ${({ theme }) => theme.primaryNormal};
		font-weight: bold;
	}

	td {
		color: ${({ theme }) => theme.primaryNormal};
	}

	hr {
		width: 80%;
		text-align: center;
		margin: auto;
	}
`
