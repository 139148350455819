import { useEffect, useState, useRef } from 'react'
import axios from 'axios'

const useDataApi = (url) => {
	const [dataState, setDataState] = useState({ data: [], isFetching: false })
	const [endpointUrl] = useState(url)

	// The useRefs allow us to capture the states across renders
	// and thus avoid a dependency on the useStates in the third useEffect.
	// (a empty dependency array otherwise triggers a compiler error.
	// and a non-empty dependency array causes an infinite loop of fetch's.
	// Make sense?  Me neither.
	// See: https://medium.com/@5066aman/effects-shouldnt-lie-about-their-dependencies-44becff15582
	const latestDataState = useRef()
	const latestUrl = useRef()

	useEffect(() => {
		latestDataState.current = dataState
	})

	useEffect(() => {
		latestUrl.current = endpointUrl
	})

	useEffect(() => {
		const fetchDataFromApi = async () => {
			try {
				setDataState({ ...latestDataState.current, isFetching: true })
				const response = await axios.get(latestUrl.current)
				setDataState({
					...latestDataState.current,
					data: response.data,
					isFetching: false,
				})
			} catch (e) {
				// eslint-disable-next-line
				console.log(e)
				setDataState({ ...latestDataState.current, isFetching: false })
			}
		}
		fetchDataFromApi()
	}, [])

	return [dataState]
}

export default useDataApi
