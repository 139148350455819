/**
 * Browse by Target Subject.
 */
import React from 'react'

import Browse from '../Browse'

const Subject = () => {
	const renderItem = (label, count, isSelected) => (
		<span>
			<span>{label.replace(':Mindscape', '').replace(':', ' / ')}</span>
			<span
				style={{
					marginLeft: 5,
					color: isSelected ? 'red' : 'black',
				}}
			>
				{count}
			</span>
		</span>
	)
	return (
		<Browse
			filterHeading="Browse by Subject"
			componentId="selected-subject"
			dataField="subjects.typed-subject.keyword"
			filterLabel="Subject"
			filtersClassName="h-64 sm:h-auto"
			loaderMessage="Loading subjects browse"
			selectAllLabel="All Subjects"
			placeholder="Search for a Subject"
			renderItem={renderItem}
			showSearch
		/>
	)
}

export default Subject
