import { manifestFileName } from '../constants'

export const getMaterialWithTranscript = (item) => {
	let data

	if (item && item['supplementary-materials']) {
		data = item['supplementary-materials'].find(
			(st) => st['material-type'] === 'On Screen Transcript'
		)

		if (!data && item.url.includes(manifestFileName)) {
			data = item['supplementary-materials']?.[0]
		}
	}

	return data
}
