/**
 * This is a High Order Component to add a11y features to MultiList
 */
import React, { useEffect, useRef } from 'react'
import debounce from 'lodash/debounce'

import { revealAriaMessage } from '../../helpers/accessibilityHelpers'

const customMultiList = (MultiList) => {
	return ({ ...props }) => {
		// to search `.sort-options` into root elem instead of entire document
		const rootElem = useRef(null)

		// to update sr-only message with found results
		const foundResultsElem = useRef(null)

		/**
		 * Add navigation by key arrows
		 */
		const keyListener = (e) => {
			const arrowUp = 38
			const arrowDown = 40
			const allInputs = rootElem.current.querySelectorAll('input')
			const currentActiveElement = document.activeElement
			const currentIndex = Array.from(allInputs).findIndex(
				(elem) => elem === currentActiveElement
			)
			const nextIndex =
				currentIndex >= allInputs.length - 1 ? 0 : currentIndex + 1
			const previousIndex =
				currentIndex === 0 ? allInputs.length - 1 : currentIndex - 1

			switch (e.keyCode) {
				case arrowUp: {
					e.preventDefault()
					allInputs[previousIndex].focus()
					break
				}
				case arrowDown: {
					e.preventDefault()
					allInputs[nextIndex].focus()
					break
				}
				default:
			}
		}

		const setAriaLabelsToCheckboxes = () => {
			let ul = null
			const interval = setInterval(() => {
				if (rootElem.current) {
					ul = rootElem.current.querySelector('ul')
					if (ul) {
						clearInterval(interval)
						ul.querySelectorAll('li').forEach((li) => {
							const labelText = li.querySelector('label')?.innerText
							const input = li.querySelector('input')
							input.setAttribute('aria-label', labelText)
						})
					}
				}
			}, 500)
		}

		useEffect(() => {
			// a11y: to make tab loop add key listener
			const container = rootElem.current
			container.addEventListener('keyup', keyListener)

			// remove listener after unmount component
			return () => {
				container.removeEventListener('keyup', keyListener)
			}
		}, [props])

		useEffect(() => {
			let searchInput = null

			// listener callback
			const updateFoundResults = debounce(() => {
				const allInputs = rootElem.current.querySelectorAll('ul input')
				revealAriaMessage(
					foundResultsElem.current,
					`${allInputs.length} results found`
				)
				setAriaLabelsToCheckboxes()
			}, 500)

			// a11y: find search input update sr-only message with found results
			// Add listener only if MultiList has search input
			if (props.showSearch) {
				// setInterval needed to wait MultiList's render
				const interval = setInterval(() => {
					searchInput = rootElem.current.querySelector('input')
					if (searchInput) {
						clearInterval(interval)
						searchInput.addEventListener('keyup', updateFoundResults)
					}
				}, 500)
			}

			setAriaLabelsToCheckboxes()

			return () => {
				if (props.showSearch && searchInput) {
					searchInput.removeEventListener('keyup', updateFoundResults)
				}
			}
			// eslint-disable-next-line
		}, [])

		return (
			<div ref={rootElem}>
				<div
					role="alert"
					aria-live="polite"
					className="sr-only"
					ref={foundResultsElem}
				/>
				<MultiList {...props} />
			</div>
		)
	}
}

export default customMultiList
