/**
 * VideoVR
 */
import React from 'react'
import { trackItemRequest } from '../../../utils/TrackingUtils'

const VideoVR = ({ item, authorizations }) => {
	if (authorizations.institution) {
		const iframewatcher = setInterval(() => {
			const activeE = document.activeElement
			if (activeE && activeE.tagName === 'IFRAME') {
				trackItemRequest('controlled', item)
				clearInterval(iframewatcher)
			}
		}, 1000)

		return (
			<div className="vr-embed h-full">
				<iframe
					src={`${item.url}`}
					className="vr-iframe h-full"
					title="VR embed"
					allowFullScreen
					webkitallowfullscreen="true"
					mozallowfullscreen="true"
					msallowfullscreen="true"
					type="text/html"
				/>
			</div>
		)
	}
	return (
		<div className="h-full" id="experience-image">
			<img
				className="h-full"
				src={item.image[0].thumbnails.large.url}
				alt={item.title}
			/>
		</div>
	)
}

export default VideoVR
