/**
 * Mindscape Commons Main Search Page.
 */
import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
	ReactiveList,
	ResultCard,
	ResultList,
	SelectedFilters,
} from '@appbaseio/reactivesearch'
import { Helmet } from 'react-helmet'
import ReactMarkdown from 'react-markdown'
import Facets from '../Facets'
import { StyledSearch } from './Search.styled'
import withLink from '../withLink'
import customReactiveList from '../customReactiveList'
import customSelectedFilters from '../customSelectedFilters'
import { trackSearch } from '../../utils/TrackingUtils'

const WithLinkResultCard = withLink(ResultCard)
const WithLinkResultList = withLink(ResultList)

const CustomReactiveList = customReactiveList(ReactiveList)
const CustomSelectedFilters = customSelectedFilters(SelectedFilters)

const Search = () => {
	const location = useLocation()
	const history = useHistory()
	const query = new URLSearchParams(location.search)

	const [layout, setLayout] = useState(query.get('layout') || 'grid')

	const selectGrid = () => {
		if (layout !== 'grid') {
			setLayout('grid')
		}
	}

	const selectList = () => {
		if (layout !== 'list') {
			setLayout('list')
		}
	}

	// Save layout param into URL
	useEffect(() => {
		query.set('layout', layout)
		history.push({
			search: query.toString(),
		})
		// eslint-disable-next-line
  }, [layout])

	return (
		<StyledSearch>
			<Helmet>
				<title>Search - Mindscape Commons</title>
			</Helmet>
			<div className="container flex flex-col sm:flex-row">
				<div className="sr-only">
					<h1>Search page</h1>
				</div>
				<div className="left-side-bar flex flex-wrap flex-col text-black">
					<Facets />
				</div>

				<div className="result-container">
					<div className="flex justify-between items-center px-4">
						<CustomSelectedFilters
							showClearAll
							clearAllLabel="Clear filters"
							className="px-3 w-full filter-button-row"
							innerClass={{
								button: 'filter-button text-black bg-white hover:text-accent',
							}}
						/>
						<div className="result-toggle flex items-center">
							<button
								type="button"
								className="grid"
								role="radio"
								aria-label="Show Grid View"
								title="Show Grid View"
								aria-checked={layout === 'grid'}
								onClick={selectGrid}
							>
								<i className="fas fa-th" />
							</button>
							<button
								type="button"
								className="list ml-2"
								title="Show List View"
								role="radio"
								aria-label="Show List View"
								aria-checked={layout === 'list'}
								onClick={selectList}
							>
								<i className="fas fa-list" />
							</button>
						</div>
					</div>

					<CustomReactiveList
						componentId="results-search"
						dataField="title"
						className="result-list p-3"
						size={20}
						defaultQuery={() => ({
							query: {
								match: {
									rectype: 'experience',
								},
							},
						})}
						loader={<div className="result-loader">Loading results...</div>}
						noResults="No results were found..."
						renderResultStats={(stats) => {
							trackSearch()
							return (
								<div className="result-count mx-3">
									{stats.numberOfResults} results
								</div>
							)
						}}
						react={{
							and: [
								'q',
								'results-search',
								'subject',
								'publisher',
								'access',
								'release-year-from',
								'release-year-to',
								'scene',
								'device',
								'immersion',
								'interactivity',
								'demographics',
							],
						}}
						innerClass={{
							list: 'list-container',
							sortOptions: 'sort-options',
							resultStats: 'result-stats',
							resultsInfo: 'result-list-info',
						}}
					>
						{({ data }) => (
							<>
								{layout === 'grid' && (
									<ReactiveList.ResultCardsWrapper
										className="mt-5"
										id="result-grid"
									>
										{data.map((item) => (
											<WithLinkResultCard
												aria-label={`${item.title} access type ${item?.['access-type']}`}
												key={item._id}
												href={`/view/${item.slug}`}
												target="_self"
												className="grid-item w-full"
												item={item}
											>
												{item.image && item.image[0].thumbnails && (
													<ResultCard.Image
														aria-hidden="true"
														alt={item.title}
														src={item.image[0].thumbnails.large.url}
														className="result-image"
													/>
												)}
												<ResultCard.Title className="result-title">
													{item.title}
												</ResultCard.Title>
												<ResultCard.Description>
													<div className="flex column justify-space-between">
														<div className="row">
															<div className="column">
																<div className="detail">
																	{item.publisher && item.publisher[0].name}
																	{item.publisher && ', '}
																	{item.publisher &&
																		item.publisher[0].country &&
																		item.publisher[0].country}
																	{item.publisher &&
																		item.publisher[0].country &&
																		item['release-date'] !== 1999 &&
																		', '}
																	{item['release-date'] &&
																		item['release-date'] !== 1999 &&
																		'('}
																	{item['release-date'] &&
																		item['release-date'] !== 1999 &&
																		item['release-date']}
																	{item['release-date'] &&
																		item['release-date'] !== 1999 &&
																		')'}
																</div>
															</div>
														</div>
													</div>
												</ResultCard.Description>
											</WithLinkResultCard>
										))}
									</ReactiveList.ResultCardsWrapper>
								)}
								{layout === 'list' && (
									<ReactiveList.ResultListWrapper id="result-list">
										{data.map((item) => (
											<WithLinkResultList
												aria-label={`${item.title} access type ${item?.['access-type']}`}
												key={item._id}
												href={`/view/${item.slug}`}
												target="_self"
												className="result-list-item flex flex-wrap"
												accessStyle="top-0 mt-4"
												item={item}
											>
												{item.image && item.image[0].thumbnails && (
													<ResultList.Image
														aria-hidden="true"
														src={item.image[0].thumbnails.large.url}
														className="w-full md:m-0 md:w-1/4 result-image rounded-lg"
													/>
												)}
												<ResultList.Content
													aria-hidden="true"
													className="w-full md:m-0 md:w-3/4"
												>
													<ResultList.Title className="result-title">
														{item.title}
													</ResultList.Title>
													<ResultList.Description>
														<div className="flex column justify-space-between">
															<div className="detail">
																{item.publisher && item.publisher[0].name}
																{item.publisher && ', '}
																{item.publisher &&
																	item.publisher[0].country &&
																	item.publisher[0].country}
																{item.publisher &&
																	item.publisher[0].country &&
																	item['release-date'] !== 1999 &&
																	', '}
																{item['release-date'] &&
																	item['release-date'] !== 1999 &&
																	'('}
																{item['release-date'] &&
																	item['release-date'] !== 1999 &&
																	item['release-date']}
																{item['release-date'] &&
																	item['release-date'] !== 1999 &&
																	')'}
															</div>
															{item.purpose && (
																<div className="summary">
																	<ReactMarkdown
																		source={item.purpose}
																		renderers={{
																			paragraph: (props) => <div {...props} />,
																		}}
																	/>
																</div>
															)}
															{item.description && (
																<div className="description">
																	<ReactMarkdown
																		source={item.description}
																		renderers={{
																			paragraph: (props) => <div {...props} />,
																		}}
																	/>
																</div>
															)}
														</div>
													</ResultList.Description>
												</ResultList.Content>
											</WithLinkResultList>
										))}
									</ReactiveList.ResultListWrapper>
								)}
							</>
						)}
					</CustomReactiveList>
				</div>
			</div>
		</StyledSearch>
	)
}

export default Search
