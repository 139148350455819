/**
 * View a Title.
 */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation } from 'react-router-dom'
import { ReactiveList } from '@appbaseio/reactivesearch'
import { useAuth0 } from '../../react-auth0-spa'

import { setIsTheater } from '../../reducers/viewPage/actions'
import { theaterModeSearchParam } from '../../reducers/viewPage/types'
import Breadcrumbs from '../Breadcrumbs'
import ViewLayout from './ViewLayout'
import { StyledView } from './View.styled'

const View = () => {
	const isTheater = useSelector((state) => state.viewPage.isTheater)
	const location = useLocation()
	const dispatch = useDispatch()
	const { id } = useParams()

	/**
	 * After rendering component (componentDidMount)
	 * check initial isTheater state from url
	 */
	useEffect(() => {
		const query = new URLSearchParams(location.search)
		const initialIsCollapsed = query.get(theaterModeSearchParam) === 'true'
		dispatch(setIsTheater(initialIsCollapsed))
	})

	const initialAuth = {
		individual: false,
		institution: false,
		visitor: true,
		plan: 'Visitor',
		registrationMessage: '',
		membershipMessage: '',
	}
	const [items, setItems] = useState([])
	// We assume that item can have only one collection (channel)
	const [collections, setCollections] = useState([])
	const [authorizations, setAuthorizations] = useState(initialAuth)

	const {
		loading,
		loadingInstitution,
		isAuthorizedIndividual,
		isAuthorizedInstitution,
	} = useAuth0()

	function getSlug(item) {
		return item?.channel?.[0]?.slug
	}

	function getQuery(slug) {
		return () => ({
			query: {
				match: {
					'slug.keyword': slug,
				},
			},
		})
	}

	useEffect(() => {
		function setAuthDetails() {
			let yourPlan = 'Visitor'
			if (isAuthorizedInstitution) {
				yourPlan = 'Institution'
			} else if (isAuthorizedIndividual) {
				yourPlan = 'Individual'
			}

			const registrationRequired = `
				<p className="text-center">This content requires <strong>Individual Registration</strong>. Registration is <strong>free</strong>.<br/>
				Click on <strong>Login / Sign Up</strong> at the top of the page.</p>
				<hr/>
				<p className="text-center">Your plan: <a aria-label="Link to the membership page" href="/about/membership">${yourPlan}</a></p>
				`
			const membershipRequired = `
				<p className="text-center">Accessing this content requires an <strong>Institutional Membership</strong>.<br/>
				Please click <a href='/about/membership'>here</a> to start a trial.<br/>
				<small>If your institution has a membership, please log into Mindscape from a link provided by your institution.  This typically involves logging in via a menu managed by your campus library.</small></p>
				<hr/>
				<p className="text-center">Your plan: <a aria-label="Link to the membership page" href="/about/membership">${yourPlan}</a></p>
				`
			const authDetails = {
				individual: isAuthorizedIndividual,
				institution: isAuthorizedInstitution,
				visitor: !isAuthorizedInstitution && !isAuthorizedIndividual,
				plan: yourPlan,
				registrationMessage: registrationRequired,
				membershipMessage: membershipRequired,
			}

			setAuthorizations(authDetails)
		}

		if (!loading && !loadingInstitution) {
			setAuthDetails()
		}
	}, [
		loading,
		loadingInstitution,
		isAuthorizedIndividual,
		isAuthorizedInstitution,
	])

	if (loading || loadingInstitution) {
		return <div />
	}

	return (
		<StyledView>
			{!isTheater && (
				<div className="container">
					<div className="breadcrumbs text-left my-4">
						<Breadcrumbs />
					</div>
				</div>
			)}
			<div className="pt-5">
				<ViewLayout
					collections={collections}
					items={items}
					authorizations={authorizations}
				/>
			</div>

			{/* Retrieving item's data */}
			<ReactiveList
				className="container"
				componentId="ViewImageResult"
				dataField="title"
				showResultStats={false}
				onData={(res) => setItems(res.data)}
				renderItem={() => ''}
				defaultQuery={getQuery(id)}
			/>

			{/* Retrieving collections for the item */}
			{items
				.filter((item) => !!getSlug(item))
				.map((item) => (
					<ReactiveList
						key={getSlug(item)}
						componentId="view-channel-meta"
						dataField="title"
						renderItem={() => ''}
						showResultStats={false}
						size={1}
						onData={(res) => setCollections(res.data)}
						defaultQuery={getQuery(getSlug(item))}
					/>
				))}
		</StyledView>
	)
}

export default View
