/**
 * Modal to display and format standard APA and MLA citations.
 *
 * Usage:
 *
 * import Cite from './Cite';
 * <Cite item={item}/>
 *
 * ...where item is a retrieved document from the directory.
 * uses Modal.styled to format the citation in the modal.
 */
import React, { useState, useRef } from 'react'
import Modal from 'react-modal'
import { Tooltip } from 'react-tippy'

import { StyledModal } from './Modal.styled'
import { revealAriaMessage } from '../../helpers/accessibilityHelpers'

const customStyles = {
	overlay: {
		backgroundColor: 'rgba(8, 8, 8, 0.50)',
	},
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		padding: '0',
		transform: 'translate(-50%, -50%)',
		width: '500px',
		maxWidth: '95%',
		maxHeight: '75%',
	},
}

// Bind modal to the appElement.  This allows the model to disable the background.
// See: http://reactcommunity.org/react-modal/accessibility/
Modal.setAppElement('#root')

function Cite(props) {
	const [tooltipOpened, setTooltipOpened] = useState(false)

	// to show screen reader's alerts
	const ariaMessage = useRef(null)

	const [modalIsOpen, setIsOpen] = React.useState(false)
	const [item, setItem] = React.useState({})
	const [copySuccess, setCopySuccess] = React.useState()

	function openModal() {
		setIsOpen(true)
		setItem(props.item)
	}

	function closeModal() {
		setIsOpen(false)
	}

	function copyToClipBoard(id, e) {
		setCopySuccess('')
		const sourceNode = document.getElementById(id)
		if (sourceNode) {
			const destNode = document.getElementById('copy-buffer')
			destNode.value = sourceNode.textContent
			destNode.select()
			const copySuccessful = document.execCommand('copy')
			if (copySuccessful) {
				revealAriaMessage(ariaMessage.current, 'Copied successfully')
				const orig = sourceNode.style.color
				sourceNode.style.color = '#7B2031'
				setTimeout(() => {
					sourceNode.style.color = orig
				}, 2000)
			} else {
				revealAriaMessage(ariaMessage.current, 'Fail to copy')
				setCopySuccess('Failed!')
			}
		} else {
			// eslint-disable-next-line
			console.log(`Could not find ${id}`)
			setCopySuccess(`Could not find ${id}`)
		}
		e.target.focus()
	}

	return (
		<span className="cite_modal">
			<Tooltip title="Cite This Page" position="top" open={tooltipOpened}>
				<button
					type="button"
					onClick={openModal}
					onBlur={() => setTooltipOpened(false)}
					onFocus={() => setTooltipOpened(true)}
					onMouseEnter={() => setTooltipOpened(true)}
					onMouseLeave={() => setTooltipOpened(false)}
				>
					<img src="/cite-icon.svg" alt="Cite This Page" />
				</button>
			</Tooltip>

			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				style={customStyles}
				contentLabel="Citation Modal"
			>
				<StyledModal>
					<div className="modal-header">
						<div ref={ariaMessage} className="sr-only" aria-live="assertive" />
						<h2 className="modal-title">Cite This Page</h2>
						<button
							type="button"
							aria-label="Close Modal"
							onClick={closeModal}
							className="close"
						>
							<span aria-hidden="true">x</span>
						</button>
					</div>
					<div className="modal-body">
						<div className="row cite-row">
							<div className="column left">
								<button
									type="button"
									className="btn"
									onClick={(e) => copyToClipBoard('apa-cite', e)}
								>
									Copy APA
								</button>
							</div>
							<div className="column right">
								<h3>APA Citation</h3>
								<div id="apa-cite">
									<div className="citation">
										{item['project-leader'] &&
											item['project-leader'].map((pl) => (
												<span key={pl.id}>
													{pl['last-name']}
													{', '}
													{pl['first-name'].substring(0, 1)}
													{'. [Creator]. '}
												</span>
											))}
										{item.publisher && item.publisher[0] && (
											<span>
												{item.publisher[0].name}
												{' [Producer] '}
											</span>
										)}
										{item['release-date'] && item['release-date'] !== 1999 && (
											<span className="rel-date">
												{item['release-date']}
												{'. '}
											</span>
										)}
										{item.title && (
											<span className="title">
												{item.title}
												{'. '}
											</span>
										)}
										{item.channel &&
											item.channel.map(
												(chan, chanindex) =>
													chanindex === 0 && (
														<span key={chan.slug}>
															{'in '}
															{chan.title}
															{'. '}
														</span>
													)
											)}
										{item['scenery-type'] &&
											item['scenery-type'].map((stype) => (
												<span key={stype}>
													{' ['}
													{stype}
													{'].'}
												</span>
											))}
										{' Retrieved from '}
										{window.location.href}
										{item.cid && (
											<span>
												<span className="uppercase">. cid:</span>
												{item.cid}
											</span>
										)}
									</div>
								</div>
							</div>
						</div>
						<div className="row cite-row">
							<div className="column left">
								<button
									type="button"
									className="btn"
									onClick={(e) => copyToClipBoard('mla-cite', e)}
								>
									Copy MLA
								</button>
							</div>
							<div className="column right">
								<h3>MLA Citation</h3>
								<div id="mla-cite">
									<div className="citation">
										{item['project-leader'] &&
											item['project-leader'].map((pl) => (
												<span key={pl.id}>
													{pl['first-name']} {pl['last-name']}
													{', '}
												</span>
											))}
										{item.title && (
											<span className="title">
												{item.title}
												{'. '}
											</span>
										)}
										{item.channel &&
											item.channel.map(
												(chan, chanindex) =>
													chanindex === 0 && (
														<span key={chan.slug}>
															{'in '}
															{chan.title}
															{'. '}
														</span>
													)
											)}
										{item.publisher &&
											item.publisher[0] &&
											item.publisher[0].name === 'Mindscape' && (
												<span>{'Elizabeth Robey, ed., '}</span>
											)}
										{item.publisher && item.publisher[0] && (
											<span>
												{item.publisher[0].name}
												{', '}
											</span>
										)}
										{item['release-date'] && item['release-date'] !== 1999 && (
											<span className="rel-date">
												{item['release-date']}
												{', '}
											</span>
										)}
										{window.location.href}
										{item.cid && (
											<span>
												<span className="uppercase">. cid:</span>
												{item.cid}
											</span>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
					{copySuccess}
					<textarea id="copy-buffer" />
				</StyledModal>
			</Modal>
		</span>
	)
}

export default Cite
