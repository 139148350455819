import { ACTIONS } from './types'

export function updateLastTitle(title) {
	return {
		type: ACTIONS.UPDATE_LAST_TITLE,
		title,
	}
}

export function addRoute(route) {
	return {
		type: ACTIONS.PUSH,
		route,
	}
}
