import { ACTIONS } from './types'

export default function history(state = document.title, action) {
	switch (action.type) {
		case ACTIONS.CHANGE:
			return action.title
		default:
			return state
	}
}
