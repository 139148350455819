// NavBar.styled.js
import styled from 'styled-components'

export const StyledNavBar = styled.div`
	background-color: ${({ theme }) => theme.secondaryBackground};
	position: fixed; /* Set the navbar to fixed position */
	top: 0; /* Position the navbar at the top of the page */
	z-index: 10;

	.app-logo {
		margin-top: 3px;
		height: 54px;
		float: none;
	}

	.search-bar div input {
		border-radius: 25px;
		background-color: ${({ theme }) => theme.secondaryBackground};
	}

	.search-bar li {
		color: #000000;
	}

	.signin-link-container {
	}

	a {
		color: ${({ theme }) => theme.secondaryLight};
	}

	small {
		margin-top: 4px;
		color: ${({ theme }) => theme.secondaryLight};
	}

	.avatar {
		vertical-align: top;
		display: inline-block;
	}

	.lolink {
		display: inline-block;
	}

	.burger-block {
		width: 16px;
	}

	.search-bar-input ~ ul {
		display: none;
	}

	.search-bar-input:focus ~ ul {
		display: block;
	}
`
