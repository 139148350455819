/**
 * Help Section on VR Apps.
 */
import React from 'react'
import { useParams, Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { StyledVRApp } from './VRApp.styled'

const VRApp = () => {
	const { section } = useParams()

	return (
		<StyledVRApp>
			<div>
				{section === undefined && (
					<Helmet>
						<title>VR Application Help - Mindscape Commons</title>
					</Helmet>
				)}
				<h1>Uptale VR Application Help</h1>
				<ul>
					<li>
						<Link to="/help/vrapp/install">
							Installing the Uptale VR Application
						</Link>
					</li>
					<li>
						<Link to="/help/vrapp/operation">
							Using the Uptale VR Application
						</Link>
					</li>
					<li>
						<Link to="/help">Help Menu</Link>
					</li>
				</ul>
			</div>
			{section === 'install' && (
				<div>
					<Helmet>
						<title>VR Application Installation - Mindscape Commons</title>
					</Helmet>
					<h1>Installing the Uptale VR Application</h1>
					<p>
						Viewing of Mindscape-published titles on a head-mounted device
						requires the installation of a viewer application called
						&quot;Uptale&quot;. The application is free but, depending on your
						device, installation might require a coupon code or additional
						registration information.
					</p>

					<p>Choose the link below for your type of device.</p>
					<div>
						<h2>Android Devices</h2>
						<p>
							This choice is for Samsung, Pixel and other Android phones and
							tablets. This is the choice if you have a Cardboard enclosure for
							your Android phone.
						</p>
						<a
							href="https://play.google.com/store/apps/details?id=com.Uptale.Player&hl=en_US&rdid=com.Uptale.Player"
							target="_hmd"
							title="Opens in another window"
							rel="noopener noreferrer"
						>
							<i className="fab fa-android" /> Uptale app on the Google Play
							Store
						</a>
						<p>
							To install the Uptale app on your Android phone, scan this QR Code
							and it will open the Google Play Store link to Uptale.
						</p>
						<img
							src="/help/uptale-on-google.png"
							title="Scan QR Code to get uptale viewer from Play Store"
							alt="Scan QR Code to get uptale viewer from Play Store"
						/>
					</div>
					<div>
						<h2>iOS Devices</h2>
						<p>
							This choice is for iPhones and iPad tablets. This is the choice if
							you have a Cardboard enclosure for your iPhone.
						</p>
						<a
							href="https://apps.apple.com/us/app/uptale-player/id1486311874?ls=1"
							target="_hmd"
							title="Opens in another window"
							rel="noopener noreferrer"
						>
							<i className="fab fa-apple" /> Uptale app in the Apple App Store
						</a>
						<p>
							To install the Uptale app on your iPhone, scan this QR Code and it
							will open the App Store link to Uptale.
						</p>
						<img
							src="/help/uptale-on-ios.png"
							title="Scan QR Code to get uptale viewer from App Store"
							alt="Scan QR Code to get uptale viewer from App Store"
						/>
					</div>
					<div>
						<h2>Windows Computers</h2>
						<p>
							This choice is for Oculus Rift on a Windows computer. The link
							will download the zipped application.
						</p>
						<a
							href="https://coherentdigital.uptale.io/Applications/GetApplication"
							target="_hmd"
							title="Downloads windows PC app"
							rel="noopener noreferrer"
						>
							<i className="fas fa-desktop" /> Uptale app for Windows Computers
						</a>
					</div>
					<div>
						<h2>Oculus Go and Quest</h2>
						<div>
							<p>
								To be able to use Uptale Player on Oculus Go or Quest, you would
								have to contact Uptale&apos;s support team for access:{' '}
								<a href="mailto:support@uptale.io">support@uptale.io</a>.
								Indicate in your email that you are a user of Mindscape Commons
								from Coherent Digital.
							</p>

							<p>Oculus Quest user:</p>
							<p>
								Provide Uptale the email address of your Oculus account with
								which you would like to install the Uptale Player App.
							</p>

							<p>Oculus Go user:</p>
							<p>
								Uptale will provide you a code that allows you to activate the
								application. Once you receive the code, steps vary depending on
								your mobile type:
							</p>
							<ul>
								<li>
									For Android users: You can use the Oculus application on your
									phone and go to Settings &gt; Payment &gt; Redeem Code
								</li>
								<li>
									For iOS users: You should go to
									<a href="https://secure.oculus.com/redeem-code/">
										https://secure.oculus.com/redeem-code/
									</a>
									and log in with your account and enter the code.
								</li>
							</ul>
						</div>
					</div>
				</div>
			)}
			{section === 'operation' && (
				<div>
					<Helmet>
						<title>Using the Uptale VR Application - Mindscape Commons</title>
					</Helmet>
					<h1>Using the Uptale VR Application</h1>
					<div>
						<h2>Entering the Launch Code</h2>
						<div>
							<p>
								Each playback of a Mindscape Commons VR experience in the Uptale
								app requires a 6-character launch code to be entered into the
								app. Note: this applies to titles produced by Mindscape. Other
								VR experiences that are listed in the Mindscape Commons
								directory will use VR technology chosen by that publisher.
							</p>

							<p>
								The launch code is presented on the popup that is opened by the
								link: &apos;View on your Head Mounted Device&apos;. Each launch
								code lasts for 30 minutes and is good for only a single
								download. So if the code has expired, then close and reopen the
								popup to generate a new one.
							</p>

							<p>
								If this experience is not available in the Uptale app, or if
								this experience is not included in your membership, then the
								link for generating a launch code will not appear.
							</p>

							<p>
								With the Launch Code displayed, and committed to memory, open
								the Uptale app on your device. There is a section in the app for
								entering the launch code.
							</p>

							<div>
								<img
									src="/help/enter_launch_code.jpeg"
									alt="Entering a launch code into the Uptale app"
								/>
							</div>

							<p>
								Using your controller, or using eye-gaze (cardboard enclosures
								without controllers), enter the 6-character code into the
								prompt. If it is not expired the app will start to download to
								your device. At the end of the download, the title will start
								playing.
							</p>
						</div>
					</div>

					<div>
						<h2>Re-launching Experiences</h2>
						<div>
							<p>
								Once you have loaded and played the experience once, content
								will be downloaded to your device and you will be able to use it
								offline for 14 days by default.
							</p>

							<p>
								If the title has changed since it was downloaded, the app will
								ask you if you want to play the local copy or not. Typically you
								will want to answer &apos;No&apos; to this question so that the
								app will download the latest copy.
							</p>

							<p>
								When offline, only experiences available locally will be
								displayed.
							</p>
						</div>
					</div>

					<div>
						<h2>User Guides for the Uptale App</h2>
						<div>
							<a
								href="https://coherentdigital.uptale.io/Applications/GetGuide?type=Oculus"
								target="_blank"
								rel="noopener noreferrer"
								title="Opens in another window"
							>
								Uptale User Guide for Oculus Go and Quest
							</a>
						</div>
						<div>
							<a
								href="https://coherentdigital.uptale.io/Applications/GetGuide?type=PC"
								target="_blank"
								rel="noopener noreferrer"
								title="Opens in another window"
							>
								Uptale User Guide for Windows
							</a>
						</div>
					</div>
				</div>
			)}
		</StyledVRApp>
	)
}

export default VRApp
