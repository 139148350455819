// About.styled.js
import styled from 'styled-components'

export const StyledAbout = styled.section`
	padding: 20px;
	background-color: ${({ theme }) => theme.secondaryBackground};
	border-radius: 5px;
	width: 75vw;
	margin: auto;

	h2,
	h3 {
		color: ${({ theme }) => theme.secondaryNormal};
		margin-top: 20px;
		margin-bottom: 5px;
	}

	p {
		margin: 20px;
	}

	* {
		color: ${({ theme }) => theme.secondaryNormal};
		text-align: left;
	}

	a {
		color: ${({ theme }) => theme.accent};
	}

	a:hover {
		text-decoration: underline;
	}
`
