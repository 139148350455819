import { ACTIONS } from './types'

export default function history(state = { isTheater: false }, action) {
	switch (action.type) {
		case ACTIONS.IS_THEATER:
			return { ...state, isTheater: action.value }
		default:
			return state
	}
}
