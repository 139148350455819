// New.styled.js
import styled from 'styled-components'

export const StyledNew = styled.section`
	margin: 20px;
	padding: 20px;
	background: ${({ theme }) => theme.primaryBackground};
	border-radius: 5px;

	p {
		width: 50%;
		margin-top: 20px;
		margin-bottom: 20px;
		margin-left: auto;
		margin-right: auto;
		color: ${({ theme }) => theme.primaryLight};
	}

	h2 {
		color: ${({ theme }) => theme.primaryNormal};
	}
`
