/**
 * The Glossary of Interactive and Immersive Media.
 */
import React from 'react'
import { Helmet } from 'react-helmet'
import { ReactiveList, ResultList } from '@appbaseio/reactivesearch'
import { Link } from 'react-router-dom'

import { useAuth0 } from '../../react-auth0-spa'
import { StyledHelp } from './Help.styled'
import customReactiveList from '../customReactiveList'

const CustomReactiveList = customReactiveList(ReactiveList)

const Glossary = () => {
	const { loading } = useAuth0()

	if (loading) {
		return <div>Loading...</div>
	}

	return (
		<StyledHelp>
			<Helmet>
				<title>Glossary Mindscape Commons</title>
			</Helmet>
			<h1>The Glossary of Interactive and Immersive Media</h1>
			<div className="result-container">
				<div className="breadcrumbs">
					<Link aria-label="return to help page" to="/help">
						Return
					</Link>
				</div>
				<CustomReactiveList
					componentId="results-glossary"
					dataField="name.keyword"
					sortBy="asc"
					sortOptions={undefined}
					defaultQuery={() => ({
						query: {
							match: {
								rectype: 'definition',
							},
						},
					})}
					Loader="Loading..."
					noResults="No results were found..."
					showResultStats={false}
					react={{
						and: ['q', 'results-glossary'],
					}}
					innerClass={{
						title: 'result-title',
						resultStats: 'result-stats',
						resultsInfo: 'result-list-info',
					}}
				>
					{({ data }) => (
						<>
							<ReactiveList.ResultListWrapper>
								{data.map((item) => (
									<ResultList key={item._id} className="result-item">
										{item.image && item.image[0].thumbnails && (
											<ResultList.Image
												src={item.image[0].thumbnails.large.url}
												className="result-image"
											/>
										)}
										<ResultList.Content>
											<ResultList.Title className="result-title">
												{item.name}
											</ResultList.Title>
											<ResultList.Description className="description">
												{item.definition}
											</ResultList.Description>
										</ResultList.Content>
									</ResultList>
								))}
							</ReactiveList.ResultListWrapper>
						</>
					)}
				</CustomReactiveList>
			</div>
		</StyledHelp>
	)
}

export default Glossary
