// Menu.js
import React, { useEffect } from 'react'
import { bool, func } from 'prop-types'
import { Link } from 'react-router-dom'
import CustomerBranding from '../../utils/CustomerBranding'
import { StyledMenu } from './Menu.styled'
import { useAuth0 } from '../../react-auth0-spa'

const Menu = ({ open, setOpen }) => {
	const {
		isAuthorizedIndividual,
		isAuthorizedInstitution,
		logout,
		loginWithPopup,
	} = useAuth0()
	const port =
		window.location.port === '' ||
		window.location.port === '80' ||
		window.location.port === '443'
			? ''
			: `:${window.location.port}`
	const loginDest = `${window.location.protocol}//${window.location.hostname}${port}/${window.location.pathname}`
	const logoutDest = `${window.location.protocol}//${window.location.hostname}${port}/`
	const isAuthorizedInstitutionOrIndividual =
		isAuthorizedIndividual || isAuthorizedInstitution

	function handleKeyUp(e) {
		const ESC = 27
		if (e.keyCode === ESC) {
			e.preventDefault()
			setOpen(false)
		}
	}

	// add listener for ESC
	useEffect(() => {
		window.addEventListener('keyup', handleKeyUp, false)

		// remove listener after unmount component
		return () => {
			window.removeEventListener('keyup', handleKeyUp)
		}
	})

	return (
		<StyledMenu open={open} className="mt-16" role="navigation">
			<div className="container menu-header">
				<div className="grid grid-cols-2 md:grid-cols-4 py-5">
					<div className="flex flex-col text-left">
						<h2 className="menu-title">Browse By</h2>
						<ul>
							<li>
								<Link to="/search" onClick={() => setOpen(!open)}>
									All Titles
								</Link>
							</li>
							<li>
								<Link to="/browse/subjects" onClick={() => setOpen(!open)}>
									Subjects
								</Link>
							</li>
							<li>
								<Link to="/browse/collections" onClick={() => setOpen(!open)}>
									Collections
								</Link>
							</li>
							<li>
								<Link to="/browse/immersion" onClick={() => setOpen(!open)}>
									Immersion Factors
								</Link>
							</li>
						</ul>
					</div>
					<div className="flex flex-col text-left">
						<h2 className="menu-title">Community</h2>
						<ul>
							<li>
								<Link to="/submit" onClick={() => setOpen(!open)}>
									Submit an Entry
								</Link>
							</li>
							<li>
								<Link to="/help" onClick={() => setOpen(!open)}>
									Help
								</Link>
							</li>
							<li>
								<Link to="/browse/resources" onClick={() => setOpen(!open)}>
									Resources
								</Link>
							</li>
							<li>
								<Link to="/about/infographic" onClick={() => setOpen(!open)}>
									Infographic
								</Link>
							</li>
						</ul>
					</div>
					<div className="flex flex-col text-left">
						<h2 className="menu-title">About</h2>
						<ul>
							<li>
								<Link to="/about#top" onClick={() => setOpen(!open)}>
									Mindscape Commons
								</Link>
							</li>
						</ul>
					</div>
					<div className="flex sm:hidden flex-col text-left">
						<h2 className="menu-title">User Menu</h2>
						{isAuthorizedIndividual && (
							<div className="text-left">
								<Link to="#!" onClick={() => logout({ returnTo: logoutDest })}>
									Logout
								</Link>
							</div>
						)}
						{!isAuthorizedIndividual && (
							<div className="text-left">
								<Link
									to="#!"
									onClick={() => loginWithPopup({ returnTo: loginDest })}
								>
									Login / Register
								</Link>
							</div>
						)}
					</div>
					{!isAuthorizedInstitutionOrIndividual && (
						<div className="hidden sm:flex flex-col text-left column-four">
							<h2 className="menu-title">Not a member?</h2>
							<a
								href="/about/membership"
								className="w-full sponsor-link flex justify-center items-center"
							>
								<span className="pl-5">Join Now!</span>
							</a>
						</div>
					)}
					{isAuthorizedInstitution && (
						<div className="hidden sm:flex flex-col text-left column-four">
							<CustomerBranding />
						</div>
					)}
				</div>
			</div>
		</StyledMenu>
	)
}

Menu.propTypes = {
	open: bool.isRequired,
	setOpen: func.isRequired,
}

export default Menu
