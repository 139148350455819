/**
 * Home Page.
 */
import React, { useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { DataSearch, ReactiveList } from '@appbaseio/reactivesearch'
import { Helmet } from 'react-helmet'
import ReactMarkdown from 'react-markdown'
import { StyledHome } from './Home.styled'

const Home = () => {
	const rootElem = useRef(null)

	useEffect(() => {
		const searchBarInput = rootElem.current.querySelector('.search-bar input')
		if (searchBarInput) {
			searchBarInput.setAttribute('aria-label', 'Search for experiences')
		}
	}, [])

	return (
		<StyledHome ref={rootElem}>
			<Helmet>
				<title>Home - Mindscape Commons</title>
			</Helmet>
			<div className="home-container">
				<h1 className="sr-only">Home Page</h1>
				<div className="row home-row home-row-search">
					<DataSearch
						componentId="q"
						dataField={[
							'title',
							'publisher.name',
							'project-leader.first-name',
							'project-leader.last-name',
							'purpose',
							'description',
							'subject',
							'target-platform',
							'interactivity',
							'scenery-type',
							'immersion-factor',
							'interactivity-factor',
							'demographics',
							'supplementary-materials.material-type',
						]}
						fieldWeights={[5, 3, 3, 3, 3, 1, 1, 1, 1, 1, 1]}
						queryFormat="and"
						placeholder="Search for experiences.."
						filterLabel="Your query"
						searchOperators
						showClear
						className="search-bar w-full md:w-1/2 my-auto mx-5 md:m-auto text-black"
						URLParams
						onValueSelected={
							/** Function to redirect a query submission to /search */
							(value) => {
								if (value && value.trim().length > 0) {
									// Remove double quotes as we are about to add our own.
									const newValue = value.replace(/["]+/g, '')
									window.location.href = `/search?q="${encodeURI(newValue)}"`
								} else {
									window.location.href = '/search'
								}
							}
						}
					/>
				</div>
				<div className="container">
					<h2 className="mt-10 font-bold home-row-title text-base text-left mx-2">
						Featured Experiences
					</h2>
					<ReactiveList
						componentId="featured"
						dataField="title"
						size={5}
						showResultStats={false}
						defaultQuery={() => ({
							query: {
								range: {
									featured: {
										gte: 1,
										lte: 5,
									},
								},
							},
							sort: [{ featured: 'asc' }],
						})}
						loader="Loading..."
					>
						{({ data }) => (
							<div className="flex flex-wrap justify-center">
								{data.map((item) => (
									<div
										className="column flex-grow sm:flex-grow-0 xl:flex-grow"
										key={item._id}
									>
										<Link
											aria-label={`Featured Experiences ${item.title}`}
											to={`/view/${item.slug}`}
											className="featured-experiences"
										>
											{item.image && item.image[0].thumbnails && (
												<img
													aria-hidden="true"
													src={item.image[0].thumbnails.large.url}
													className="h-64 sm:h-40 result-image"
													alt={item.title}
												/>
											)}
											<div className="caption" aria-hidden="true">
												<p>{item.title}</p>
												{item.purpose && (
													<small>
														<ReactMarkdown
															source={`${item.purpose
																.split(' ')
																.slice(0, 20)
																.join(' ')}...`}
															renderers={{
																paragraph: (props) => <div {...props} />,
															}}
														/>
													</small>
												)}
												<small>
													By {item.publisher && item.publisher[0].name}
												</small>
											</div>
										</Link>
									</div>
								))}
							</div>
						)}
					</ReactiveList>
				</div>

				<div className="browses">
					<div className="container">
						<h2 className="mt-10 font-bold home-row-title text-base text-left mx-2">
							Featured Browses
						</h2>
					</div>

					<div className="container">
						<div className="flex flex-wrap justify-center">
							<div className="column flex-grow sm:flex-grow-0 xl:flex-grow">
								<Link
									aria-label="Featured Browses: Subjects"
									to="/browse/subjects"
									className="featured-browses"
								>
									<img
										aria-hidden="true"
										src="/subjects.png"
										alt="Subject books"
									/>
									<div className="caption" aria-hidden="true">
										<p>Subjects</p>
										<small>
											Mindscape Commons covers over <b>90</b> subjects in
											Counseling, Psychology and Mental Health
										</small>
									</div>
								</Link>
							</div>
							<div className="column flex-grow sm:flex-grow-0 xl:flex-grow">
								<Link
									aria-label="Featured Browses: Publisher"
									to="/browse/publisher"
									className="featured-browses"
								>
									<img
										aria-hidden="true"
										src="/publisher.png"
										alt="Publisher"
									/>
									<div className="caption" aria-hidden="true">
										<p>Publisher</p>
									</div>
									<small aria-hidden="true">
										Mindscape Commons has interactive and immersive experiences
										from over <b>90</b> VR developers including
										faculty-developed concepts and commercial publications
									</small>
								</Link>
							</div>
							<div className="column flex-grow sm:flex-grow-0 xl:flex-grow">
								<Link
									aria-label="Featured Browses: Titles For Your Device"
									to="/browse/device"
									className="featured-browses"
								>
									<img aria-hidden="true" src="/device.png" alt="VR Headset" />
									<div className="caption" aria-hidden="true">
										<p>Titles For Your Device</p>
									</div>
									<small aria-hidden="true">
										Many VR experiences are published for selected VR headsets.
										Use Mindscape Commons to find the experiences compatible
										with your device.
									</small>
								</Link>
							</div>
							<div className="column flex-grow sm:flex-grow-0 xl:flex-grow">
								<Link
									aria-label="Featured Browses: Virtual Microcases"
									to="/collection/virtualmicrocases"
									className="featured-browses"
								>
									<img
										aria-hidden="true"
										src="/virtual-microcases.png"
										alt="Virtual Microcase Scene"
									/>
									<div className="caption" aria-hidden="true">
										<p>Virtual Microcases</p>
									</div>
									<small aria-hidden="true">
										These three-to-five minute cases feature clients coming to
										therapy for the first time and responding to the question:
										“What brings you into therapy?”
									</small>
								</Link>
							</div>
							<div className="column flex-grow sm:flex-grow-0 xl:flex-grow">
								<Link
									aria-label="Featured Browses: Interactive Simulations "
									to="/collection/interactivesimulations:momentsofexcellenceincounseling"
									className="featured-browses"
								>
									<img
										aria-hidden="true"
										src="/simulations.png"
										alt="Simulation showcase"
									/>
									<div className="caption" aria-hidden="true">
										<p>Interactive Simulations</p>
									</div>
									<small aria-hidden="true">
										Interactive Simulations bring the viewer through a
										multi-scene conversation with a client presenting an issue.
										Between scenes, the viewer is asked questions about the best
										way to guide the conversation with the client.
									</small>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</StyledHome>
	)
}

export default Home
