/**
 * Outline of Beta Testing for New Releases.
 */
import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { StyledHelp } from './Help.styled'

const Beta = () => {
	return (
		<StyledHelp>
			<Helmet>
				<title>Beta Test Program - Mindscape Commons</title>
			</Helmet>
			<h1>Welcome to the Beta Program for Mindscape Commons</h1>
			<div>
				<p>
					Thank you for your participation. This page provides an overview of
					the program and will provide up-to-date status of changes and known
					issues.
				</p>
			</div>

			<h2>Content Included</h2>
			<div>
				<p>
					The Mindscape Beta Program is providing early access to our{' '}
					<Link to="/search">directory of VR titles</Link>
					in counseling, psychology and mental health. The program also provides
					full access to our
					<Link to="/browse/exclusive">exclusive titles</Link> produced by
					Mindscape as well as to titles we have licensed for distribution. Many
					of these titles are still under construction and you may encounter the
					&apos;Under Construction&apos; image when you view the title. In most
					cases a preview is provided. We will list below the titles that have
					been released for beta preview.
				</p>

				<p>
					Please also be aware that after the beta program is over, the
					exclusive titles will be available only to member institutions.
				</p>
			</div>

			<h2>Who is participating?</h2>
			<div>
				<p>
					The Beta Program is available to selected participants. A login is
					provided. Please{' '}
					<a href="mailto:erobey@coherentdigital.net">
						contact Elizabeth Robey
					</a>{' '}
					if someone you know would also like to particiapte.
				</p>
			</div>

			<h2>Duration</h2>
			<div>
				<p>The Beta Program runs from July 22, 2020 through August 14, 2020.</p>
				<p>
					During the beta program, we do expect to be issuing system updates,
					title enhancements and new titles. Please check here for release notes
					if you want to see if anything has changed since your last visit.
				</p>
			</div>

			<h2>Feedback</h2>
			<div>
				<p>
					We welcome feedback of any kind. A survey will be provided to
					participants at the end.
				</p>
				<p>
					Please feel free to send an email to Elizabeth. If you are reporting a
					bug, please also check the list of known issues below.
				</p>
			</div>

			<h2>Exclusive Titles Released for Beta Preview</h2>
			<div>
				<ul>
					<li>
						<Link to="/view/moments-of-excellence-in-counseling-cultural-adaptation">
							Moments of Excellence in Counseling: Cultural Adaptation
						</Link>
					</li>
					<li>
						<Link to="/view/moments-of-excellence-in-counseling-evaluating-suicide">
							Moments of Excellence in Counseling: Evaluating Suicide
						</Link>
					</li>
					<li>
						<Link to="/view/moments-of-excellence-in-counseling-religion-spirituality">
							Moments of Excellence in Counseling: Religion/Spirituality
						</Link>
					</li>
					<li>
						<Link to="/view/moments-of-excellence-in-counseling-stages-of-change">
							Moments of Excellence in Counseling: Stages of Change
						</Link>
					</li>

					<li>
						<Link to="/view/virtual-microcase-anxiety-1">
							Virtual Microcase: Anxiety 1
						</Link>
					</li>
					<li>
						<Link to="/view/virtual-microcase-anxiety-2">
							Virtual Microcase: Anxiety 2
						</Link>
					</li>
					<li>
						<Link to="/view/virtual-microcase-anxiety-3">
							Virtual Microcase: Anxiety 3
						</Link>
					</li>
					<li>
						<Link to="/view/virtual-microcase-depression-1">
							Virtual Microcase: Depression 1
						</Link>
					</li>
					<li>
						<Link to="/view/virtual-microcase-depression-5">
							Virtual Microcase: Depression 5
						</Link>
					</li>
					<li>
						<Link to="/view/virtual-microcase-grief-and-loss-2">
							Virtual Microcase: Grief and Loss 2
						</Link>
					</li>
					<li>
						<Link to="/view/virtual-microcase-sexual-trauma-2">
							Virtual Microcase: Sexual Trauma 2
						</Link>
					</li>
					<li>
						<Link to="/view/virtual-microcase-substance-use-1">
							Virtual Microcase: Substance Use 1
						</Link>
					</li>
					<li>
						<Link to="/view/virtual-microcase-suicidal-thoughts-3">
							Virtual Microcase: Suicidal Thoughts 3
						</Link>
					</li>
				</ul>
			</div>

			<h2>Known Issues</h2>
			<div>
				<ul>
					<li>Page layouts are not all optimized for mobile devices.</li>
					<li>
						On Safari, the launch code for viewing a title on a head-mounted
						device does not appear in the modal. An alternative link is provided
						which opens the launch code page in a new tab.
					</li>
				</ul>
			</div>

			<h2>Release Notes</h2>
			<div>
				<p>
					July 31, 2020: The Exclusive Content list available for beta preview
					has been updated. There are four titles from the{' '}
					<em>Moments of Excellence in Counseling</em> series and nine titles
					from the <em>Virtual Microcases</em> series.
				</p>
			</div>
		</StyledHelp>
	)
}

export default Beta
