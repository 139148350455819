/**
 * Modal to display the embed markup for a view page.
 *
 * Usage:
 *
 * import Embed from './Embed';
 * <Embed url={embed url}/>
 *
 * The embed URL is the same as the view URL with the path /view/ replaced with /embed/
 */
import React, { useState, useRef } from 'react'
import Modal from 'react-modal'
import { Tooltip } from 'react-tippy'

import { StyledModal } from './Modal.styled'
import { revealAriaMessage } from '../../helpers/accessibilityHelpers'

const customStyles = {
	overlay: {
		backgroundColor: 'rgba(8, 8, 8, 0.50)',
	},
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		padding: '0',
		transform: 'translate(-50%, -50%)',
		width: '600px',
		maxWidth: '95%',
		maxHeight: '75%',
	},
}

// Bind modal to the appElement.  This allows the model to disable the background.
// See: http://reactcommunity.org/react-modal/accessibility/
Modal.setAppElement('#root')

function Embed(props) {
	const [tooltipOpened, setTooltipOpened] = useState(false)

	// to show screen reader's alerts
	const ariaMessage = useRef(null)

	const markup = useRef()
	const [modalIsOpen, setIsOpen] = React.useState(false)
	const [copySuccess, setCopySuccess] = React.useState()

	function openModal() {
		setIsOpen(true)
	}

	function afterOpenModal() {
		// references are now sync'd and can be accessed.
		markup.current.innerHTML =
			`&lt;iframe src="${props.url}" width="800" height="500" allowfullscreen="true"&gt;\n` +
			`&lt;/iframe&gt;`
	}

	function closeModal() {
		setIsOpen(false)
	}

	function copyToClipBoard(id, e) {
		setCopySuccess('')
		const sourceNode = document.getElementById(id)
		if (sourceNode) {
			sourceNode.select()
			const copySuccessful = document.execCommand('copy')
			if (copySuccessful) {
				revealAriaMessage(ariaMessage.current, 'Copied successfully')
				const orig = sourceNode.style.color
				sourceNode.style.color = '#7B2031'
				setTimeout(() => {
					sourceNode.style.color = orig
				}, 2000)
			} else {
				revealAriaMessage(ariaMessage.current, 'Fail to copy')
				setCopySuccess('Failed!')
			}
		} else {
			// eslint-disable-next-line
			console.log(`Could not find ${id}`)
			setCopySuccess(`Could not find ${id}`)
		}
		e.target.focus()
	}

	return (
		<span className="embed_modal">
			<Tooltip title="Embed This Page" position="top" open={tooltipOpened}>
				<button
					aria-label="Embed this page"
					type="button"
					className="text-2xl pb-2"
					onClick={openModal}
					onBlur={() => setTooltipOpened(false)}
					onFocus={() => setTooltipOpened(true)}
					onMouseEnter={() => setTooltipOpened(true)}
					onMouseLeave={() => setTooltipOpened(false)}
				>
					&lt;/&gt;
				</button>
			</Tooltip>
			<Modal
				isOpen={modalIsOpen}
				onAfterOpen={afterOpenModal}
				onRequestClose={closeModal}
				style={customStyles}
				contentLabel="Embed"
			>
				<StyledModal>
					<div className="modal-header">
						<div ref={ariaMessage} className="sr-only" aria-live="assertive" />
						<h2 className="modal-title">Embed Markup for this Page</h2>
						<button
							type="button"
							aria-label="Close Modal"
							onClick={closeModal}
							className="close"
						>
							<span aria-hidden="true">x</span>
						</button>
					</div>
					<div className="modal-body">
						<div className="flex flex-col">
							<div className="w-ful">
								<button
									type="button"
									className="btn w-full"
									onClick={(e) => copyToClipBoard('markup', e)}
								>
									Copy
								</button>
							</div>
							<div className="mt-3 ">
								<textarea
									id="markup"
									ref={markup}
									className="h-32 w-full embed_code"
								/>
							</div>
						</div>
						<div className="instructions">
							<p>
								Copy this markup and paste it into your web page, adjusting the
								height and width to your desired dimensions. If your
								organization serves remote users using a proxy, then you should
								login to this page via that proxy to obtain an embed destination
								that supports remote users.
							</p>
							<p>{copySuccess}</p>
						</div>
					</div>
				</StyledModal>
			</Modal>
		</span>
	)
}

export default Embed
