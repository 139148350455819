/**
 * Info about Institutional Membership.
 */
import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { StyledMembership } from './Membership.styled'
import { useAuth0 } from '../../react-auth0-spa'

const Membership = () => {
	const {
		loading,
		loadingInstitution,
		isAuthorizedIndividual,
		isAuthorizedInstitution,
		loginWithPopup,
	} = useAuth0()
	const port =
		window.location.port === '' ||
		window.location.port === '80' ||
		window.location.port === '443'
			? ''
			: `:${window.location.port}`
	const loginDest = `${window.location.protocol}//${window.location.hostname}${port}/`

	if (loading || loadingInstitution) {
		return <div />
	}

	return (
		<StyledMembership>
			<Helmet>
				<title>Membership - Mindscape Commons</title>
			</Helmet>
			<h1 id="top">How can I become part of Mindscape Commons?</h1>

			<p>
				Institutional Membership with full access is available to academic
				institutions, organizations, and clinical centers. Individuals may sign
				up for free to unlock the features indicated below.
			</p>

			<div>
				<div className="grid grid-cols-3 product_cart">
					<div className="product_status">
						{' '}
						{!isAuthorizedIndividual && !isAuthorizedInstitution && (
							<span>Your plan</span>
						)}
					</div>
					<div className="product_status">
						{' '}
						{isAuthorizedIndividual && <span>Your plan</span>}
					</div>
					<div className="product_status">
						{' '}
						{isAuthorizedInstitution && <span>Your plan</span>}
					</div>

					<div className="product" id="product_1">
						<div className="product_reg_title">Visitor</div>
						<div className="product_reg_body_text">
							Search and discover citations for hundreds of VR experiences in
							mental health.
						</div>
						{!isAuthorizedIndividual && !isAuthorizedInstitution && (
							<Link to="/">
								<div className="product_reg_button">Continue Browsing</div>
							</Link>
						)}
					</div>

					<div className="product" id="product_2">
						<div className="product_reg_title">Individual Free</div>
						<div className="product_reg_body_text">
							Richer searching, access to view all open sourced VR content and
							project sites, and other content previews
							<br />
							<b>plus</b>
							<br />
							submit up to 5 VR project entries.
						</div>
						{!isAuthorizedIndividual && !isAuthorizedInstitution && (
							<Link
								to="#!"
								onClick={() => loginWithPopup({ returnTo: loginDest })}
							>
								<div className="product_reg_button">Sign up for FREE</div>
							</Link>
						)}
						{!isAuthorizedIndividual && isAuthorizedInstitution && (
							<Link
								to="#!"
								onClick={() => loginWithPopup({ returnTo: loginDest })}
							>
								<div className="product_reg_button">
									Sign up for More Benefits
								</div>
							</Link>
						)}
						{isAuthorizedIndividual && (
							<Link to="/">
								<div className="product_reg_button">Continue Browsing</div>
							</Link>
						)}
					</div>

					<div className="product" id="product_3">
						<div className="product_reg_title">Institution</div>
						<div className="product_reg_body_text">
							Unlimited access across your institution to over
							<br /> <b>200 experiences</b>,<br />
							including 100 VR experiences and instructor materials available
							nowhere else
							<br />
							<b>plus</b>
							<br />
							unlimited VR project entries
							<br />
							<b>plus</b>
							<br />
							library tools, including usage stats, MARC records, LibGuides, and
							technical support.
						</div>
						{!isAuthorizedInstitution && (
							<a
								href="https://coherentdigital.us20.list-manage.com/subscribe/post?u=d55edcaa77ac7fe8cec6e9ad6&id=e183ecd55b&group[6178][2]=1"
								target="_blank"
								rel="noopener noreferrer"
							>
								<div className="product_reg_button">
									Contact us for a <br />
									free trial and pricing
								</div>
							</a>
						)}
						{isAuthorizedInstitution && (
							<Link to="/">
								<div className="product_reg_button">Continue Browsing</div>
							</Link>
						)}
					</div>
				</div>

				<table className="product_cart">
					<tbody>
						<tr>
							<th className="feature-column">Features</th>
							<th>Visitor</th>
							<th>Individual Free</th>
							<th>Institution</th>
						</tr>
						<tr>
							<td className="feature-column">Pricing</td>
							<td>Free, no registration</td>
							<td>Free, with registration</td>
							<td>Annual Membership</td>
						</tr>
						<tr>
							<td className="feature-column">Search and discovery</td>
							<td>
								<img
									src="/membership/fifty_percent.png"
									className="freemiumpct"
									alt="Some Features Included"
								/>
							</td>
							<td>
								<img
									src="/membership/100_percent.png"
									className="freemiumpct"
									alt="All Features Included"
								/>
							</td>
							<td>
								<img
									src="/membership/100_percent.png"
									className="freemiumpct"
									alt="All Features Included"
								/>
							</td>
						</tr>
						<tr>
							<td className="feature-column">Links to open-access content</td>
							<td>
								<img
									src="/membership/fifty_percent.png"
									className="freemiumpct"
									alt="Some Features Included"
								/>
							</td>
							<td>
								<img
									src="/membership/100_percent.png"
									className="freemiumpct"
									alt="All Features Included"
								/>
							</td>
							<td>
								<img
									src="/membership/100_percent.png"
									className="freemiumpct"
									alt="All Features Included"
								/>
							</td>
						</tr>
						<tr>
							<td className="feature-column">
								Hosting and preservation of your VR projects
							</td>
							<td>
								<img
									src="/membership/zero_percent.png"
									className="freemiumpct"
									alt="Not Included"
								/>
							</td>
							<td>
								<img
									src="/membership/fifty_percent.png"
									className="freemiumpct"
									alt="Some Features Included"
								/>
							</td>
							<td>
								<img
									src="/membership/100_percent.png"
									className="freemiumpct"
									alt="All Features Included"
								/>
							</td>
						</tr>
						<tr>
							<td className="feature-column">Premium content</td>
							<td>
								<img
									src="/membership/zero_percent.png"
									className="freemiumpct"
									alt="Not Included"
								/>
							</td>
							<td>
								<img
									src="/membership/zero_percent.png"
									className="freemiumpct"
									alt="Not Included"
								/>
							</td>
							<td>
								<img
									src="/membership/100_percent.png"
									className="freemiumpct"
									alt="All Features Included"
								/>
							</td>
						</tr>
						<tr>
							<td className="feature-column">Library Features</td>
							<td>
								<img
									src="/membership/zero_percent.png"
									className="freemiumpct"
									alt="Not Included"
								/>
							</td>
							<td>
								<img
									src="/membership/zero_percent.png"
									className="freemiumpct"
									alt="Not Included"
								/>
							</td>
							<td>
								<img
									src="/membership/100_percent.png"
									className="freemiumpct"
									alt="All Features Included"
								/>
							</td>
						</tr>
						<tr>
							<td className="feature-column">Support and Help</td>
							<td>
								<img
									src="/membership/zero_percent.png"
									className="freemiumpct"
									alt="Not Included"
								/>
							</td>
							<td>
								<img
									src="/membership/zero_percent.png"
									className="freemiumpct"
									alt="Not Included"
								/>
							</td>
							<td>
								<img
									src="/membership/100_percent.png"
									className="freemiumpct"
									alt="All Features Included"
								/>
							</td>
						</tr>
						<tr>
							<td colSpan="4" className="caption">
								<img
									src="/membership/zero_percent.png"
									className="freemiumpct"
									alt="Not included"
								/>{' '}
								Not Included&nbsp;&nbsp;&nbsp;
								<img
									src="/membership/fifty_percent.png"
									className="freemiumpct"
									alt="Some Features Included"
								/>{' '}
								Some Features Included&nbsp;&nbsp;&nbsp;
								<img
									src="/membership/100_percent.png"
									className="freemiumpct"
									alt="All Features Included"
								/>{' '}
								All Features Included
							</td>
						</tr>
					</tbody>
				</table>

				<table className="product_cart features" id="product_cart">
					<tbody>
						<tr>
							<th colSpan="2">Details</th>
							<th>Visitor</th>
							<th>Individual Free</th>
							<th>Institution</th>
						</tr>
						<tr className="yellow">
							<td className="feature-column">Discovery</td>
							<td className="feature-column">Search & browse facets</td>
							<td>Limited</td>
							<td>Unlimited</td>
							<td>Unlimited</td>
						</tr>
						<tr className="blue">
							<td rowSpan="3" className="feature-column">
								Published Content
							</td>
							<td className="feature-column">Mindscape Directory</td>
							<td>yes</td>
							<td>yes</td>
							<td>yes</td>
						</tr>
						<tr className="blue">
							<td className="feature-column">Open-access content</td>
							<td>citation only</td>
							<td>direct links</td>
							<td>direct links</td>
						</tr>
						<tr className="blue">
							<td className="feature-column">
								Premium content—more than 100 top-quality VR experiences, half
								of them available only in <i>Mindscape Commons</i>, plus
								original lessons plans, instructor guides, and other support
								resources
							</td>
							<td>citation only</td>
							<td>preview only</td>
							<td>full (incl HMD)</td>
						</tr>
						<tr className="pink">
							<td rowSpan="7" className="feature-column">
								Your content
							</td>
							<td className="feature-column">
								Add your own VR experience to the Mindscape Directory
							</td>
							<td>no</td>
							<td>up to 5 entries</td>
							<td>unlimited</td>
						</tr>
						<tr className="pink">
							<td className="feature-column">
								Hosting of a 360˚ or 180˚ VR Video Experience
							</td>
							<td>no</td>
							<td>on request</td>
							<td>20/year</td>
						</tr>
						<tr className="pink">
							<td className="feature-column">
								Add Project Page for your VR lab
							</td>
							<td>no</td>
							<td>no</td>
							<td>3</td>
						</tr>
						<tr className="pink">
							<td className="feature-column">
								Hosting and preservation of downloadable VR content
							</td>
							<td>no</td>
							<td>no</td>
							<td>1 GB</td>
						</tr>
						<tr className="pink">
							<td className="feature-column">
								We’ll create supplementary materials for your uploaded VR
								experiences—previews, transcripts, MARC records for library
								catalogs—and include any other items you create, such as
								teaching guides
							</td>
							<td>no</td>
							<td>no</td>
							<td>yes</td>
						</tr>
						<tr className="pink">
							<td className="feature-column">Content creation consultation</td>
							<td>no</td>
							<td>no</td>
							<td>yes</td>
						</tr>
						<tr className="pink">
							<td className="feature-column">Analytics for uploaded content</td>
							<td>no</td>
							<td>no</td>
							<td>yes</td>
						</tr>
						<tr className="grey">
							<td rowSpan="3" className="feature-column">
								Sharing
							</td>
							<td className="feature-column">Citation features</td>
							<td>non-standard</td>
							<td>display MLA, APA</td>
							<td>display MLA, APA and export</td>
						</tr>
						<tr className="grey">
							<td className="feature-column">Embeds</td>
							<td>no</td>
							<td>no</td>
							<td>yes</td>
						</tr>
						<tr className="grey">
							<td className="feature-column">Annotations</td>
							<td>no</td>
							<td>no</td>
							<td>coming</td>
						</tr>
						<tr className="green">
							<td rowSpan="9" className="feature-column">
								Library Features
							</td>
							<td className="feature-column">Institution branding</td>
							<td>no</td>
							<td>no</td>
							<td>yes</td>
						</tr>
						<tr className="green">
							<td className="feature-column">MARC records</td>
							<td>no</td>
							<td>no</td>
							<td>yes</td>
						</tr>
						<tr className="green">
							<td className="feature-column">Discovery feeds</td>
							<td>no</td>
							<td>no</td>
							<td>yes</td>
						</tr>
						<tr className="green">
							<td className="feature-column">LibGuides</td>
							<td>no</td>
							<td>no</td>
							<td>yes</td>
						</tr>
						<tr className="green">
							<td className="feature-column">Custom login URLs</td>
							<td>no</td>
							<td>no</td>
							<td>yes</td>
						</tr>
						<tr className="green">
							<td className="feature-column">Usage data (COUNTER)</td>
							<td>no</td>
							<td>no</td>
							<td>yes</td>
						</tr>
						<tr className="green">
							<td className="feature-column">
								Automated updates with PSI IP Registry
							</td>
							<td>no</td>
							<td>no</td>
							<td>yes</td>
						</tr>
						<tr className="green">
							<td className="feature-column">
								Shibboleth federation configuration, WAYFless login
							</td>
							<td>no</td>
							<td>no</td>
							<td>yes</td>
						</tr>
						<tr className="green">
							<td className="feature-column">Perpetual ownership</td>
							<td>no</td>
							<td>no</td>
							<td>optional added cost</td>
						</tr>
						<tr className="blue2">
							<td rowSpan="4" className="feature-column">
								Support & Help
							</td>
							<td className="feature-column">Online help</td>
							<td>yes</td>
							<td>yes</td>
							<td>yes</td>
						</tr>
						<tr className="blue2">
							<td className="feature-column">Community help</td>
							<td>no</td>
							<td>yes</td>
							<td>yes</td>
						</tr>
						<tr className="blue2">
							<td className="feature-column">Email/phone</td>
							<td>no</td>
							<td>no</td>
							<td>yes</td>
						</tr>
						<tr className="blue2">
							<td className="feature-column">Slack channel</td>
							<td>no</td>
							<td>no</td>
							<td>on request</td>
						</tr>
					</tbody>
				</table>
			</div>
		</StyledMembership>
	)
}

export default Membership
