// Footer.styled.js
import styled from 'styled-components'

export const StyledFooter = styled.div`
	width: 100%;
	background-color: ${({ theme }) => theme.captionBackground};
	height: fit-content;

	.footer-header {
		background: #212121;
	}

	.tagline {
		height: 80px;
		margin-top: 60px;
		background: url('./logo-mask-background.svg');
		background-color: ${({ theme }) => theme.secondaryBackground};
	}

	.tagtext {
		font-size: large;
		color: ${({ theme }) => theme.secondaryBold};
	}

	.footer-logo {
		width: 160px;
	}

	.footer-logo-link:focus {
		outline: 1px solid #fff;
	}
`
