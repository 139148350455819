/**
 * Browse
 */
import React from 'react'
import {
	SingleList,
	ReactiveList,
	MultiList,
	ResultCard,
	SelectedFilters,
} from '@appbaseio/reactivesearch'
import { useDispatch } from 'react-redux'
import debounce from 'lodash/debounce'
import { Helmet } from 'react-helmet'

import { renderAccessItem } from '../Facets'
import { addRoute } from '../../reducers/history/actions'
import Collapse, { bodyClassName, headerClassName } from '../Collapse'
import { sortOptions } from '../../constants'
import { StyledBrowse } from './Browse.styled'
import withLink from '../withLink'
import { trackSearch } from '../../utils/TrackingUtils'
import customSelectedFilters from '../customSelectedFilters'
import customReactiveList from '../customReactiveList'
import customMultiList from '../customMultiList'

const CustomMultiList = customMultiList(MultiList)
const WithLinkResultCard = withLink(ResultCard)
const CustomReactiveList = customReactiveList(ReactiveList)
const CustomSelectedFilters = customSelectedFilters(SelectedFilters)

const Browse = (props) => {
	const dispatch = useDispatch()

	/**
	 * We need debounce to prevent saving the same routes twice or more
	 * while clearing filters
	 */
	const multiListOnChangeHandler = debounce(() => {
		dispatch(
			addRoute({
				pathname: window.location.pathname,
				search: window.location.search,
				title: document.title,
			})
		)
	}, 500)

	const {
		componentId,
		dataField = 'subject.keyword',
		defaultQuery = { rectype: 'experience' },
		filterHeading = 'Browse By Subject',
		filterLabel = 'Subject',
		filtersClassName = '',
		loaderMessage = 'Loading results',
		pageTitle,
		placeholder = 'Search for a Subject',
		renderItem = (label, count, isSelected) => (
			<span>
				<span>{label}</span>
				<span
					style={{
						marginLeft: 5,
						color: isSelected ? 'red' : 'black',
					}}
				>
					{count}
				</span>
			</span>
		),
		sortOption = sortOptions.title,
		selectAllLabel = 'All Subjects',
		showSearch = false,
	} = props

	return (
		<StyledBrowse>
			<Helmet>
				<title>{pageTitle || filterHeading}</title>
			</Helmet>
			<div className="container flex flex-col sm:flex-row">
				{pageTitle && <h2 className="sr-only">{pageTitle}</h2>}
				<div className="left-side-bar flex flex-wrap flex-col">
					<Collapse>
						<h3 className={`filter-heading ${headerClassName}`}>
							{filterHeading}
						</h3>
						<SingleList
							componentId={componentId}
							dataField={dataField}
							filterLabel={filterLabel}
							className={`${filtersClassName} overflow-auto browse-list ${bodyClassName}`}
							size={200}
							sortBy="asc"
							selectAllLabel={selectAllLabel}
							showRadio={false}
							showCount
							showSearch={showSearch}
							showFilter
							onValueChange={multiListOnChangeHandler}
							placeholder={placeholder}
							URLParams
							innerClass={{
								label: 'list-item',
								input: 'list-input text-black',
							}}
							defaultQuery={() => ({
								query: {
									match: defaultQuery,
								},
							})}
							react={{
								and: ['q', `access-${componentId}`, `results-${componentId}`],
							}}
							renderItem={renderItem}
						/>
					</Collapse>
					<Collapse>
						<h3 className={`mt-4 filter-heading ${headerClassName}`}>
							Content Type
						</h3>
						<CustomMultiList
							componentId={`access-${componentId}`}
							dataField="access-type.keyword"
							defaultQuery={() => ({
								query: {
									match: defaultQuery,
								},
							})}
							filterLabel="Access"
							className={`access-filter ${bodyClassName}`}
							size={4}
							sortBy="count"
							queryFormat="or"
							selectAllLabel="All Content Types"
							showCheckbox
							showCount
							onValueChange={multiListOnChangeHandler}
							showSearch={false}
							renderItem={renderAccessItem}
							react={{
								and: ['q', `results-${componentId}`],
							}}
							showFilter
							URLParams
							innerClass={{
								checkbox: 'list-checkbox',
								label: 'list-item',
								input: 'list-input',
							}}
						/>
					</Collapse>
				</div>
				<div className="w-full">
					<CustomSelectedFilters
						showClearAll
						clearAllLabel="Clear filters"
						className="my-5 sm:my-0 filter-button-row"
						innerClass={{
							button: 'filter-button',
						}}
					/>
					<CustomReactiveList
						componentId={`results-${componentId}`}
						dataField={dataField}
						size={20}
						sortOptions={Object.values(sortOptions)}
						defaultQuery={() => ({
							query: {
								match: defaultQuery,
							},
						})}
						defaultSortOption={sortOption.label}
						loader={<div className="result-loader">{loaderMessage}...</div>}
						noResults="No results were found..."
						renderResultStats={(stats) => {
							trackSearch()
							return (
								<div className="result-count mr-5">
									{stats.numberOfResults} results
								</div>
							)
						}}
						renderError={(error) => (
							<div>
								Something went wrong!
								<br />
								Error details
								<br />
								{error}
							</div>
						)}
						react={{
							and: [`access-${componentId}`, 'q', componentId],
						}}
						innerClass={{
							title: 'result-title',
							listItem: 'result-item',
							list: 'list-container',
							sortOptions: 'sort-options',
							resultStats: 'result-stats',
							resultsInfo: 'result-list-info',
						}}
					>
						{({ data }) => (
							<>
								<ReactiveList.ResultCardsWrapper
									className="mt-3"
									id="result-list"
								>
									{data.map((item) => (
										<WithLinkResultCard
											key={item._id}
											aria-label={`Result Card: ${item.title} access type ${item?.['access-type']}`}
											href={`/view/${item.slug}`}
											target="_self"
											className="result-item"
											item={item}
										>
											{item.image && item.image[0].thumbnails && (
												<ResultCard.Image
													src={item.image[0].thumbnails.large.url}
													className="result-image"
												/>
											)}
											<ResultCard.Title className="result-title">
												{item.title}
											</ResultCard.Title>
											<ResultCard.Description>
												<div className="flex column justify-space-between">
													<div className="row">
														<div className="column">
															<div className="detail">
																{item.publisher && item.publisher[0].name}
																{item.publisher && ', '}
																{item.publisher &&
																	item.publisher[0].country &&
																	item.publisher[0].country}
																{item.publisher &&
																	item.publisher[0].country &&
																	item['release-date'] !== 1999 &&
																	', '}
																{item.publisher &&
																	item['release-date'] &&
																	item['release-date'] !== 1999 &&
																	'('}
																{item['release-date'] &&
																	item['release-date'] !== 1999 &&
																	item['release-date']}
																{item['release-date'] &&
																	item['release-date'] !== 1999 &&
																	')'}
															</div>
														</div>
													</div>
												</div>
											</ResultCard.Description>
										</WithLinkResultCard>
									))}
								</ReactiveList.ResultCardsWrapper>
							</>
						)}
					</CustomReactiveList>
				</div>
			</div>
		</StyledBrowse>
	)
}

export default Browse
