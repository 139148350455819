// Burger.styled.js
import styled from 'styled-components'

export const StyledBurger = styled.button`
	position: absolute;
	top: 20px;
	right: 0;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	width: 1.5rem;
	height: 1rem;
	background: transparent;
	border: none;
	cursor: pointer;
	padding: 0;
	z-index: 10;
	width: 16px;
	height: 16px;
	overflow: hidden;

	div {
		width: 1rem;
		height: 0.12rem;
		background: ${({ theme, open }) =>
			open ? theme.secondaryNormal : theme.secondaryLight};
		/* transparent border for hight-contrast mode */
		border-bottom: 1px solid rgba(255, 255, 255, 0);
		transition: all 0.3s linear;
		position: relative;
		transform-origin: 1px;

		:first-child {
			transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
		}

		:nth-child(2) {
			opacity: ${({ open }) => (open ? '0' : '1')};
			transform: ${({ open }) => (open ? 'translateX(20px)' : 'translateX(0)')};
		}

		:nth-child(3) {
			transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
		}
	}
`
