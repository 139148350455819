/**
 * ViewLayout
 */
import React from 'react'
import { useSelector } from 'react-redux'

import ViewImageBlock from './ViewImageBlock'
import ViewInfoBlock from './ViewInfoBlock'
import ViewHelmetBlock from './ViewHelmetBlock'
import ViewSeeAlsoBlock from './ViewSeeAlsoBlock'
import StyledViewLayout from './ViewLayout.styled'

const ViewLayout = ({ collections = [], items = [], authorizations = {} }) => {
	const isTheater = useSelector((state) => state.viewPage.isTheater)

	return (
		<StyledViewLayout isTheater={isTheater}>
			{items.map((item, index) => (
				// eslint-disable-next-line
				<div key={index} className="mb-20" className="theater-container">
					<ViewHelmetBlock item={item} />
					<div className={isTheater ? 'flex justify-between' : ''}>
						<div className={`${isTheater ? 'bg-black' : ''} view-container`}>
							<div className="container">
								<ViewImageBlock item={item} authorizations={authorizations} />
							</div>
						</div>
						<div className="info-container">
							<div className="container">
								<ViewInfoBlock
									collections={collections}
									item={item}
									authorizations={authorizations}
								/>
							</div>
						</div>
					</div>
					{!isTheater && (
						<div>
							<ViewSeeAlsoBlock relatedProjects={item['related-projects']} />
						</div>
					)}
				</div>
			))}
		</StyledViewLayout>
	)
}

export default ViewLayout
