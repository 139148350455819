/**
 * Contact Us Page.
 */
import React from 'react'
import { StyledContact } from './Contact.styled'

const Contact = () => {
	return (
		<StyledContact>
			<h2>Contact Us</h2>
			<p>This page is under construction</p>
		</StyledContact>
	)
}

export default Contact
