// Facets.styled.js
import styled from 'styled-components'

export const StyledFacets = styled.div`
	.list-input {
		border-radius: 25px;
		background-color: ${({ theme }) => theme.primaryBackground};
		height: 25px;
		width: 85%;
		font-size: x-small;
	}

	.list-item {
		font-size: x-small;
		color: ${({ theme }) => theme.primaryLight};
		text-align: left;
		margin: 0;
	}

	.list-item:hover {
		color: ${({ theme }) => theme.primaryBold};
	}

	.list-checkbox:focus + label:before {
		outline: 2px solid;
	}

	.list-item span span:nth-child(2) {
		color: ${({ theme }) => theme.primaryNormal};
		margin-right: 8px;
		background-color: #4a483f27;
		display: inline-block;
		height: fit-content;
		border-radius: 3px;
		padding: 2px;
		min-width: 20px;
		text-align: right;
	}

	.filter-heading {
		color: ${({ theme }) => theme.primaryBold};
		font-size: medium;
		font-weight: normal;
	}

	.datePicker {
		color: ${({ theme }) => theme.primaryLight};
	}

	.release-date button,
	.release-date ul {
		font-size: 0.625rem;
	}
`
