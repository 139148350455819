// BrowseSingleList.styled.js
import styled from 'styled-components'

export const StyledCollection = styled.section`
	.channel-image {
		width: 90%;
		margin: 10px;
	}

	.channel-title {
		color: ${({ theme }) => theme.primaryBold};
		font-size: medium;
		margin-top: 35px;
		margin-bottom: 10px;
		text-align: left;
	}

	.collection-type {
		display: block !important;
		color: ${({ theme }) => theme.tertiaryBold};
		background-color: ${({ theme }) => theme.accent};
		padding: 5px;
		font-size: medium;
		margin-top: 35px;
		margin-bottom: 10px;
		text-align: left;
	}

	.column-one div {
		text-align: left;
	}

	.column-two {
		flex-basis: 80%;
	}

	/* styling for Result Container */
	#result-list {
		margin: 0;
	}

	.result-item {
		min-height: 250px;
		height: 250px;
		background-color: ${({ theme }) => theme.secondaryBackground} !important;
		margin-right: 17px !important;
		margin-bottom: 17px !important;
		padding: 0px 0px 25px 0 !important;
	}

	.result-title {
		display: block !important;
		color: ${({ theme }) => theme.primaryNormal} !important;
		padding: 5px;
		white-space: normal;
		text-align: left;
	}

	.result-image {
		-webkit-transition-duration: 1s;
		-o-transition-duration: 1s;
		transition-duration: 1s;
		overflow: hidden;
		background-color: ${({ theme }) => theme.imageBackground};
		background-size: cover;
		width: 260px;
		height: 145px;
	}

	.detail {
		font-size: 11px;
		color: ${({ theme }) => theme.primaryLight};
		text-align: left;
		padding: 5px;
	}

	.sub-title {
		color: ${({ theme }) => theme.primaryNormal};
		font-size: 13px;
		text-align: center;
	}

	.result-container {
		width: 80%;
	}

	.result-container-optional {
		width: 85%;
	}

	.result-stats {
		color: ${({ theme }) => theme.primaryNormal};
		font-size: 14px;
		margin-left: 3px;
		margin-top: 5px;
	}

	.result-count {
		color: ${({ theme }) => theme.primaryNormal};
		font-size: 0.85rem;
	}

	.result-card-header {
		flex-direction: column;
	}

	.result-card-header > div {
		justify-content: center;
		margin-top: 5px;
	}

	h2 {
		color: ${({ theme }) => theme.primaryNormal};
		width: 100%;
		text-align: center;
	}

	.button {
		color: ${({ theme }) => theme.tertiaryNormal};
		background-color: ${({ theme }) => theme.tertiaryBackground};
		border-radius: 5px;
		padding: 10px;
		width: 180px;
		height: 40px;
		font-weight: bold;
		font-size: 1em;
		display: flex;
		margin: auto;
		margin-top: 20px;
		margin-bottom: 3px;
		vertical-align: middle;
		justify-content: center;
		align-items: center;
	}

	.sort-options:focus {
		outline: 1px solid;
	}
`
