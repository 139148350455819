/**
 * Editorial guidelines Page.
 */
import React from 'react'
import { Helmet } from 'react-helmet'
import { StyledGuidelines } from './Guidelines.styled'

const Guidelines = () => {
	return (
		<StyledGuidelines>
			<Helmet>
				<title>Editorial Guidelines - Mindscape Commons</title>
			</Helmet>
			<div>
				<h1 className="ml-10 mt-10">
					Editorial Guidelines for Mindscape Commons Submissions
				</h1>
				<ul className="list-disc ml-20 mt-8">
					<li>
						Content submissions to Mindscape Commons should have a primary focus
						on mental health, immersion and/or interactivity.
					</li>
					<li>
						This includes 360° or 180° degree video content on mental health, as
						well as serious games and apps that have been peer-reviewed and
						demonstrate positive outcomes.
					</li>
					<li>
						We also encourage those involved in VR mental health research
						projects to submit their entry with a URL link to their work.
						Projects can be completed, or in progress.
					</li>
					<li>
						Submissions can also include help files, teaching aids, articles,
						book chapters, and previews.
					</li>
				</ul>

				<p className="ml-10">
					Content can be uploaded to Mindscape Commons or linked back to content
					owner websites.
				</p>

				<p className="ml-10">
					Submissions are reviewed by Coherent Digital before they are
					published. We reserve the right to decline the submission if it does
					not conform to standards. Please provide a contact name and email so
					that we may contact you about the details of your submission. Thank
					you!
				</p>

				<p className="ml-10">
					Please contact Elizabeth Robey, Publisher, with any questions.{' '}
					<a href="mailto:erobey@coherentdigital.net">
						erobey@coherentdigital.net
					</a>
				</p>
			</div>
		</StyledGuidelines>
	)
}

export default Guidelines
