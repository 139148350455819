/**
 * About Coherent Digital.
 */
import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { StyledAbout } from './About.styled'

const About = () => {
	return (
		<StyledAbout>
			<Helmet>
				<title>About - Mindscape Commons</title>
			</Helmet>
			<h1 id="top">About Mindscape Commons</h1>

			<p>
				<em>Mindscape Commons</em> is the world’s first online resource for
				immersive and interactive content in mental health. It presents and
				documents nearly 200 VR experiences, as well as some serious games and
				apps, for teaching and research in counseling, social work, psychology,
				health sciences, and related programs. It’s also collaborative in
				nature, so faculty and researchers can create member profiles, upload
				their projects, and connect with others.
			</p>

			<h2>The Content</h2>
			<p>
				At a time of fluctuation and change within higher education, Mindscape
				Commons offers compelling online content for teaching, learning, and
				research. Our exclusive{' '}
				<em>
					<Link to="/collection/virtualmicrocases">Virtual Microcases</Link>
				</em>{' '}
				and{' '}
				<em>
					<Link to="/collection/interactivesimulations:momentsofexcellenceincounseling">
						Interactive Simulations
					</Link>
				</em>{' '}
				feature a range of clients and mental health conditions, coupled with
				interactivity and assessments to gauge comprehension. Other experiences
				such as The Guardian’s{' '}
				<em>
					<Link to="/view/limbo-a-virtual-experience-of-waiting-for-asylum">
						Waiting for Asylum
					</Link>
				</em>
				, BBC Scotland’s{' '}
				<em>
					<Link to="/view/inside-anxiety-360">Inside Anxiety</Link>
				</em>
				, and 360 Immersive{' '}
				<em>
					<Link to='/search?publisher=%5B"360+Immersive"%5D'>
						Social Work Home Assessments
					</Link>
				</em>{' '}
				offer additional options for learning, enhancing empathy, and building
				skills. Additionally, the <em>Mindscape Commons</em> points to many{' '}
				<Link to='/search?access=%5B"Open"%5D'>Open Access</Link> VR content as
				well as VR research projects.
			</p>

			<h2>The Convenience</h2>
			<p>
				We know that VR is new to many, and we want our content to work for{' '}
				<em>all</em>. Therefore, most experiences can be viewed on a laptop or
				for those who wish to employ immersion, experiences can be viewed on a
				smartphone with Google cardboard, or, to take it further, viewed in an
				Oculus head-mounted device.
			</p>

			<h2>The Community</h2>
			<p>
				Historically, VR projects have been difficult to find and cite, often
				disappearing when funding at the originating organization runs out.{' '}
				<em>Mindscape Commons</em> provides a place to preserve descriptions of
				the actual VR experiences. All entries to the <em>Directory</em> receive
				permanent, unique identifiers, along with subject tags, making it easy
				for users to discover these works. Registered users can upload projects
				or post links back to their websites.
			</p>

			<p>
				We welcome your feedback, idea sharing, and hope you can help us build a
				community! Thank you for your interest.
			</p>

			<p>
				Elizabeth Robey, Publisher
				<br />
				Carolina Tobón, Project Manager
				<br />
				Peter Ciuffetti, Chief Technology Officer
			</p>
		</StyledAbout>
	)
}

export default About
