/**
 * The wrapper for Route requires that the user is logged in as an individual.
 * This applies to situations like visiting /profile
 */
import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import { useAuth0 } from '../react-auth0-spa'

const IndividualRoute = ({ component: Component, path, ...rest }) => {
	const { loading, isAuthorizedIndividual, loginWithRedirect } = useAuth0()

	useEffect(() => {
		if (loading || isAuthorizedIndividual) {
			return
		}
		const fn = async () => {
			await loginWithRedirect({
				appState: { targetUrl: path },
			})
		}
		fn()
	}, [loading, isAuthorizedIndividual, loginWithRedirect, path])

	const render = (props) =>
		isAuthorizedIndividual === true ? <Component {...props} /> : null

	return <Route path={path} render={render} {...rest} />
}

export default IndividualRoute
