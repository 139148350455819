/**
 * Head Mounted Device modal for a view page.
 *
 * Usage:
 *
 * import HeadMountedDevice from './HeadMountedDevice';
 * <HeadMountedDevice url={launchcode url}/>
 *
 */
import React, { useRef } from 'react'
import Modal from 'react-modal'
import { StyledModal } from './Modal.styled'
import { trackItemInvestigation } from '../../utils/TrackingUtils'
import { urlPrefix } from '../../constants'

const customStyles = {
	overlay: {
		backgroundColor: 'rgba(8, 8, 8, 0.50)',
	},
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		padding: '0',
		transform: 'translate(-50%, -50%)',
		width: '600px',
		maxWidth: '95%',
		maxHeight: '75%',
	},
}

// Bind modal to the appElement.  This allows the model to disable the background.
// See: http://reactcommunity.org/react-modal/accessibility/
Modal.setAppElement('#root')

function HeadMountedDevice(props) {
	const codeSrc = useRef()
	const subtitle = useRef()
	const codeHref = useRef()

	const [modalIsOpen, setIsOpen] = React.useState(false)
	const [loadingClass, setLoadingClass] = React.useState('loading')

	function openModal(e) {
		setIsOpen(true)
		e.preventDefault()
	}

	function afterOpenModal() {
		// references are now sync'd and can be accessed.
		subtitle.current.style.color = '#111111'
		codeSrc.current.src = `${props.url}#container`
		codeHref.current.href = `${props.url}#container`
		codeSrc.current.onload = () => {
			setLoadingClass('')
		}

		const { item } = props
		const accessType =
			item.url.indexOf(urlPrefix) > 0 ? 'controlled' : 'other_free_to_read'

		trackItemInvestigation(accessType, item)
	}

	function closeModal() {
		setIsOpen(false)
	}

	return (
		<span className="hmd_modal">
			<button
				type="button"
				onClick={openModal}
				href={window.location.href}
				className="tw-btn tw-btn-light w-full"
			>
				<img className="h-4 inline mr-2" src="/hmd.png" alt="Video" />
				Watch in HMD
			</button>
			<Modal
				isOpen={modalIsOpen}
				onAfterOpen={afterOpenModal}
				onRequestClose={closeModal}
				style={customStyles}
				contentLabel="View on HMD"
			>
				<StyledModal>
					<div className="modal-header max-h-9/10">
						<h2 ref={subtitle} className="modal-title">
							View this Experience on your HMD
						</h2>
						<button
							type="button"
							aria-label="Close Modal"
							onClick={closeModal}
							className="close"
						>
							<span aria-hidden="true">x</span>
						</button>
					</div>
					<div className={`hmd modal-body ${loadingClass}`}>
						<h3 className="hmd_code_title">
							First install the Uptale viewer app on your device
						</h3>
						<p className="text-left">
							To view on a head mounted device, please install the Uptale viewer
							app.
							<a
								href="/help/vrapp/install"
								aria-describedby="uptale"
								aria-label="Click here to open new window with the instructions"
								target="_blank"
								title="Opens in another window"
								className="mx-2"
							>
								Click here
							</a>
							for instructions
						</p>
						<div className="w-full h-80 overflow-hidden">
							<div className="fake-big-window">
								<iframe
									title="Launch Code"
									id="codeSrc"
									className="launch_code w-full h-full"
									scrolling="yes"
									ref={codeSrc}
									tabIndex="-1"
								/>
							</div>
						</div>
						<div className="hmd_instructions w-full">
							<h3 className="hmd_instructions_title">
								Enter this launch code into the Uptale app.
							</h3>
							<p id="uptale" className="text-left">
								This code expires in 30 minutes. If expired, close and reopen
								this popup for a new launch code. If the launch code does not
								appear,{' '}
								<a
									href="/"
									aria-describedby="uptale"
									aria-label="Click here to open new window with the code"
									ref={codeHref}
									target="launchcode"
									rel="noopener noreferrer"
									title="Opens in another window"
								>
									click here
								</a>
								.
							</p>
						</div>
					</div>
				</StyledModal>
			</Modal>
		</span>
	)
}

export default HeadMountedDevice
