// Search.styled.js
import styled from 'styled-components'

export const StyledSearch = styled.div`
	/* styling for left-sidebar */

	.grid-item {
		background-color: ${({ theme }) => theme.secondaryBackground} !important;
		padding: 0px 0px 30px 0;
	}

	.grid-item:focus {
		outline: 2px solid transparent;
	}

	.result-title {
		display: block !important;
		color: ${({ theme }) => theme.primaryNormal} !important;
		padding: 5px;
		white-space: normal;
		text-align: left;
	}

	.result-list-item .result-title {
		text-align: left;
	}

	.result-image {
		-webkit-transition-duration: 1s;
		-o-transition-duration: 1s;
		transition-duration: 1s;
		overflow: hidden;
		background-color: ${({ theme }) => theme.imageBackground};
		background-size: cover;
		width: 260px;
		height: 145px;
	}

	.grid-item .result-image {
		width: 260px;
		height: 145px;
	}

	.result-list-item .result-image {
		border-radius: 5px;
	}

	.detail {
		font-size: 11px;
		color: ${({ theme }) => theme.primaryLight};
		text-align: left;
		padding: 5px;
	}

	.result-list-item .detail {
		text-align: left;
	}

	.summary {
		color: ${({ theme }) => theme.primaryLight};
		text-align: left;
		padding: 5px;
		width: 90%;
		font-size: small;
	}

	.description {
		color: ${({ theme }) => theme.primaryLight};
		text-align: left;
		padding: 5px;
		width: 90%;
		font-size: x-small;
	}

	.sub-title {
		color: ${({ theme }) => theme.primaryNormal};
		font-size: 13px;
		text-align: center;
	}

	.result-list-item .sub-title {
		text-align: left;
	}

	.result-card-header {
		flex-direction: column;
	}

	.result-card-header > div {
		justify-content: center;
		margin-top: 5px;
	}

	.result-list-item {
		min-height: 100px;
		background-color: ${({ theme }) => theme.secondaryBackground} !important;
		border-bottom: 1px solid #ccc;
	}

	.result-container {
		@media (min-width: ${({ theme }) => theme.mobile}) {
			width: 80%;
		}
		margin-top: 12px;
	}

	.result-container-optional {
		width: 85%;
	}

	.result-stats {
		color: ${({ theme }) => theme.primaryNormal};
		font-size: 14px;
		margin-left: 3px;
		margin-top: 5px;
	}

	.result-count {
		color: ${({ theme }) => theme.primaryNormal};
		font-size: 0.85rem;
	}

	.result-toggle {
		color: ${({ theme }) => theme.primaryNormal};
	}

	.result-loader {
		color: ${({ theme }) => theme.primaryLight};
	}

	.sort-options:focus {
		outline: 1px solid;
	}
`
