import React, { useState, useEffect } from 'react'
import jwt from 'jsonwebtoken'
import { useAuth0 } from '../react-auth0-spa'

const CustomerBranding = () => {
	const { loadingInstitution, jwToken } = useAuth0()

	const [brandingUrl, setBrandingUrl] = useState()
	const [customerName, setCustomerName] = useState()

	useEffect(() => {
		const initBrandingUrl = () => {
			if (jwToken) {
				const payload = jwt.decode(jwToken)
				if ('aud' in payload) {
					const logoUrl = `https://commons.coherentdigital.net/api/orgs/${payload.aud}/logo`
					setBrandingUrl(logoUrl)
				}
			}
		}

		const initCustomerName = () => {
			if (jwToken) {
				const payload = jwt.decode(jwToken)
				if ('acn' in payload) {
					setCustomerName(payload.acn)
				}
			}
		}

		initBrandingUrl()
		initCustomerName()
	}, [jwToken])

	if (loadingInstitution || !jwToken) {
		return <div />
	}

	if (brandingUrl && customerName) {
		return (
			<img
				src={brandingUrl}
				alt={customerName}
				onError={(e) => {
					e.target.onerror = null
					e.target.src = '/no-branding.png'
				}}
				className="customer-logo"
			/>
		)
	}

	if (customerName) {
		return <span className="customer-name">{customerName}</span>
	}

	return <div />
}

export default CustomerBranding
