import React from 'react'
import { TagCloud } from 'react-tagcloud'
import { useAuth0 } from '../../react-auth0-spa'

const AggTotals = ({ aggregations }) => {
	const { loading } = useAuth0()

	const colors = [
		'#742a2a' /* crimson */,
		'#006666' /* cyan */,
		'#4B0082' /* indigo */,
		'#000080' /* navy */,
		'#2F4F4F' /* slate grey */,
	]

	if (loading) {
		return <div>Loading...</div>
	}

	const subject = aggregations?.subject?.buckets?.sort((a, b) => {
		return a.key > b.key ? 1 : -1
	})

	return (
		<div>
			{aggregations && (
				<div>
					<div className="row r1 infographic">
						<div className="column r1c1">
							<div className="cell r1c1c1 text-center">
								<div className="small">
									Mindscape Commons Provides Access to
								</div>
								<div className="large">
									{aggregations.projects.doc_count.toLocaleString(
										navigator.language,
										{
											minimumFractionDigits: 0,
										}
									)}
								</div>
								<div className="medium">
									Interactive and Immersive Experiences
								</div>
								<div className="small">
									in Counseling, Psychology and Mental Health
								</div>
							</div>
							<div className="cell r1c1c2 text-center">
								<span className="medium">From </span>
								<span className="large">
									{aggregations.publishers.value.toLocaleString(
										navigator.language,
										{
											minimumFractionDigits: 0,
										}
									)}
								</span>
								<span className="medium"> Producers</span>
							</div>
						</div>
						<div className="column cell r1c2 text-center">
							<h2 className="font-normal" id="sourced-from">
								<span className="medium">Sourced from </span>
								<span className="large">
									{aggregations.countries.value.toLocaleString(
										navigator.language,
										{
											minimumFractionDigits: 0,
										}
									)}
								</span>
								<span className="medium"> Countries</span>
							</h2>
							<div>
								<table aria-describedby="sourced-from">
									<tbody>
										{aggregations.country.buckets.map(
											(country) =>
												country.doc_count > 1 && (
													<tr className="stats-table-row" key={country.key}>
														<th>{country.key}</th>
														<td>
															{country.doc_count.toLocaleString(
																navigator.language,
																{ minimumFractionDigits: 0 }
															)}
														</td>
													</tr>
												)
										)}
									</tbody>
								</table>
							</div>
							<div className="small">
								<span>...and more</span>
							</div>
						</div>
					</div>

					<div className="row r2 infographic">
						<div className="column r2c1 text-center">
							<div className="cell r2c1c1">
								<h2 id="top-producers" className="font-normal medium">
									Top Producers
								</h2>
								<table aria-describedby="top-producers">
									<tbody>
										{aggregations.publisher.buckets.map(
											(publisher) =>
												publisher.doc_count > 3 && (
													<tr className="stats-table-row" key={publisher.key}>
														<th>{publisher.key}</th>
														<td>
															{publisher.doc_count.toLocaleString(
																navigator.language,
																{ minimumFractionDigits: 0 }
															)}
														</td>
													</tr>
												)
										)}
									</tbody>
								</table>
							</div>
						</div>
						<div className="column r2c2">
							<div className="cell r2c2c1">
								<div>
									<span className="large">
										{aggregations.subjects.value.toLocaleString(
											navigator.language,
											{
												minimumFractionDigits: 0,
											}
										)}
									</span>
									<span className="small">
										{' '}
										subject tags used to categorize the experiences.
									</span>
								</div>
								<div>
									<hr />
									<span className="small">
										Experiences that have supplementary materials:{' '}
									</span>
									<span className="large">
										{aggregations.have_sup_matter.doc_count.toLocaleString(
											navigator.language,
											{
												minimumFractionDigits: 0,
											}
										)}
									</span>
								</div>
								<div>
									<hr />
									<span className="large">
										{aggregations.have_preview.doc_count.toLocaleString(
											navigator.language,
											{
												minimumFractionDigits: 0,
											}
										)}
									</span>
									<span className="small">
										{' '}
										experiences have open access previews.
									</span>
								</div>
								<div>
									<hr />
									<span className="small">
										Teacher materials are included in{' '}
									</span>
									<span className="large">
										{aggregations.have_teacher_materials.doc_count.toLocaleString(
											navigator.language,
											{
												minimumFractionDigits: 0,
											}
										)}
									</span>
									<span className="small"> experiences.</span>
								</div>
							</div>
						</div>
					</div>

					<div className="cell subjects">
						<h2 className="font-normal medium">
							Subjects Covered by Mindscape Commons
						</h2>
						<div className="wordcloud text-center">
							<ul className="sr-only">
								{subject.map((bucket) => (
									<li key={bucket.key}>{bucket.key}</li>
								))}
							</ul>
							<TagCloud
								aria-hidden="true"
								minSize={12}
								maxSize={48}
								tags={aggregations.subject.buckets.map((s) => {
									return {
										value: s.key,
										count: s.doc_count,
										color: colors[s.doc_count % 5],
									}
								})}
							/>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}

export default AggTotals
