import uniqueId from 'lodash/uniqueId'

const timers = {}
export const revealAriaMessage = (elem, text) => {
	const attribute = 'data-reveal-message-id'
	const disableAriaNotification = 6000

	let id = elem.getAttribute(attribute)

	// clear timer if we set up message into the same elem too fast
	if (id) {
		if (timers[id]) {
			clearTimeout(timers[id])
			delete timers[id]
		}
	} else {
		// create unique id `data-reveal-message-id` for element to track its timer
		id = uniqueId('reveal')
		elem.setAttribute(attribute, id)
	}

	// eslint-disable-next-line
	elem.innerText = text
	timers[id] = setTimeout(() => {
		// eslint-disable-next-line
		elem.innerText = ''
		if (id) delete timers[id]
	}, disableAriaNotification)
}
