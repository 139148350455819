// global.js
import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
  }
  *, *::after, *::before {
    box-sizing: border-box;
  }
  body {
    align-items: center;
    color: #111111;
    font-family: ${({ theme }) => theme.fontFamily};
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	--blue: #246BE9;
	--purple: #6729FF;
	--orange: #F96A3E;
	--white: #FFFFFF;
	--gray: #CCCCCC;
	--gray-dark: #343a40;
	--primary: #6729FF;
	--secondary: #6c757d;
	--success: #02B875;
	--light: #f8f9fa;
	--dark: #212529;
	--breakpoint-xs: 0;
	--breakpoint-sm: 576px;
	--breakpoint-md: 768px;
	--breakpoint-lg: 992px;
	--breakpoint-xl: 1200px;
	background-color: ${({ theme }) => theme.primaryBackground};

    justify-content: center;
    text-rendering: optimizeLegibility;
  }

  small {
    display: block;
  }

  a {
    color: ${({ theme }) => theme.primaryNormal};
    text-decoration: none;
  }

  /* row and column flex css */
  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }

  .column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
  }

  .modal-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px);
  }

  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto;
  }

  .modal-title {
    margin-bottom: 0;
    line-height: 1.5;
    font-size: 1.25rem;
  }

  button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
  }

  .close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
  }

  .modal-body {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem;
  }

  .btn {
    display: inline-block;
    padding: 0.5rem;
    font-weight: 400;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: ${({ theme }) => theme.accent};
    border: 0px;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }

  .btn:not(:disabled):not(.disabled) {
    cursor: pointer;
  }

  .btn svg {
    overflow: hidden;
    vertical-align: middle;
  }

  .twitter-button {
    background-color: #1b95e0;
    margin-right: 10px;
  }

  .facebook-button {
    background-color: #394959;
  }

  .dismiss-button {
    margin-right: 10px;
  }

  div.note {
    margin-top: 20px;
    margin-bottom: 30px;
  }

  #rcc-confirm-button {
    background-color: ${({ theme }) => theme.accent} !important;
    color: #fff !important;
  }
`
