/* eslint-disable */

import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import { ReactiveBase } from '@appbaseio/reactivesearch'
import { Auth0Provider } from './react-auth0-spa'
import config from './auth_config.json'
import './App.css'
import Routes from './Routes'
import { ThemeProvider } from 'styled-components'
import { GlobalStyles } from './global'
import { theme } from './theme'
import history from './utils/history'

const es_conf = require('./elasticsearch-config.json')
const credentials = es_conf.username + ':' + es_conf.password

// A function that routes the user to the right place
// after login
const onRedirectCallback = (appState) => {
	history.push(
		appState && appState.targetUrl
			? appState.targetUrl
			: window.location.pathname
	)
}
const isEmbed = window.location.href.indexOf('/embed/') > 0
const isRoot = window.location.pathname === '/'
const mainClass = isEmbed ? '' : 'main-container ' + (isRoot ? 'mt-16' : 'mt-32') + ' sm:mt-16'

const App = () => {
	return (
		<div>
			<div
				className={mainClass}
			>
				<ThemeProvider theme={theme}>
					<GlobalStyles />
					<Auth0Provider
						domain={config.domain}
						client_id={config.clientId}
						useRefreshTokens
						cacheLocation="localstorage"
						redirect_uri={window.location.origin}
						onRedirectCallback={onRedirectCallback}
					>
						<BrowserRouter>
							<ReactiveBase
								url="https://search.mindscapecommons.net"
								credentials={credentials}
								app={es_conf.index}
								theme={{
									typography: {
										fontFamily: ({ theme }) => theme.fontFamily,
										fontSize: '16px',
									},
									colors: {
										backgroundColor: '#212121',
										primaryTextColor: '#fff',
										primaryColor: '#2196F3',
										titleColor: '#fff',
										alertColor: '#d9534f',
										borderColor: '#666',
									},
								}}
							>
								<Routes />
							</ReactiveBase>
						</BrowserRouter>
					</Auth0Provider>
				</ThemeProvider>
			</div>
		</div>
	)
}

export default App
