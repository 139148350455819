/**
 * Browse by Target Publisher.
 */
import React from 'react'

import Browse from '../Browse'
import { sortOptions } from '../../constants'

const Publisher = () => {
	return (
		<Browse
			filterHeading="Browse Publishers"
			pageTitle="Browse Publishers"
			componentId="selected-publisher"
			dataField="publisher.name.keyword"
			filterLabel="Publisher"
			filtersClassName="h-64 sm:h-auto"
			sortOption={sortOptions.publisher}
			loaderMessage="Loading publishers browse"
			selectAllLabel="All Publishers"
			placeholder="Search for a Publisher"
			showSearch
		/>
	)
}

export default Publisher
