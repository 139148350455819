/**
 * ViewMenu
 */
import React from 'react'
import { Link } from 'react-router-dom'

const ViewMenu = ({ item }) => {
	return (
		<div className="flex flex-wrap mt-10">
			{/* Subjects */}
			{item.subjects && (
				<div className="w-full sm:w-1/2 mb-10 flex">
					<h3 className="w-1/2 font-black  pr-5">Subject</h3>
					<div className="w-1/2">
						{item.subjects.map((subjects) => (
							<div key={subjects.subject}>
								<Link
									className="text-accent"
									to={`/search?subject=%5B%22${subjects.subject}%22%5D`}
								>
									{subjects.subject}
								</Link>
								{subjects.vocabulary && subjects.vocabulary !== 'Mindscape' && (
									<span className="ml-2">({subjects.vocabulary})</span>
								)}
							</div>
						))}
					</div>
				</div>
			)}
			{/* Collections */}
			{item.channel && (
				<div className="w-full sm:w-1/2 mb-10 flex">
					<h3 className="w-1/2 font-black  pr-5">Collections</h3>
					<div className="w-1/2">
						{item.channel.map((chan) => (
							<div key={chan.slug}>
								<Link className="text-accent" to={`/collection/${chan.slug}`}>
									{chan.title}
								</Link>
							</div>
						))}
					</div>
				</div>
			)}
			{/* demographics */}
			{item.demographics && (
				<div className="w-full sm:w-1/2 mb-10 flex">
					<h3 className="w-1/2 font-black  pr-5">Demographics</h3>
					<div className="w-1/2">
						{item.demographics.map((demo) => (
							<div key={demo}>
								<Link
									className="text-accent"
									to={`/search?demographics=%5B%22${demo}%22%5D`}
								>
									{demo}
								</Link>
							</div>
						))}
					</div>
				</div>
			)}
			{/* Interactivity Factors */}
			{item['interactivity-factor'] && (
				<div className="w-full sm:w-1/2 mb-10 flex">
					<h3 className="w-1/2 font-black  pr-5">Interactivity Factors</h3>
					<div className="w-1/2">
						{item['interactivity-factor'].map((ifactor) => (
							<div key={ifactor}>
								<Link
									className="text-accent"
									to={`/search?interactivity=%5B%22${ifactor}%22%5D`}
								>
									{ifactor}
								</Link>
							</div>
						))}
					</div>
				</div>
			)}
			{/* Content Type */}
			{item['access-type'] && (
				<div className="w-full sm:w-1/2 mb-10 flex">
					<h3 className="w-1/2 font-black  pr-5">Content Type</h3>
					<div className="w-1/2">
						<Link
							className="text-accent"
							to={`/search?access=%5B%22${item['access-type']}%22%5D`}
						>
							{item['access-type']}
						</Link>
					</div>
				</div>
			)}
			{/* Immersion Factors */}
			{item['immersion-factor'] && (
				<div className="w-full sm:w-1/2 mb-10 flex">
					<h3 className="w-1/2 font-black  pr-5">Immersion Factors</h3>
					<div className="w-1/2">
						{item['immersion-factor'].map((ifactor) => (
							<div key={ifactor}>
								<Link
									className="text-accent"
									to={`/search?immersion=%5B%22${ifactor}%22%5D`}
								>
									{ifactor}
								</Link>
							</div>
						))}
					</div>
				</div>
			)}
		</div>
	)
}

export default ViewMenu
