/**
 * ViewHelmetBlock
 */
import React from 'react'
import { Helmet } from 'react-helmet'
import { trackItemInvestigation } from '../../utils/TrackingUtils'
import { urlPrefix } from '../../constants'

const ViewHelmetBlock = ({ item }) => {
	const accessType =
		item.url.indexOf(urlPrefix) > 0 ? 'controlled' : 'other_free_to_read'

	trackItemInvestigation(accessType, item)

	return (
		<Helmet>
			<title>{item.title} - Mindscape Commons</title>
			{item.purpose && <meta name="description" content={item.purpose} />}
			<link
				rel="canonical"
				href={`https://mindscapecommons.net/view/${item.slug}`}
			/>
			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:site" content="@MindscapeVR" />
			<meta name="twitter:creator" content="@MindscapeVR" />
			<meta
				property="og:url"
				content={`https://mindscapecommons.net/view/${item.slug}`}
			/>
			<meta property="og:title" content={item.title} />
			{item.purpose && (
				<meta property="og:description" content={item.purpose} />
			)}
			{item.image && item.image[0].thumbnails && (
				<meta
					property="og:image"
					content={item.image[0].thumbnails.large.url}
				/>
			)}
			<meta property="og:site_name" content="Mindscape Commons" />
			<meta property="og:type" content="virtual-reality" />
		</Helmet>
	)
}

export default ViewHelmetBlock
