/**
 * ViewImageBlock
 */
import React, { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tooltip } from 'react-tippy'

import { useHistory, useLocation } from 'react-router-dom'
import { manifestFileName } from '../../../constants'
import ViewLayoutVideoObject from '../ViewLayoutVideoObject'

// css
import './ViewImageBlock.scss'

import ViewDuration from '../ViewDuration'
import ViewButtonsBlock from '../ViewButtonsBlock'
import ViewVideoBlock from '../ViewVideoBlock'
import { setIsTheater } from '../../../reducers/viewPage/actions'
import { theaterModeSearchParam } from '../../../reducers/viewPage/types'
import ModalPopup from '../ModalPopup'

const ViewImageBlock = ({ item, authorizations }) => {
	const [cidTooltipOpened, setCidTooltipOpened] = useState(false)
	const [isPlayed, setIsPlayed] = useState(false)

	const dispatch = useDispatch()
	const history = useHistory()
	const location = useLocation()
	const isTheater = useSelector((state) => state.viewPage.isTheater)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [videoObjectRef, setVideoObjectRef] = useState(null)
	const onRefChange = useCallback(
		(videoObject) => {
			setVideoObjectRef(videoObject)

			videoObject.video.addEventListener('play', () => {
				if (!authorizations.institution) {
					videoObject.player.pause()
					setIsPlayed(false)
					setIsModalOpen(true)
				} else {
					setIsPlayed(true)
				}
			})

			videoObject.video.addEventListener('pause', () => {
				setIsPlayed(false)
			})
		},
		[authorizations.institution]
	)

	function getAvatar(projectLeader) {
		const small = projectLeader?.avatar?.[0]?.thumbnails?.small?.url
		const large = projectLeader?.avatar?.[0]?.thumbnails?.large?.url
		const full = projectLeader?.avatar?.[0]?.thumbnails?.full?.url
		return small || large || full
	}

	const publisherLogoUrl = item?.publisher?.[0]?.['logo-url']
	const publisherUrl = item?.publisher?.[0]?.['url']
	const publisherName = item?.publisher?.[0]?.['name']

	const handlePlay = () => {
		if (isPlayed) {
			// eslint-disable-next-line
			videoObjectRef.player.pause()
			setIsPlayed(false)
		} else {
			// eslint-disable-next-line
			videoObjectRef.player.play()
			setIsPlayed(true)
		}
	}

	const handlePlayWithTranscription = () => {
		const newValue = !isTheater
		dispatch(setIsTheater(newValue))
		history.push(`${location.pathname}?${theaterModeSearchParam}=${newValue}`)
		// scroll to the top in we switch theater mode on
		// eslint-disable-next-line
		newValue && window.scrollTo(0, 0)

		// eslint-disable-next-line
		if (videoObjectRef) {
			videoObjectRef.player.play()
			setIsPlayed(true)
		}
	}

	const closeModal = () => {
		setIsModalOpen(false)
	}

	return (
		<div className="flex flex-col md:flex-row">
			{item.url.includes(manifestFileName) && (
				<div className="flex-grow">
					<ViewLayoutVideoObject item={item} videoObjectRef={onRefChange} />
				</div>
			)}
			{!item.url.includes(manifestFileName) && (
				<div className="flex-grow h-96">
					<ViewVideoBlock item={item} authorizations={authorizations} />
				</div>
			)}

			{/* Video meta block */}
			{!isTheater && (
				<div className="w-full md:w-1/2 mt-5 md:ml-5 md:mt-0">
					{item.cid && (
						<div className="cid inline-block">
							<Tooltip
								title="Coming soon"
								position="top"
								offset="-150"
								open={cidTooltipOpened}
							>
								<div
									className="flex items-center"
									onBlur={() => setCidTooltipOpened(false)}
									onFocus={() => setCidTooltipOpened(true)}
									onMouseEnter={() => setCidTooltipOpened(true)}
									onMouseLeave={() => setCidTooltipOpened(false)}
								>
									<img src="/coherent-logo.png" alt="Coherent identifier" />
									About this item: {item.cid}
								</div>
							</Tooltip>
						</div>
					)}
					<h1 className="mb-5">{item.title}</h1>
					<div className="video-meta mb-5 text-primaryLight">
						{item['release-date'] && item['release-date'] !== 1999 && (
							<span className="video-meta__item">{item['release-date']}</span>
						)}
						{item['scenery-type'] &&
							item['scenery-type'].map((stype) => (
								<span className="video-meta__item" key={stype}>
									{stype}
								</span>
							))}
						{item.duration && (
							<span className="video-meta__item">
								<ViewDuration duration={item.duration} />
							</span>
						)}
					</div>

					{/* buttons block */}
					<ViewButtonsBlock
						item={item}
						authorizations={authorizations}
						handlePlay={handlePlay}
						handlePlayWithTranscription={handlePlayWithTranscription}
						isTheater={isTheater}
						isPlayed={isPlayed}
					/>
					<ModalPopup
						isOpen={isModalOpen}
						title="Play"
						description={authorizations.membershipMessage}
						btnClass="tw-btn tw-btn-light w-full"
						closeModal={closeModal}
					/>
					{/* publisher block */}
					<div className="flex">
						{item['project-leader'] &&
							item['project-leader'].map((pl) => (
								<div key={pl.id} className="flex items-center mr-5">
									{getAvatar(pl) && (
										<img
											className="mr-2 rounded-full h-10 w-10"
											alt={`${pl['first-name']} ${pl['last-name']}`}
											src={getAvatar(pl)}
										/>
									)}
									<span>
										{pl['first-name']} {pl['last-name']}
									</span>
								</div>
							))}
						{publisherUrl && (
							<div className="flex items-center">
								{publisherLogoUrl && (
									<img
										className="mr-2 rounded-full h-10 w-10"
										alt={publisherName}
										src={publisherLogoUrl}
									/>
								)}
								<a
									target="_blank"
									rel="noopener noreferrer"
									href={publisherUrl}
								>
									{publisherName}
								</a>
							</div>
						)}
					</div>
				</div>
			)}
		</div>
	)
}

export default ViewImageBlock
