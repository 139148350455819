import { ACTIONS } from './types'
import { breadcrumbsNumber } from './selectors'
import { config } from '../../constants'

export default function history(state = [], action) {
	let lastRoute
	switch (action.type) {
		case ACTIONS.UPDATE_LAST_TITLE:
			lastRoute = state[state.length - 1]
			if (lastRoute) {
				lastRoute.title = action.title.replace(config.sitePostfix, '')
				const newState = [...state]
				newState.pop()
				newState.push(lastRoute)
				return newState
			}
			return state
		case ACTIONS.PUSH: {
			let newState = state
			// remove postfix from title
			if (action.route.title) {
				// eslint-disable-next-line
				action.route.title = action.route.title.replace(config.sitePostfix, '')
			}
			lastRoute = state[state.length - 1]
			if (
				lastRoute?.pathname === '/search' &&
				action?.route?.pathname === '/search'
			) {
				// we don't want to store every search changes
				state.pop()
			}
			// determine whether if clicked on breadcrumbs link
			// if user clicks on route that is within 2 last routes in history
			const lastIndexOf = state.findIndex(
				(route) => route.pathname === action.route.pathname
			)
			if (
				lastIndexOf !== -1 &&
				lastIndexOf >= state.length - 1 - breadcrumbsNumber
			) {
				newState = newState.slice(0, lastIndexOf)
			}
			return [...newState, action.route]
		}
		default:
			return state
	}
}
