// Search.styled.js
import styled from 'styled-components'

export const StyledCustomFilters = styled.div`
	.filter-button-row {
		text-align: left;
		margin: 2px;
		margin-top: 5px;
	}

	.filter-button {
		// Taken from reactivesearch theme

		display: inline-flex;
		justify-content: center;
		align-items: center;
		border-radius: 3px;
		border: 1px solid transparent;
		min-height: 30px;
		overflow-wrap: break-word;
		padding: 5px 12px;
		line-height: 1.2rem;
		background-color: rgb(33, 33, 33);
		color: rgb(66, 66, 66);
		cursor: pointer;
		user-select: none;
		transition: all 0.3s ease 0s;
		margin: 2px 3px;
		padding: 5px 8px;
		font-size: 0.85rem;

		position: relative;
		color: ${({ theme }) => theme.secondaryNormal};
		background-color: ${({ theme }) => theme.secondaryBackground};
		border: 1px solid rgba(0, 0, 0, 0.5);
	}

	.filter-button span:first-of-type {
		// Taken from reactivesearch theme
		max-width: 260px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-right: 26px;
	}

	.filter-button span:last-of-type {
		// Taken from reactivesearch theme
		display: flex;
		height: 100%;
		top: 0px;
		right: 8px;
		position: absolute;
		-webkit-box-align: center;
		align-items: center;
		border-left: 1px solid rgb(102, 102, 102);
		padding-left: 8px;
		margin-left: 8px;
	}

	.filter-button:hover {
		color: ${({ theme }) => theme.accent};
		background-color: ${({ theme }) => theme.secondaryBackground};
	}
`
