/**
 * ViewSeeAlsoBlock
 */
import React from 'react'
import { Link } from 'react-router-dom'

const ViewSeeAlsoBlock = React.memo(({ relatedProjects }) => {
	if (!relatedProjects) return 'Not found'

	const filteredRelatedProjects = relatedProjects
		?.filter((project) => {
			return project?.image?.[0] && project.title && project.slug
		})
		?.slice(0, 4)

	return (
		<div className="bg-primary">
			<div className="container py-5">
				<div className="flex justify-between items-center py-10">
					<h3 className="text-white text-2xl font-normal">See also</h3>
				</div>
				<div className="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4">
					{filteredRelatedProjects.map((rp) => (
						<div className="w-full rounded mb-5" key={rp.id}>
							<Link
								to={`/view/${rp.slug}`}
								className="related-link light-outline block"
							>
								<img
									aria-hidden="true"
									src={rp.image[0].thumbnails.large.url}
									className="related-image w-full sm:h-56"
									alt="Related experience"
								/>
								<h3 className="related-title flex items-center justify-center p-5 h-20">
									{rp.title.split(' ').slice(0, 12).join(' ')}
									{rp.title.split(' ').length > 12 && '...'}
								</h3>
							</Link>
						</div>
					))}
				</div>
			</div>
		</div>
	)
})

export default ViewSeeAlsoBlock
