// Help.styled.js
import styled from 'styled-components'

export const StyledHelp = styled.section`
	padding: 20px;
	color: ${({ theme }) => theme.secondaryNormal} !important;
	background-color: ${({ theme }) => theme.secondaryBackground};
	border-radius: 5px;
	@media (min-width: ${({ theme }) => theme.mobile}) {
		width: 75vw;
	}
	margin: auto;

	h1 {
		text-align: left;
	}

	h2 {
		color: ${({ theme }) => theme.secondaryNormal};
		margin-top: 2em;
		text-align: left;
	}

	h3 {
		margin-top: 1em;
		text-align: left;
	}

	div {
		text-align: left;
	}

	p,
	ul {
		margin-top: 10px;
	}

	a {
		color: ${({ theme }) => theme.accent};
		margin-left: 3px;
		margin-right: 3px;
	}

	.help_menu {
		text-align: left;
		padding-top: 20px;
	}

	.help_contact {
		text-align: left;
	}

	.help_content {
		width: 90%;
		margin: auto;
		margin-top: 20px;
	}

	.help_content div {
		text-align: left;
	}

	div.breadcrumbs {
		text-align: left;
	}

	div.glossary_panel {
		width: 25vw;
	}

	.glossary_title {
		background-color: ${({ theme }) => theme.captionBackground};
		color: ${({ theme }) => theme.captionBold};
		font-weight: bolder;
	}

	.glossary_panel img {
		width: 25vw;
		border: 1px solid grey;
		object-fit: cover;
	}

	/* styling for Result Container */

	.result-item {
		min-height: 100px;
		background-color: ${({ theme }) => theme.secondaryBackground} !important;
		margin: auto;
		margin-top: 8px !important;
		margin-bottom: 8px !important;
		padding: 0px !important;
		width: 90%;
	}

	.result-title {
		display: block !important;
		color: ${({ theme }) => theme.secondaryNormal} !important;
		padding: 5px;
		white-space: normal;
	}

	.result-image {
		-webkit-transition-duration: 1s;
		-o-transition-duration: 1s;
		transition-duration: 1s;
		overflow: hidden;
		background-color: ${({ theme }) => theme.imageBackground};
	}

	.detail {
		font-size: 11px;
		color: ${({ theme }) => theme.secondaryNormal};
		text-align: left;
		padding: 5px;
	}

	.description {
		color: ${({ theme }) => theme.secondaryNormal};
		text-align: left;
		padding: 5px;
	}

	.sub-title {
		color: ${({ theme }) => theme.secondaryNormal};
		font-size: 13px;
		text-align: left;
	}

	.result-container {
		width: 100%;
	}

	.result-title {
		text-align: left;
	}
`
